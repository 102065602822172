import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LoginAuthenticationService } from './login-authentication.service';


@Injectable({
  providedIn: 'root'
})
export class RouteGuardService implements CanActivate {

   auth:boolean;
  userRoles: any;
  roles: string;
  constructor(private loginAuthenticationService:LoginAuthenticationService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const expectedRole = route.data.role;
    console.log("expectedRole:"+expectedRole);
    // if(window.location.port == '4200')
    // {
    //   if (this.loginAuthenticationService.isUserLoggedIn())
    //      return true;
    //     this.router.navigate(['login']);
    //    return false;

    // }
    // else
    // {
    // 	this.loginAuthenticationService.checkAuthLoginService().subscribe
    //     (data =>{      
    //      console.log("CheckAuth --> Route Guard Authenticated :" + data.authenticated); 
    //       if(data.authenticated == true)
    //       {         
    //         console.log("Can activate");         
    //         //this.auth =true;
    //            return true;           
    //       }
    //       else
    //       {
    //          this.router.navigate(['login']);
    //         return false; 
    //       }
           
    //     });
        
    //   }
 
    if (this.loginAuthenticationService.isUserLoggedIn())
    {
      this.roles =sessionStorage.getItem('roles');
      if(this.roles.indexOf(expectedRole) !== -1)
      return true;
      else
      this.router.navigate(['accessDenied']);
    }
    else
    {
      this.router.navigate(['login']);
      return false;
    }
       
  }
 
}