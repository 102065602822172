import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';
import { SetScreenService } from 'src/app/service/common-service/set-screen.service';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { UserIdleService } from 'angular-user-idle';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  loggedUserName='';
  loggedUserFullName='';
  dealerName = "";
  isAdmin: any; 
  showModal: boolean;
  changePassword : boolean;
  updateContact : boolean;
  changepwdChk : boolean;
  resetUserName:  string ="";
  newPassword: string ="";
  confirmPassword:  string ="";
  messageColor:  string ="";
  message:  string ="";
  contactMessage:  string ="";
  contactMessageColor:  string ="";
  errorList: string ="";
  oldPassword:  string ="";
  emailId: string ="";
  contactName: string = "";
	
  constructor(private route:ActivatedRoute,private loginAuthenticationService:LoginAuthenticationService,private _setCsaValueService:SetScreenService,  private titleService: Title,private userIdle: UserIdleService,private router:Router) { }

  get myVariable()
   {
      return this._setCsaValueService.myVariable;
   }
   set myVariable(value)
   {
      this._setCsaValueService.myVariable=value;
   }

   setScreen(num)
   {
           this._setCsaValueService.changeMyVar(num);
          
   }
    

 ngOnInit(): void {

  console.log("Init Header Component");
  this.loggedUserName= this.loginAuthenticationService.getAuthenticatedUser();
   this.loggedUserFullName= this.loginAuthenticationService.getAuthenticatedUserFullName();
   this.dealerName = sessionStorage.getItem('DealerName');
   this.isAdmin=  sessionStorage.getItem('Admin');
   this.userIdle.startWatching();
  // if(this.loggedUserName == 'undefined')
  // {
//    /this.loginAuthenticationService.checkAuthLoginService().subscribe
//	    (data =>{      
//	     console.log("CheckAuth --> Authenticated :" + data.authenticated);	     
//           
//              //this.loggedUserName= data.userName;
//             // this.loggedUserFullName= data.userFullName;
//             // console.log("LoggedIn user: from AppComponent"+this.loggedUserName);
//              //console.log("LoggedIn user Full Name: from AppComponent"+this.loggedUserFullName);
//	      });
  //}
  console.log("LoggedIn user: from Header"+this.loggedUserName);
  console.log("LoggedIn user Full Name: from Header"+this.loggedUserFullName);
   
  this.userIdle.onTimerStart().subscribe(
    count => 
    {
    console.log("Time Starts"+count);
      this.showModal = true;
   }
    
 );

 // Start watch when time is up.
  this.userIdle.onTimeout().subscribe(() => 
 {
    console.log('Time is up!');
    //alert("Time is Up");
    this.userIdle.stopWatching();
    this.showModal = false;
    this.loginAuthenticationService.executeUserSessionTimeoutService().subscribe
  (data =>{      
     console.log("Page Redirect to login --> response :" + data); 
     //this.invalidLogin=true;
       this.router.navigate(['login']);
       });
 
  }
);
	
 }
 restart() {
  console.log('Timer reset done.');
  //this.showModal = false;
  window.location.reload();
  this.userIdle.resetTimer();
  this.showModal = false;
}

 resetPassword() {
    let inputParams : any = { resetUserName: this.loggedUserName, 
       newPassword : this.newPassword, oldPassword : this.oldPassword}
  console.log("Inside resetPassword");
  
    var mediumRegex = new RegExp("^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*$", "g");    
    var enoughRegex = new RegExp("(?=.{8,}).*", "g");
    this.errorList ="";
    var noError = false;
    if (false == enoughRegex.test(this.newPassword)) {
      this.messageColor  = "alert alert-danger";
      this.message = "Password should have minimum 8 characters.";  
    }else if(this.newPassword != this.confirmPassword){
      this.messageColor  = "alert alert-danger";
      this.message = "New password & Confirm password should be same.";
    } else if (mediumRegex.test(this.newPassword)) {  
      this.messageColor = "alert alert-success";
      this.message = "Strong password";
      noError = true;
    } else {
      this.messageColor  = "alert alert-danger";
      this.message = "Password should have 1 Uppercase, 1 Lowercase , 1 Number";  
    }

    if(noError)
    {
      this.message  ="";
      this.loginAuthenticationService.changePasswordService(inputParams).subscribe(data => {
        console.log("changePasswordService, reset --> Response :" + data); 
        if(data != null && data.statusFlag == 'S')
        {
           this.messageColor  = "alert alert-success";
      	   this.message = "Password updated successfully.";
           this.changePassword = false;
        }else
        {
          this.errorList = data.status;
          //this.router.navigate(['/login']);
        }
            
      });
    }

}

 updateContactDetails() {
    let inputParams : any = { userName: this.loggedUserName, email: this.emailId, contactName: this.contactName}
  console.log("Inside resetEmail");
  
    var emailRegex = new RegExp("^[-+.0-9A-Z_a-z]+@[-+.0-9A-Z_a-z]+\.[A-Za-z]{2,4}$");    
    this.errorList ="";
    var noError = false;
    if (false == emailRegex.test(this.emailId)) {
      this.contactMessageColor  = "alert alert-danger";
      this.contactMessage = "Invalid Email.";  
    }else if(this.contactName == ""){
	  this.contactMessageColor  = "alert alert-danger";
      this.contactMessage = "Please enter Contact Name.";
	}else{
		noError = true;
	}

    if(noError)
    {
      this.message  ="";
      this.loginAuthenticationService.updateContactService(inputParams).subscribe(data => {
        console.log("updateContactService, update --> Response :" + data); 
        if(data != null && data.status == 'S')
        {
           this.contactMessageColor  = "alert alert-success";
      	   this.contactMessage = "Contact info updated successfully.";
           this.updateContact = false;
        }else
        {
          this.contactMessageColor  = "alert alert-danger";
      	  this.contactMessage = "Error while updating Contact Info.";  
        }
            
      });
    }

}

 updatePwdChkClick() {
 	if(this.changePassword){
		this.changePassword = false;
	}else{
		this.changePassword = true;
	}
}

 updateContactChkClick() {
 	if(this.updateContact){
		this.updateContact = false;
	}else{
		this.updateContact = true;
	}
}

getUserContactInfo(){
	if(this.loggedUserName)
	 {	
		let inputParams : any = { userName: this.loggedUserName, 
	       email : ""}
		this.loginAuthenticationService.validateEpartUser(inputParams).subscribe
		    (data =>{  
				if(data != null && data["status"] == 'Y'){
					this.emailId = data["emailId"];
					this.contactName = data["contactName"];	    
			     	console.log("validateEpartUser --> emailId :" + data);	  
				}    
		      });
	  }
}
 
openUserModal() {
 	this.changePassword = false;
    this.updateContact = false;
  	this.messageColor="";
  	this.message ="";
    this.contactMessageColor  = "";
    this.contactMessage = "";
 	this.errorList ="";
	this.newPassword ="";
  	this.confirmPassword ="";
  	this.oldPassword="";
	this.getUserContactInfo();
}

 submitForm() {
	this.messageColor="";
  	this.message ="";
    this.contactMessageColor  = "";
    this.contactMessage = "";
	this.errorList ="";
	
	if(this.changePassword){
		this.resetPassword();
	}
	if(this.updateContact){
		this.updateContactDetails();
	}
}
}