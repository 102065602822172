import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';
import { Util } from 'src/app/app.util';
import { DatePipe } from '@angular/common';
import { OrderDetailCsaService } from 'src/app/service/order-mgmt-service/order-detail-csa.service';

declare var $: any;
@Component({
  selector: 'app-order-detail-csa',
  templateUrl: './order-detail-csa.component.html',
  styleUrls: ['./order-detail-csa.component.css']
})
export class OrderDetailCsaComponent implements OnInit {

 
  loggedUserName=''; 
  loggedUserFullName='';
  orderNum: number;
  totalconfigList:Number;
  totalcontactList:Number;
  totalshipmentList:Number;
  totalcompensationList:Number;
  totalinstallCallList:Number;
  totalnotesList:Number;
  totalAttachmentList :Number;
  selectedOrder: any[] = [];

  csaOrderGsd: any[] = [];
  csaOrderShipmentList: any[] = [];
  csaOrderCompensationList: any[] = [];
  csaOrderInstallCallList: any[] = [];
  csaOrderContactList: any[] = [];
  csaOrderNotesList: any[] = [];
  csaOrderAttachmentsList: any[] = [];

  configTab: string ='orderList-tab-active';
  shipmentTab: string = '';
  compensationTab: string = '';
  installCallTab: string = '';
  contactsTab: string = '';
  notesTab:string ='';
  attachmentTab:string ='';
  message: string = '';
  id: any ='';
  sub: any;
  subod: any;
  subcn: any;
  subhd: any;
  ord: any ='';
  custNm: any ='';
  subpo: any;
  refPoNum: any;
  checkboxModel:string='';
  saveStatus: any;
  headerId: any;
  csaOrderDelHist: any;
  selectedUploadedOrder: any;
  fileToUpload: File = null;
  lineId: any;
  fileMsg:string;
  validateShipStatus:string;
  saveShipStatus:string;
  confirmShipStatus:string;
  reasonCode: string;
  comments: string;
  disableProp: any;
  shipBtnDisable: boolean;
  serializedDate:string;
  masterSelected:boolean;
  checklist:any;
  shipmentCheckedList:any;
  contactCheckedList: any;
  masterContactsSelected: boolean;
  masterShipmentSelected: boolean;
  defaultUrlNew: any;
  selectedOrderDate: Date;
  scheduldedDelDate: string;
  latest_date: any;
  reasonCodeErrMsg: string;
  reasonCodeList: any;
  showReasonCodeErr: string;
  confirmedLines: any;
  showCheckboxErr: string;
  showContactErr: string;
  shipOrgId: any;
  dealerNm: any ='';
  errorMsg: any;
  shipmentErrorMsg: any;
  shipmentConfirmErrorMsg: any;
  minDate = new Date('2019/09/14');
  
  selectedSchDate: Date;
  checkShipmentSchDt: any;
  csaNm: any;
  csaOrderConfigList: any;
  commonErrMsg: string;
  csaCompFlag: any;
  setConfigNum = true;
  setTotalPrice = true;
  setUnitPrice = true;
  setItemType = true;
  setQty = true;
  setServiceModel = true;
  setItemCode = true;
  setItemDesc = true;
  setLineNum =true;
  setRma =true;

setRetAct =true;
setDelHist =true;
setDelInvDest =true;
setCnaCode =true;
setCusaPo =true;
setPrAtt =true;
setState =true;
setZipCode =true;
setCity =true;
setCusaDelNum =true;
setReasonCode =true;
setSchDelDate =true;
setShipStatus =true;
setSerNum =true;
setShippedDate =true;
setSchHist =true;
setInvSrc =true;
setInvLoc =true;
setDelStatus =true;
setAddress= true;
setNotes= true;
setFindFee =true;
setRemComp =true;
setInstFee =true;
setPricePlan =true;
setContType =true;
setBasePrice =true;
setBwAll =true;
setBwCpc =true;
setClrAll =true;
setClrCpc =true;
setBaseBillCycle =true;
setUsBillCycle =true;
setTerm =true;
setDeliveryConfDate=true;
setSerCallNum =true;
setInsCallStatus =true;
setSerCallDate =true;

setDPConName =true;
setDPConPhone =true;
setDPConEmail =true;
setDSConName =true;
setDSConPhone =true;
setDSConEmail =true;
setCPConName =true;
setCPConPhone =true; 
setCPConEmail =true;
setNotesId =true;
setTimestamp =true;
setComment =true;
setSource =true;
setCreatedBy =true;
setSelectAll =true;
tableLoader = true; 
  isAdmin: string;
  parentCustName: any;
  parentCustPhone: any;
  parentCustEmail: any;
  disableSaveFlag: boolean = true;
  ohaddress: string = "";
  ohcontactname: string = "";
  ohcontactphone: string = "";
  ohcontactemail: string = "";
  ohtotal: number =0;
  ohcomptotal:   number =0;
  ohexpensetotal:  number =0;
  ohitemstotal:   number =0;
  newcomment: string ='';
  saveNotesStatus: string ='';
  submitNotesBtn: boolean =false;
  saveNotesErrorStatus: string ='';
  reqNumber: string ='';
  showAddBtn: boolean = false;
  setAttOrderNum=true;
  setDocType=true;
  setDocRef=true;
  setFileName=true;
  setFileDesc=true;
  setDateTime=true;
  setAttSource=true;
  setDelete=true;
  setBlkMtrRdSt =true;
  setBlkMtrRdEd =true;
  setClrMtrRdSt =true;
  setClrMtrRdEd =true;
  orderNumber: any;
  dealerCsaObj: any;
  source: string= '';
  masterOrderLineSelected: boolean;
  orderLineCheckedList: any[];
  conCatLineNumber: string= '';
  docTypebtn: boolean = false;
  requestId: string= '';
  searchInstall:  string= '';
  searchOrderLine:  string= '';
  searchOrderItem:  string= '';
  searchOrderServiceModel:  string= '';
  searchOrderDescription:  string= '';
  searchSerialNumber:  string= '';
  searchRequestNumber: string= '';
  orderLineNumberValue: string= '';
  checkBoxError: string;
  checkBoxErrorMsg: string= '';
  selectedDocType = '';
  selectedOrderLine: any[] = [];
  parentOrderLineId: string= '';
  warningFileMsg: string;
  warningFileDocMsg: string;
  commonFileErrMsg: string;
  fileLineId: any;
  desc: string;
  fileName: any;
  attachId: any;
  valFiletype: boolean;
  searchOrderDesc: string;
  searchOrderSerNum: string;
  ordLineReqId: any;
  fileMainMsg: any;
  valFileSize: boolean;
  ordStatus:string = "";
  ohordDate:string = "";
  ohinventorysource: string = "";
  ohreqinstdate:string = "";
  ohcustcontactname: string = "";
  ohcustcontactphone:string = "";
  ohcustcontactemail: string = "";
  ohdealercontactname: string = "";
  ohdealercontactphone:string = "";
  ohdealercontactemail: string = "";
  ohdealercontactaddress: string = "";
  showShipmentErr: string;
  ohdealercontactcity: string = "";
  ohdealercontactstate: string = "";
  ohdealercontactzip: string = "";

  constructor(private activatedRoute: ActivatedRoute,private loginAuthenticationService:LoginAuthenticationService, private orderDtlCsaService: OrderDetailCsaService,private util : Util,public datepipe: DatePipe, private titleService: Title) { }
  dealerOrderConfigList: any[] = [];

   ngOnInit(): void {  
    console.log("minDate:"+this.minDate);
    this.isAdmin=	sessionStorage.getItem('Admin');
    this.validateShipStatus == "";
    this.shipBtnDisable=false;
    this.setCSACSS();
    this.configTab = 'orderList-tab-active';
   // $( "#validateShip" ).empty();
    /* this.sub = this.activatedRoute.params.subscribe(params => {
    //    this.id = params['id'];
    //    });
       this.subod = this.activatedRoute.params.subscribe(params => {
         this.ord = params['od'];
         });
         this.subcn = this.activatedRoute.params.subscribe(params => {
           this.custNm = params['cn'];
           });
         this.subpo = this.activatedRoute.params.subscribe(params => {
             this.refPoNum = params['po'];
             });
         this.subhd = this.activatedRoute.params.subscribe(params => {
               this.headerId = params['hd'];
               });
            // this.sub = this.activatedRoute.params.subscribe(params => {
            //     this.csaNm = params['dn'];
            // });*/
            this.sub = this.activatedRoute.queryParams.subscribe(params => {            
              this.ord =  params['od'];             
              this.headerId = params['hd'];
              this.reqNumber =params['reqN'];
              this.ordLineReqId =params['reqID'];
              this.ordStatus= params['ordStatus'];
              this.ohordDate= params['ohordDate'];
              });
       console.log(" this.ohordDate:"+ this.ohordDate);
      this.tableLoader = true; 
   this.orderDtlCsaService.getCsaDtlList('CONFIG',this.ord).subscribe(data => {
    this.csaOrderConfigList = data.data;
    console.log('csa order list',this.csaOrderConfigList);
    //console.log(JSON.stringify(this.csaOrderList));
  this.totalconfigList=this.csaOrderConfigList.length;
  this.tableLoader = false; 
  },
  (error) =>
  {
     this.commonErrMsg = "";
     this.tableLoader = false; 
     console.log("Error: "+error);
     this.commonErrMsg =error;
  });

  this.loggedUserName= this.loginAuthenticationService.getAuthenticatedUser();
  this.loggedUserFullName= this.loginAuthenticationService.getAuthenticatedUserFullName();

  this.checkboxModel='Yes';
let username = this.loggedUserName;  
this.titleService.setTitle("Order Details");
this.getOrderDtlHeader();
  
  }
  
  changeConfigSelectAll() {
    if(this.setSelectAll) {
     this.setConfigNum = true;
     this.setTotalPrice = true;
     this.setUnitPrice = true;
     this.setItemType = true;
     this.setQty = true;
     this.setServiceModel = true;
     this.setItemCode = true;
     this.setItemDesc = true;
     this.setLineNum =true;
     this.setRma =true;
    }else {

     this.setConfigNum = false;
     this.setTotalPrice = false;
     this.setUnitPrice = false;
     this.setItemType = false;
     this.setQty = false;
     this.setServiceModel = false;
     this.setItemCode = false;
     this.setItemDesc = false;
     this.setLineNum =false;
     this.setRma =false;
    } 
  }

  changeAttachmentsSelectAll() {
    if(this.setSelectAll) {
      this.setAttOrderNum=true;
      this.setDocType=true;
      this.setDocRef=true;
      this.setFileName=true;
      this.setFileDesc=true;
      this.setDateTime=true;
      this.setAttSource=true;
      this.setDelete=true;
    }else {

      this.setAttOrderNum=false;
      this.setDocType=false;
      this.setDocRef=false;
      this.setFileName=false;
      this.setFileDesc=false;
      this.setDateTime=false;
      this.setAttSource=false;
      this.setDelete=false;
    } 
  }
  changeShipmentSelectAll() {
    if(this.setSelectAll) {
      this.setRetAct =true;
      this.setDelHist =true;
      this.setDelInvDest =true;
      this.setCnaCode =true;
      this.setCusaPo =true;
      this.setPrAtt =true;
      this.setAddress =true;
      this.setNotes =true;
      this.setState =true;
      this.setZipCode =true;
      this.setCity =true;
      this.setCusaDelNum =true;
      this.setReasonCode =true;
      this.setSchDelDate =true;
      this.setShipStatus =true;
      this.setSerNum =true;
      this.setShippedDate =true;
      this.setSchHist =true;
      this.setInvSrc =true;
      this.setInvLoc =true;
      this.setDelStatus =true;
      this.setLineNum =true;
     this.setItemCode = true;
     this.setItemDesc = true;    
     this.setRma =true;
     this.setDeliveryConfDate=true;
     this.setBlkMtrRdSt =true;
     this.setBlkMtrRdEd =true;
     this.setClrMtrRdSt =true;
     this.setClrMtrRdEd =true;
    }else {

      this.setRetAct =false;
      this.setDelHist =false;
      this.setDelInvDest =false;
      this.setCnaCode =false;
      this.setCusaPo =false;
      this.setPrAtt =false;
      this.setAddress =false;
      this.setNotes =false;
      this.setState =false;
      this.setZipCode =false;
      this.setCity =false;
      this.setCusaDelNum =false;
      this.setReasonCode =false;
      this.setSchDelDate =false;
      this.setShipStatus =false;
      this.setSerNum =false;
      this.setShippedDate =false;
      this.setSchHist =false;
      this.setInvSrc =false;
      this.setInvLoc =false;
      this.setDelStatus =false;
      this.setLineNum =false;
     this.setItemCode = false;
     this.setItemDesc = false;    
     this.setRma =false;
     this.setDeliveryConfDate=false;
     this.setBlkMtrRdSt =false;
     this.setBlkMtrRdEd =false;
     this.setClrMtrRdSt =false;
     this.setClrMtrRdEd =false;
    } 
  }

  
  changeCompensationSelectAll() {
    if(this.setSelectAll) {
      this.setFindFee =true;
      this.setRemComp =true;
      this.setInstFee =true;
      this.setPricePlan =true;
      this.setContType =true;
      this.setBasePrice =true;
      this.setBwAll =true;
      this.setBwCpc =true;
      this.setClrAll =true;
      this.setClrCpc =true;
      this.setBaseBillCycle =true;
      this.setUsBillCycle =true;
      this.setTerm =true;
      this.setItemCode = true;
      this.setItemDesc = true;    
      this.setRma =true;
      this.setLineNum =true;
    }else {

      this.setFindFee =false;
      this.setRemComp =false;
      this.setInstFee =false;
      this.setPricePlan =false;
      this.setContType =false;
      this.setBasePrice =false;
      this.setBwAll =false;
      this.setBwCpc =false;
      this.setClrAll =false;
      this.setClrCpc =false;
      this.setBaseBillCycle =false;
      this.setUsBillCycle =false;
      this.setTerm =false;
      this.setItemCode = false;
      this.setItemDesc = false;    
      this.setRma =false;
      this.setLineNum =false;
    } 
  }
  changeInstallSelectAll() {
    if(this.setSelectAll) {
      this.setSerCallNum =true;
      this.setInsCallStatus =true;
      this.setSerCallDate =true;
      this.setItemCode = true;
      this.setItemDesc = true;    
      this.setRma =true;
      this.setLineNum =true;
    }else {
      this.setSerCallNum =false;
      this.setInsCallStatus =false;
      this.setSerCallDate =false;
      this.setItemCode = false;
      this.setItemDesc = false;    
      this.setRma =false;
      this.setLineNum =false;
    } 
  }

  changeContactsSelectAll() {
    if(this.setSelectAll) {
      this.setDPConName =true;
      this.setDPConPhone =true;
      this.setDPConEmail =true;
      this.setDSConName =true;
      this.setDSConPhone =true;
      this.setDSConEmail =true;
      this.setCPConName =true;
      this.setCPConPhone =true; 
      this.setCPConEmail =true;
      this.setItemCode = true;
      this.setItemDesc = true;    
      this.setRma =true;
      this.setLineNum =true;
    }else {

      this.setDPConName =false;
      this.setDPConPhone =false;
      this.setDPConEmail =false;
      this.setDSConName =false;
      this.setDSConPhone =false;
      this.setDSConEmail =false;
      this.setCPConName =false;
      this.setCPConPhone =false; 
      this.setCPConEmail =false;
      this.setItemCode = false;
      this.setItemDesc = false;    
      this.setRma =false;
      this.setLineNum =false;
    }
  }
  changeNotesSelectAll() {
    if(this.setSelectAll) {
      this.setNotesId=true;
      this.setTimestamp =true;
      this.setComment =true;
      this.setSource = true;
      this.setCreatedBy = true;   
      
    }else {
      this.setNotesId=false;
      this.setTimestamp =false;
      this.setComment =false;
      this.setSource = false;
      this.setCreatedBy = false; 
    } 
  }
  ngOnDestroy() {
    // this.sub.unsubscribe();
    // this.subod.unsubscribe();
    // this.subcn.unsubscribe();
    // this.subpo.unsubscribe();
    // this.subhd.unsubscribe();
  }
  
  onBack(): void {
    // this._router.navigate(['product']);
  }
 
   changeDate(event){ 
    // Return date object
    //alert("Change Date");
    let dd = event.getDate();
    let mm = event.getMonth() + 1; //January is 0!
    let yyyy = event.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    //this.selectedSchDate =  yyyy+ '/' + mm+ '/' + dd ;
    //this.selectedOrderDate =  mm + '/' + dd + '/' + yyyy ;
    //this.selectedOrderDate =this.datepipe.transform(event, 'MM/dd/yyyy'); 
    //this.orderDate = this.selectedOrderDate ;
    //this.selectedOrderDate.toLocaleDateString();
    console.log("this.selectedSchDate:"+this.selectedSchDate);
    console.log(event);
    //console.log('dd',dd);
    return;
  }
  setTab(event:any){
    console.log(event);
    if(event == 'config'){
     this.configTab = 'orderList-tab-active';
     this.shipmentTab = '';
     this.compensationTab='';
     this.installCallTab='';
     this.contactsTab='';
     this.notesTab='';
     this.attachmentTab='';
     this.commonErrMsg = "";
     this.tableLoader = true; 
     this.orderDtlCsaService.getCsaDtlList('CONFIG',this.ord).subscribe(data => {
      this.csaOrderConfigList = data.data;
      console.log('csaOrderConfigList',this.csaOrderConfigList);
    //console.log(JSON.stringify(this.csaOrderList));
      this.totalconfigList=this.csaOrderConfigList.length;
     this.tableLoader = false; 
    },
    (error) =>
    {
       this.commonErrMsg = "";
       this.tableLoader = false; 
       console.log("Error: "+error);
       this.commonErrMsg =error;
    });
   
    }else if (event == 'shipment') {
     this.shipmentTab = 'orderList-tab-active';
     this.validateShipStatus = "";
     this.showReasonCodeErr = "";
     this.saveShipStatus = "";
     this.showCheckboxErr = "";
     this.commonErrMsg = "";
     this.tableLoader = true; 
     this.saveStatus ="";
     this.orderDtlCsaService.getCsaDtlList('SHIPMENT',this.ord).subscribe(data => {
      this.csaOrderShipmentList = data.data;
      if(this.csaOrderShipmentList != null || this.csaOrderShipmentList != undefined)
      {
      for (var i = 0; i < this.csaOrderShipmentList.length; i++) {
        this.csaOrderShipmentList[i].scheduldedDelDate = new Date(this.csaOrderShipmentList[i].schDateString);     
      }
      console.log('shipment order csa list',this.csaOrderShipmentList);

      //console.log(JSON.stringify(this.csaOrderList));
    this.totalshipmentList=this.csaOrderShipmentList.length;
     this.tableLoader = false; 
      }
    },
    (error) =>
    {
       this.commonErrMsg = "";
       this.tableLoader = false; 
       console.log("Error: "+error);
       this.commonErrMsg =error;
    });
     this.configTab = '';
     this.compensationTab='';
     this.installCallTab='';
     this.contactsTab='';
     this.notesTab ='';
     this.attachmentTab='';
    }
    else if (event == 'compensation') {
      this.compensationTab = 'orderList-tab-active';
      this.commonErrMsg = "";
      this.tableLoader = true; 
      this.orderDtlCsaService.getCsaDtlList('COMPENSATION',this.ord).subscribe(data => {
        this.csaOrderCompensationList = data.data;
        console.log('compensation order csa list',this.csaOrderCompensationList);
        //console.log(JSON.stringify(this.csaOrderList));
        for (var i = 0; i < this.csaOrderCompensationList.length; i++) {       
          this.csaCompFlag=this.csaOrderCompensationList[i].gsd;       
         }
         console.log("delearCompFlag::"+ this.csaCompFlag);
      this.totalcompensationList=this.csaOrderCompensationList.length;
      this.tableLoader = false; 
      },
      (error) =>
      {
         this.commonErrMsg = "";
         this.tableLoader = false; 
         console.log("Error: "+error);
         this.commonErrMsg =error;
      });
      this.configTab = '';
      this.shipmentTab = '';
      this.installCallTab='';
      this.contactsTab='';
      this.notesTab='';
      this.attachmentTab='';
     }
     else if (event == 'installCall') {
      this.installCallTab = 'orderList-tab-active';
      this.commonErrMsg = "";
      this.tableLoader = true; 
      this.orderDtlCsaService.getCsaDtlList('INSTALL_CALL',this.ord).subscribe(data => {
        this.csaOrderInstallCallList = data.data;
        console.log('Insatll order csa list',this.csaOrderInstallCallList);
        //console.log(JSON.stringify(this.csaOrderList));
      this.totalinstallCallList=this.csaOrderInstallCallList.length;
      this.tableLoader = false; 
      },
      (error) =>
      {
         this.commonErrMsg = "";
         this.tableLoader = false; 
         console.log("Error: "+error);
         this.commonErrMsg =error;
      });
      this.configTab = '';
      this.shipmentTab = '';
      this.compensationTab='';
      this.contactsTab='';
      this.notesTab='';
      this.attachmentTab='';
     }
     else if (event == 'contacts') {
      this.contactsTab = 'orderList-tab-active';
      this.showContactErr = ""; 
      this.saveStatus = "";
      this.commonErrMsg = "";
      this.tableLoader = true; 
      this.masterContactsSelected = false;
      this.orderDtlCsaService.getCsaDtlList('CONTACTS',this.ord).subscribe(data => {
        this.csaOrderContactList = data.data;
        console.log('contacts order csa list',this.csaOrderContactList);
        //console.log(JSON.stringify(this.csaOrderList));
      this.totalcontactList=this.csaOrderContactList.length;
      for (var i = 0; i < this.csaOrderContactList.length; i++) {     
        if(this.csaOrderContactList[i].contactDisableFlag == false)  
            this.disableSaveFlag=false;             
       }
       this.tableLoader = false; 
      },
      (error) =>
      {
         this.commonErrMsg = "";
         this.tableLoader = false; 
         console.log("Error: "+error);
         this.commonErrMsg =error;
      });
      this.configTab = '';
      this.shipmentTab = '';
      this.compensationTab='';
      this.installCallTab='';
       this.notesTab='';
       this.attachmentTab='';
     }
     else if (event == 'notes') {
      this.notesTab = 'orderList-tab-active';
      this.showContactErr = ""; 
      this.saveStatus = "";
      this.commonErrMsg = "";
      this.tableLoader = true; 
      this.masterContactsSelected = false;
      
      if(this.reqNumber != null || this.reqNumber != '')
          {
            if(this.reqNumber.trim().startsWith('EP-'))
            {
              this.showAddBtn = true;
            }
          }
          else
          this.showAddBtn = false;
      this.orderDtlCsaService.getCsaDtlList('NOTES',this.ord).subscribe(data => {
        this.csaOrderNotesList = data.data;
        console.log('notes order csa list',this.csaOrderNotesList);
        //console.log(JSON.stringify(this.csaOrderList));
      this.totalnotesList=this.csaOrderNotesList.length;     
       this.tableLoader = false; 
      },
      (error) =>
      {
         this.commonErrMsg = "";
         this.tableLoader = false; 
         console.log("Error: "+error);
         this.commonErrMsg =error;
      });
      this.configTab = '';
      this.shipmentTab = '';
      this.compensationTab='';
      this.installCallTab='';
      this.contactsTab='';
      this.attachmentTab='';
     }
     else if (event == 'attachments') {
      this.attachmentTab= 'orderList-tab-active dealerTabs';
      this.showContactErr = ""; 
      this.saveStatus = "";
      this.commonErrMsg = "";
      this.tableLoader = true; 
      this.masterContactsSelected = false;
      this.orderDtlCsaService.getCsaDtlList('ATTACHMENTS',this.ord).subscribe(data => {
        this.csaOrderAttachmentsList = data.data;
        console.log('Attachments order csa list',this.csaOrderAttachmentsList);
        //console.log(JSON.stringify(this.csaOrderList));
      this.totalAttachmentList=this.csaOrderAttachmentsList.length;     
       this.tableLoader = false; 
      },
      (error) =>
      {
         this.commonErrMsg = "";
         this.tableLoader = false; 
         console.log("Error: "+error);
         this.commonErrMsg =error;
      });
      this.configTab = '';
      this.shipmentTab = '';
      this.compensationTab='';
      this.installCallTab='';
      this.contactsTab='';
      this.notesTab='';
     }

  }
  contactCheckUncheckAll() {
    for (var i = 0; i < this.csaOrderContactList.length; i++) {
      if(this.csaOrderContactList[i].disableProp != "true")
      {
      this.csaOrderContactList[i].isCheck = this.masterContactsSelected;
      if(this.csaOrderContactList[i].isCheck == true)
      {          
         this.parentCustName =this.csaOrderContactList[0].custSecContactName;
         this.parentCustPhone=this.csaOrderContactList[0].custSecContactPhone;
         this.parentCustEmail=this.csaOrderContactList[0].custSecContactEmail;
         this.csaOrderContactList[i].custSecContactName = this.csaOrderContactList[i].custSecContactName == "" ?this.parentCustName: this.csaOrderContactList[i].custSecContactName;
         this.csaOrderContactList[i].custSecContactPhone = this.csaOrderContactList[i].custSecContactPhone == "" ?this.parentCustPhone: this.csaOrderContactList[i].custSecContactPhone;
         this.csaOrderContactList[i].custSecContactEmail = this.csaOrderContactList[i].custSecContactEmail == "" ?this.parentCustEmail: this.csaOrderContactList[i].custSecContactEmail;

      }
      }
    }
    this.getCheckedContactList();
  }
  setCSACSS() {
    $("#side-nav-logo-button-wrapper,#mobile-menu-modal-header").css("background", " url('assets/img/csa-bg.jpg')");
    $(".site-logo").attr("src", "assets/img/ep-csa-logo.png");
    $("#main-top-nav-wrapper-clone").css("background", "#555");
    $("#main-side-nav,#mobile-menu-modal-body").css("background", "#333");
    $(".main-top-navbar").css("border-top", "1px solid rgb(186, 179, 171)");
    $(".fa-ellipsis-v").css("color", "rgb(204, 204, 204)");
    $("#main-side-nav .inner ul li.side-menu-li,#mobile-menu-links ul li.mobile-menu-li,.single-link-side-menu-li,.single-link-mobile-menu-li").css({ "border": "1px solid #333", "background-color": "#dbd5ce", "color": "#59534d" });
  
    $(".side-less-icons-wrapper").hover(() => {
        $(".side-less-icons-wrapper .side-more-icons").css("color", "#dbd5ce");
    }, () =>{
        $(".side-less-icons-wrapper .side-more-icons").css("color", "#fff");
    });
    $(".side-more-icons-wrapper").hover(() => {
        $(".side-more-icons-wrapper .side-more-icons").css("color", "#dbd5ce");
    }, () => {
        $(".side-more-icons-wrapper .side-more-icons").css("color", "#fff");
    });
  
    $(".side-menu-button-wrapper i").hover(() => {
        $(this).css({ "color": "#dbd5ce", "border": "3px solid #dbd5ce" });
    }, () =>{
        $(this).css({ "color": "#fff", "border": "3px solid #fff" });
    });
    $(".tooltip .tooltip-inner").css("border-left", "7px solid #dbd5ce");
    $(".group-switcher").css({ "border": "1px solid #969089", "background-color": "#999" ,"pointer-events": "none"});
    $(".side-menu-wrapper-inner").css("border-top", "1px solid #ccc");
    $(".main-dealers-content-wrapper,.dealer-page-header-title").hide();
    $(".main-csa-content-wrapper,.csa-page-header-title").fadeIn();
    $(".btn-primary").css({ "border-color": "#969089", "background-color": "#999", "color": "#fff" });
    $(".result-table tr td i").css({ "color": "#333", "border": "solid 1px #dbd5ce" });
    $("html,body").css({ "background-color": "#ebe7e4" });
    $(".main-top-navbar-left-content,.main-top-navbar-links").css({ "color": "#fff" });
    $(".group-switcher-inner").css({ "color": "#fff" });
    $(".result-table tr th").css({ "background-color": "#333" });
    $('.sm-switcher .group-switcher-inner').css("margin-left", "5px");
    $('.md-switcher .group-switcher-inner').css("margin-left", "0");
  
  }
  openModel(lineId)
  {
   // alert("openModel");
   this.commonErrMsg = "";
     this.orderDtlCsaService.getGsdComp(this.ord,lineId).subscribe(data => {
      this.csaOrderGsd = data.data;
      this.commonErrMsg = "";
      console.log('GSD order list',this.csaOrderGsd);
      //console.log(JSON.stringify(this.csaOrderList));
   // this.totalconfigList=this.csaOrderGsd.length;
    },
    (error) =>
    {
       this.commonErrMsg = "";
       console.log("Error: "+error);
       this.commonErrMsg =error;
    });
    
  }
  openDeliveryPickupHistory(lineId)
  {
   // alert("openModel");
   this.validateShipStatus = "";
   this.showReasonCodeErr = "";
   this.saveShipStatus = "";
   this.showCheckboxErr = "";
   this.confirmShipStatus = "";
   this.commonErrMsg = "";
   if(lineId == undefined)
       lineId="";
     this.orderDtlCsaService.getDelPickupHist(this.ord ,lineId,this.headerId).subscribe(data => {
      this.csaOrderDelHist = data.data;
      console.log('DelHistory order list',this.csaOrderDelHist.length);
      //console.log(JSON.stringify(this.csaOrderList));
   // this.totalconfigList=this.csaOrderGsd.length;
    },
    (error) =>
    {
       this.commonErrMsg = "";
       console.log("Error: "+error);
       this.commonErrMsg =error;
    });
    
  }
  tempArr: any = { "contacts": [] };
  
  contactsChange(param,event){ // Use appropriate model type instead of any
    if ( event.target.checked )
    { 
      if(param.lineId !="" || param.lineId != null)
      this.tempArr.contacts.push(param);
      $('#saveContactBtn').attr("disabled", false);        
    }
    else
    {    
      param.splice(param.lineId,1);
      $('#saveContactBtn').attr("disabled", true);
    }
    
  }

  shipmentChange(param,event){ // Use appropriate model type instead of any
    if ( event.target.checked )
    { 
      if(param.lineId !="" || param.lineId != null)
      this.tempArr.shipments.push(param);
      $('#saveShipmentBtn').attr("disabled", false);        
    }
    else
    {    
      param.splice(param.lineId,1);
      $('#saveShipmentBtn').attr("disabled", true);
    }
    
  }

  getFormattedDate(tDate){
    let dd = tDate.getDate();
    let mm = tDate.getMonth() + 1; //January is 0!
    let yyyy = tDate.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return  mm + '-' + dd + '-' + yyyy ;  
}
contactsAutoCheck(contact)
    {
      this.showContactErr = "";
      this.commonErrMsg = "";
      this.saveStatus = "";
      contact.isCheck = true;
    }
    saveContacts()
    {
      //alert("Clicked Save");
      this.contactCheckedList= this.getCheckedContactList();
      this.showContactErr = "";
      this.commonErrMsg = "";
      if(this.contactCheckedList.length != 0)
      {
      console.log("contactCheckedList:"+this.contactCheckedList);
      this.orderDtlCsaService.saveCsaContacts(this.contactCheckedList,this.ord ,this.headerId,this.shipOrgId).subscribe(data => {          
        this.showContactErr = "";  
        this.commonErrMsg = "";            
        console.log('saveStatus order list',this.saveStatus);
        console.log('SuccessMsg from save',data[0]); 
        this.saveStatus = data[0];   
       // $("#save-contact-detils-modal").modal("show");
        this.orderDtlCsaService.getCsaDtlList('CONTACTS',this.ord).subscribe(data => {
          this.csaOrderContactList = data.data;
          this.masterContactsSelected = false;
          console.log('Csa order contact list',this.csaOrderContactList);
          for (var i = 0; i < this.csaOrderContactList.length; i++) {     
            if(this.csaOrderContactList[i].contactDisableFlag == false)  
            this.disableSaveFlag=false; 
           }
        this.totalcontactList=this.csaOrderContactList.length;
        },
        (error) =>
        {
           this.commonErrMsg = "";
           console.log("Error: "+error);
           this.commonErrMsg =error;
        });     
      },
      (error) =>
      {
         this.commonErrMsg = "";
         console.log("Error: "+error);
         this.commonErrMsg =error;
      });
    }
    else
        {
          this.saveStatus = "";
          this.commonErrMsg = "";
          this.showContactErr = "Please check the checkbox and click save";
        }
    }

    shipmentAutoCheck(shipment)
    {
      this.showContactErr = "";
      this.commonErrMsg = "";
      this.saveStatus = "";
      shipment.isCheck = true;
    }
    saveCsaShipments()
    {
      //alert("Clicked Save");
      this.shipmentCheckedList= this.getCheckedShipmentList();
      this.showShipmentErr = "";
      this.commonErrMsg = "";
      this.saveStatus ="";
      if(this.shipmentCheckedList.length != 0)
      {
      console.log("shipmentCheckedList:"+this.shipmentCheckedList);
      this.orderDtlCsaService.saveshipmentCsa(this.shipmentCheckedList,this.ord ,this.headerId).subscribe(data => {          
        this.showShipmentErr = "";  
        this.commonErrMsg = "";            
        console.log('saveStatus order list',this.saveStatus);
        console.log('SuccessMsg from save',data[0]); 
        this.saveStatus = data[0];   
       // $("#save-contact-detils-modal").modal("show");
        this.orderDtlCsaService.getCsaDtlList('SHIPMENT',this.ord).subscribe(data => {
          this.shipmentCheckedList = data.data;
          this.masterShipmentSelected = false;
          console.log('Csa order shipment list',this.shipmentCheckedList);         
        this.totalshipmentList=this.shipmentCheckedList.length;
        },
        (error) =>
        {
           this.commonErrMsg = "";
           console.log("Error: "+error);
           this.commonErrMsg =error;
        });     
      },
      (error) =>
      {
         this.commonErrMsg = "";
         console.log("Error: "+error);
         this.commonErrMsg =error;
      });
    }
    else
        {
          this.saveStatus = "";
          this.commonErrMsg = "";
          this.showShipmentErr = "Please check the checkbox and click save";
        }
    }

   
    getSaveParam (param) { 
      return {
        "lineId":param.lineId,
        "itemCode":param.itemCode,
        "itemDesc":param.itemDesc,
        "lineNum":param.lineNum,
        "rma":param.rma,
        "serialNum":param.serialNum,       
        "scheduldedDelDate": param.schDateString,        
        "reasonCode":param.reasonCode,
        "notes":param.schedulingNotes,
        "serialChangeFlag": "",
        "ssdChangeFlag": "",
        "contactName": param.custSecContactName == null ? " ": param.custSecContactName.trim(),
        "phoneNumber":param.custSecContactPhone  == null ? " ": param.custSecContactPhone.trim(),
        "email": param.custSecContactEmail  == null ? " ": param.custSecContactEmail.trim(),      
        "modelBlkMtrRdStart":parseInt(param.modelBlkMtrRdStart) == 0 ? 0: parseInt(param.modelBlkMtrRdStart),
        "modelBlkMtrRdEnd":parseInt(param.modelBlkMtrRdEnd) == 0 ? 0: parseInt(param.modelBlkMtrRdEnd),
        "modelClrMtrRdStart":parseInt(param.modelClrMtrRdStart)  == 0 ? 0: parseInt(param.modelClrMtrRdStart),
        "modelClrMtrRdEnd":parseInt(param.modelClrMtrRdEnd) == 0 ? 0: parseInt(param.modelClrMtrRdEnd),
  }
   
      }
   
  
    contactsCheckUncheckAll() {
      for (var i = 0; i < this.csaOrderContactList.length; i++) {
        this.csaOrderContactList[i].isCheck = this.masterContactsSelected;
      }
      this.getCheckedContactList();
    }
    isAllContactstSelected() {
     // $('#confirmShipBtn').attr("disabled", false);
     // $('#saveShipBtn').attr("disabled", false);
      this.masterContactsSelected = this.csaOrderContactList.every(function(contacts:any) {
          return contacts.isCheck == true;
        })
      this.getCheckedContactList();
    }
  
    getCheckedContactList(){
      this.contactCheckedList = [];
      for (var i = 0; i < this.csaOrderContactList.length; i++) {
        if(this.csaOrderContactList[i].isCheck)
        {
          this.shipOrgId=this.csaOrderContactList[i].shipToOrgId;        
        this.contactCheckedList.push(this.getSaveParam(this.csaOrderContactList[i]));
        }
      }
      //this.contactCheckedList = JSON.stringify(this.contactCheckedList);
      return this.contactCheckedList;
    }
  
    shipmentCheckUncheckAll() {
      for (var i = 0; i < this.csaOrderShipmentList.length; i++) {
        this.csaOrderShipmentList[i].isCheck = this.masterShipmentSelected;
      }
      this.getCheckedShipmentList();
    }
    isAllShipmentSelected() {
     // $('#confirmShipBtn').attr("disabled", false);
     // $('#saveShipBtn').attr("disabled", false);
      this.masterShipmentSelected = this.csaOrderShipmentList.every(function(shipments:any) {
          return shipments.isCheck == true;
        })
      this.getCheckedShipmentList();
    }
    getCheckedShipmentList(){
      this.shipmentCheckedList = [];
      for (var i = 0; i < this.csaOrderShipmentList.length; i++) {
        if(this.csaOrderShipmentList[i].isCheck)
        {
           
        this.shipmentCheckedList.push(this.getSaveParam(this.csaOrderShipmentList[i]));
        }
      }
      //this.contactCheckedList = JSON.stringify(this.contactCheckedList);
      return this.shipmentCheckedList;
    }


   pageLoad() {
    $("#page-loader-overlay")
        .delay(800)
        .fadeOut();
    $("#delay-loader")
        .delay(1000)
        .fadeOut();
       
       }
    
onNavigate(params,event) {
  event.preventDefault();
   console.log(params);
   var pgUrl = window.location.host;
   var pgUrl = "http://localhost:8080";
   this.defaultUrlNew = this.util.getBaseUrl();
   var encodedFileName= encodeURIComponent(params.fileName);
   var link = this.defaultUrlNew+'orderDtlCsa/da?requestId='+params.requestId+'&orderNumber='+params.orderNumber+'&attachmentId='+params.attachmentId+'&fileIdentifierId='+params.fileIdentifierId+'&dataType='+'FILE'+'&fileName='+encodedFileName+'&schema='+params.schema;+'&schema='+params.schema;
    console.log('upload url is : ',link);
   window.open(link, "_blank");
 }
 getOrderDtlHeader()
 {
  // alert("openModel");
  
    this.orderDtlCsaService.getOrderDtlHeaderSec(this.ord).subscribe(data => {
     
     this.ohaddress =data.ohaddress;
     this.ohcontactname =data.ohcontactname;
     this.ohcontactphone =data.ohcontactphone;
     this.ohcontactemail =data.ohcontactemail;
     this.ohtotal =data.ohtotal;
     this.ohcomptotal =data.ohcomptotal;
     this.ohexpensetotal =data.ohexpensetotal;
     this.ohitemstotal =data.ohitemstotal;
     this.custNm =data.ohcustname;    
     this.refPoNum =data.ohdealerpo;

     this.ohinventorysource =data.ohinventorysource;    
     this.ohreqinstdate =data.ohreqinstdate;
     this.ohcustcontactname =data.ohcustcontactname;
     this.ohcustcontactphone =data.ohcustcontactphone;
     this.ohcustcontactemail =data.ohcustcontactemail;

     this.ohdealercontactname =data.ohdealercontactname;
     this.ohdealercontactphone =data.ohdealercontactphone;
     this.ohdealercontactemail =data.ohdealercontactemail;
     this.ohdealercontactaddress =data.ohdealercontactaddress;
     this.ohdealercontactcity =data.ohdealercontactcity;
     this.ohdealercontactstate =data.ohdealercontactstate;
     this.ohdealercontactzip=data.ohdealercontactzip;
    // console.log('GSD order list',this.dealerOrderGsd);
     //console.log(JSON.stringify(this.csaOrderList));
  // this.totalconfigList=this.csaOrderGsd.length;
   },
   (error) =>
   {
     // this.commonGsdErrMsg = "";
      console.log("Error: "+error);
      //this.commonGsdErrMsg =error;
   });
 }

 addNotes()
  {
    this.submitNotesBtn = false;
    this.newcomment='';
    this.saveNotesStatus ='';
    this.saveNotesErrorStatus ='';
    $("#addnotes-modal").modal("show");
  }
  submitNotes()
  {
    this.saveNotesStatus ='';
    this.submitNotesBtn = false;
    this.saveNotesErrorStatus ='';
    if(this.newcomment != ''){
    this.orderDtlCsaService.saveNotesDetails(this.ord,this.id,this.headerId,this.dealerNm,this.custNm,this.newcomment,this.loggedUserName).subscribe(data => {
      if(data[0] != 'Error')
      {             
    this.saveNotesStatus=data[5];
    console.log('saveNotesStatus',this.saveNotesStatus);
    this.submitNotesBtn = true;
      }
      else
      this.commonErrMsg=data[5];
      //console.log(JSON.stringify(this.csaOrderList));
     this.tableLoader = false; 
    },
    (error) =>
    {
       this.commonErrMsg = "";
       this.saveNotesStatus ='';
       this.tableLoader = false; 
       console.log("Error: "+error);
       this.commonErrMsg =error;
    });
  }
    else
    {
      this.submitNotesBtn = false;
      this.commonErrMsg = "";
      this.saveNotesStatus ='';
      this.saveNotesErrorStatus ='';
      this.saveNotesErrorStatus ='Please add notes in text area and click submit';
    }
  }
  closeNotes()
  {
  
    this.showContactErr = ""; 
    this.saveStatus = "";
    this.commonErrMsg = "";
    this.tableLoader = true; 
    this.saveNotesStatus ='';
    this.orderDtlCsaService.getCsaDtlList('NOTES',this.ord).subscribe(data => {
      this.csaOrderNotesList = data.data;
      console.log('notes order csa list',this.csaOrderNotesList);
      //console.log(JSON.stringify(this.csaOrderList));
    this.totalnotesList=this.csaOrderNotesList.length;    
     this.tableLoader = false; 
    },
    (error) =>
    {
       this.commonErrMsg = "";
       this.saveNotesStatus ='';
       this.tableLoader = false; 
       console.log("Error: "+error);
       this.commonErrMsg =error;
    });
  }
  closeAttachments()
  {
  
    this.orderDtlCsaService.getCsaDtlList('ATTACHMENTS',this.ord).subscribe(data => {
      this.csaOrderAttachmentsList = data.data;
      console.log('Attachments order csa list',this.csaOrderAttachmentsList);
      //console.log(JSON.stringify(this.csaOrderList));
    this.totalAttachmentList=this.csaOrderAttachmentsList.length;     
     this.tableLoader = false; 
    },
    (error) =>
    {
       this.commonErrMsg = "";
       this.tableLoader = false; 
       console.log("Error: "+error);
       this.commonErrMsg =error;
    });
  }
  getOrderLineParam (orderNumber,ittNumber) {
    return {
      "orderNumber": orderNumber, 
      "ittNumber": ittNumber
    
     // "lineId": lineId
       }
    }
    getSearchOrderLineParam (orderNumber,ittNumber) {
      return {
        "orderNumber": orderNumber, 
        "ittNumber": ittNumber,
        "installReturn": this.searchInstall,
        "lineNumber": this.searchOrderLine,
        "item": this.searchOrderItem,
        "serviceModel": this.searchOrderServiceModel,
        "itemDesc": this.searchOrderDescription,
        "serialNumber": this.searchSerialNumber
         }
      }
    searchOrderLineBtn(orderNumber,ittNumber)
    {
      orderNumber=this.ord;
      ittNumber =this.id;
      this.masterOrderLineSelected = false;
      //requestNumber =this.dealerCsaObj.requestNumber;
      this.orderDtlCsaService.getSearchLineAttachments(this.getSearchOrderLineParam(this.ord,"")).subscribe(data => {
        this.selectedOrderLine = data.data;
       
      console.log("Uploaded Docs List:"+this.selectedOrderLine);
        $("#orderLineModal").modal("show");
        console.log('data passed to modal: ',this.selectedOrderLine);
        
      },
      (error) =>
      {
         this.commonErrMsg = "";  
         console.log("Error: "+error);
         this.commonErrMsg =error;
      });
    }
    clearOrderLineButton() {    
      this.searchInstall = "";
      this.searchOrderLine = "";
      this.searchOrderItem = "";
      this.searchOrderServiceModel = "";
      this.searchOrderDesc = "";
      this.searchOrderSerNum = "";
      this.masterOrderLineSelected = false;
      this.orderLineNumberValue = "";
      this.searchOrderLineBtn(this.ord,"");
    
    }

  openOrderLineData(orderNumber,ittNumber,requestId)
  {
    this.warningFileMsg = "";
    this.warningFileDocMsg = "";
    this.fileMsg = "";
    this.fileMainMsg = "";
    this.checkBoxErrorMsg= "";
    this.orderLineNumberValue = "";
    this.searchInstall = "";
    this.masterOrderLineSelected = false;
    this.orderLineCheckedList =[];
   // alert("openModel");
   if(this.selectedDocType == "POD/Pickup Confirmation")
    {
   orderNumber=this.ord;
   ittNumber =this.id;
    
   this.orderDtlCsaService.getSearchLineAttachments(this.getOrderLineParam(this.ord,"")).subscribe(data => {
    this.selectedOrderLine = data.data;
   
  console.log("Uploaded Docs List:"+this.selectedOrderLine);
    $("#orderLineModal").modal("show");
    console.log('data passed to modal: ',this.selectedOrderLine);
    
  },
  (error) =>
  {
     this.commonErrMsg = "";  
     console.log("Error: "+error);
     this.commonErrMsg =error;
  });
  }
  else
  {
    this.warningFileMsg = "";
    this.warningFileDocMsg = "";
    this.warningFileMsg = "Please select Document Type";
  }
  }
  openFileUpload()
  {
    this.commonErrMsg = "";
    this.commonFileErrMsg = "";
    this.fileMsg = "";
    this.fileMainMsg = "";
   // this.docTypebtn = true;
    //this.selectedDocType ='';
  }
  uploadDocService(param){
    console.log('order id is:'+param);     
         this.commonErrMsg = "";
         this.commonErrMsg = "";
         this.commonFileErrMsg = "";
         this.fileMsg ="";
    this.orderDtlCsaService.getUploadedDocs(param).subscribe(data => {
    this.selectedUploadedOrder = data.data;
   
  console.log("Uploaded Docs List:"+this.selectedUploadedOrder);
   $("#uploadDocModal").modal("show");
   this.docTypebtn = false;
   // console.log('data passed to modal: ',this.selectedUploadedOrder);
  },
  (error) =>
  {
     this.commonErrMsg = "";  
     console.log("Error: "+error);
     this.commonErrMsg =error;
  });
  }
  
  getFileAttParam (orderNumber) {
  return {
    "orderNumber": this.ord,
     }
  }
  uploadDoc(){
    this.fileMsg = "";
    this.fileMainMsg = "";
    this.commonErrMsg = "";
    this.warningFileMsg = "";
    this.warningFileDocMsg = "";
    this.checkBoxErrorMsg= "";
    this.searchInstall = "";
    this.selectedDocType ="";
    this.desc ="";
    this.orderLineNumberValue = "";
    $("#customFile").val(''); 
    
    this.uploadDocService(this.getFileAttParam(this.orderNumber));
  
   }
  
   handleFileInput(files: FileList) {

    this.fileToUpload = files.item(0);
     //this.uploadFileToActivity();
  }
  openDeleteFileModal(fileName,attachId)
  {
  this.commonFileErrMsg = "";
  this.fileMsg = "";
  this.fileMainMsg = "";
  this.checkBoxErrorMsg= "";
  this.searchInstall = "";
  this.selectedDocType ="";
  this.orderLineNumberValue = "";
  $("#delete-file-modal").modal("show");
  this.fileName=fileName;
  this.attachId=attachId;
  }
  deleteDoc(attachId)
  {
  this.commonFileErrMsg = "";
  this.warningFileMsg = "";
  this.warningFileDocMsg = "";
  this.fileMsg = "";
  this.fileMainMsg = "";
  this.orderLineNumberValue = "";
  this.orderDtlCsaService.deleteUploadedDoc(attachId,this.ord,"",this.reqNumber).subscribe(data => {
    this.commonFileErrMsg = "";
    if(data.status == 'Successfully Deleted the attachment')
    {
      $("#delete-file-modal").modal("hide");
      this.fileMsg = data.status;
      this.fileMainMsg = data.status;
      this.orderDtlCsaService.getUploadedDocs(this.getFileAttParam(this.ord)).subscribe(data => {        
        this.selectedUploadedOrder = data.data;
        this.fileMsg = "";
        this.fileMainMsg = "";
        this.docTypebtn = false;
         console.log("Uploaded Docs List:"+this.selectedUploadedOrder);
      },
      (error) =>
      {
         this.commonFileErrMsg = "";
         this.warningFileMsg = "";
         this.warningFileDocMsg = "";
         this.fileMsg = "";
         this.fileMainMsg = "";
         console.log("Error: "+error);
         this.commonFileErrMsg =error;
      });
      this.orderDtlCsaService.getCsaDtlList('ATTACHMENTS',this.ord).subscribe(data => {
        this.csaOrderAttachmentsList = data.data;
        console.log('Attachments order dealer list',this.csaOrderAttachmentsList);
        //console.log(JSON.stringify(this.csaOrderList));
      this.totalAttachmentList=this.csaOrderAttachmentsList.length;     
       this.tableLoader = false; 
      },
      (error) =>
      {
         this.commonErrMsg = "";
         this.tableLoader = false; 
         console.log("Error: "+error);
         this.commonErrMsg =error;
      });
      
    }
    else
    {
      this.commonFileErrMsg = "";
      this.fileMsg = "";
      this.fileMainMsg = "";
      this.commonFileErrMsg = "Some Error Occured while deleteing the file";
     
    }
  },
  error =>
  {
    this.commonFileErrMsg = "";
    this.fileMsg = "";
    this.fileMainMsg = "";
    console.log("Error: "+error);
    this.commonFileErrMsg =error;
  });
  }

  validateFileType(fileToUpload)
  {
   if(fileToUpload.type == 'application/pdf' || fileToUpload.type == 'application/msword' ||fileToUpload.type == 'application/vnd.ms-excel'|| fileToUpload.type == 'text/plain' || fileToUpload.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || fileToUpload.type ==  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
  this.valFiletype = true;
  else
  this.valFiletype =  false;
  
  return this.valFiletype;
  }
  validateFileSize(fileToUpload)
{
  const fsize = fileToUpload.size;
  const file = Math.round((fsize / 1024)); 
   if (file >= 2048)                 
    this.valFileSize = false;
  else
    this.valFileSize =  true;

return this.valFileSize;
}

  clearFileDtls(s: string) {
  this.desc = '';
  this.fileName = null;
  
  if (s == "I") {
    this.warningFileMsg = '';
    this.fileMsg = '';
  }
  }
  orderLineCheckUncheckAll() { 
    this.orderLineNumberValue = "";
    for (var i = 0; i < this.selectedOrderLine.length; i++) {
   
         this.selectedOrderLine[i].isCheck = this.masterOrderLineSelected;
    }
    this.getCheckedOrderLineList();
  }
  getOrderLineSaveParam (param) { 
    return {
      "lineNumber":param.lineNumber,
      "serialNumber":param.serialNumber,
      "itemDesc":param.itemDesc,
      "item":param.item,
      "installReturn":param.installReturn,
  }
  
    }
    parentCheckOrderLine(parentObj) {
      this.orderLineNumberValue = "";
      if( parentObj.isCheck == true)
      {
        this.parentOrderLineId=parentObj.lineNumber;
    
      for (var i = 0; i < this.selectedOrderLine.length; i++) {
        if(this.selectedOrderLine[i].lineNumber == this.parentOrderLineId )
        {
          this.selectedOrderLine[i].isCheck =true;
        }
      }
    }
    this.getCheckedOrderLineList();
  }
    
    getCheckedOrderLineList() {
      this.orderLineCheckedList = [];
      this.orderLineNumberValue = "";
    this.conCatLineNumber= "";
        for (var i = 0; i < this.selectedOrderLine.length; i++) {
          if(this.selectedOrderLine[i].isCheck)
          {
  
              this.orderLineCheckedList.push(this.getOrderLineSaveParam(this.selectedOrderLine[i]));                  
          }
  
        }
        console.log("orderLineCheckedList:"+this.orderLineCheckedList);
      //  throw new Error('Method not implemented.');
      if(this.searchInstall == "ALL"  && this.masterOrderLineSelected == true && this.orderLineCheckedList.length >0)
      {
        this.orderLineNumberValue = "ALL";
      }
      else if(this.searchInstall == "INSTALL"  && this.masterOrderLineSelected == true && this.orderLineCheckedList.length >0)
      {
        this.orderLineNumberValue = "Installation Only - All";
      }
      else if(this.searchInstall == "RETURN"  && this.masterOrderLineSelected == true && this.orderLineCheckedList.length >0)
      {
        this.orderLineNumberValue = "Return Only - All";
      }
      else
      {
        for (var i = 0; i < this.orderLineCheckedList.length; i++) {
          if(this.orderLineCheckedList.length>0)
          {
            if(i== 0)
            this.conCatLineNumber=this.orderLineCheckedList[i].lineNumber;
            else
            this.conCatLineNumber+=","+this.orderLineCheckedList[i].lineNumber;
          }
        else
        this.conCatLineNumber=this.orderLineCheckedList[i].lineNumber;
        }
        this.orderLineNumberValue =this.conCatLineNumber;
      }
    
        return this.orderLineCheckedList;
    }
    finalOrderLineCheckedList()
    {
     if(this.orderLineCheckedList == undefined)
       {
         this.checkBoxErrorMsg = "Please select atleast one line";
       }
       else
       $("#orderLineModal").modal("hide");
    }
    opendocType()
    {
      this.orderLineNumberValue = "";
      this.searchInstall = "";
      if(this.selectedDocType == "Misc")
      this.docTypebtn = true;
      else if(this.selectedDocType == "POD/Pickup Confirmation")
      this.docTypebtn = false;
      else
      this.docTypebtn = true;
    }
  uploadFileToActivity() {
  this.fileMsg = "";
  this.fileMainMsg = "";
  this.commonErrMsg = "";
  this.warningFileMsg = "";
  this.warningFileDocMsg = "";
  this.commonFileErrMsg = "";
  if(this.desc == undefined)
    this.desc = "";
    
  if(this.fileToUpload != null)
  {
    this.valFiletype=this.validateFileType(this.fileToUpload);
    this.valFileSize=this.validateFileSize(this.fileToUpload);
     if(this.valFiletype)
    {
      if(this.valFileSize)
    {
       if(this.selectedDocType == "Misc" || this.selectedDocType == "POD/Pickup Confirmation")
       {
        if(this.selectedDocType != "Misc")
        {
          if(this.orderLineCheckedList !== undefined && this.orderLineCheckedList.length !== 0)
          {
      for (var i = 0; i < this.orderLineCheckedList.length; i++) {
        if(this.orderLineCheckedList.length>1)
        {
          if(i== 0)
          this.conCatLineNumber=this.orderLineCheckedList[i].lineNumber;
          else
          this.conCatLineNumber+=","+this.orderLineCheckedList[i].lineNumber;
        }
      else
      this.conCatLineNumber=this.orderLineCheckedList[i].lineNumber;
      }
    }
    else
    this.warningFileMsg = "To Upload POD/Pickup Confirmation Document Type please select line Numbers.";
      }
    else
    this.conCatLineNumber = '';
    if(this.warningFileMsg  == "")
    {
  if(this.isAdmin == 'Y')
    this.source = 'CSA';
    else
    this.source = 'Dealer';
  this.orderDtlCsaService.insertUploadedDocs(this.fileToUpload,this.conCatLineNumber,this.ord,this.headerId,this.id,this.desc,this.selectedDocType,this.source,this.ordLineReqId,this.orderLineNumberValue).subscribe(data => {
    // do something, if upload success
    //this.fileMsg = data.data;
   // alert("Insert");
   this.commonFileErrMsg = "";
   this.warningFileMsg = "";
   this.warningFileDocMsg = "";
   this.fileMsg = "";
   this.fileMainMsg = "";
    if(data.data == "Success")
    {
      this.fileMsg = "Successfully Uploaded";
      this.warningFileMsg = "";
      this.warningFileDocMsg = "";
      this.desc ="";
      $("#noRecUploadMsg").empty();
      $("#customFile").val(''); 
      this.clearFileDtls('U');
      this.fileToUpload = null;
      this.orderDtlCsaService.getUploadedDocs(this.getFileAttParam(this.ord)).subscribe(data => {
        this.selectedUploadedOrder = data.data;
        this.docTypebtn = false;
        this.selectedDocType ='';
        this.orderLineNumberValue = '';
        this.orderLineCheckedList =[];
         console.log("Uploaded Docs List:"+this.selectedUploadedOrder);
      },
      (error) =>
      {
         this.commonFileErrMsg = "";
         this.fileMsg = "";
         this.fileMainMsg = "";
         this.warningFileMsg = "";
         this.warningFileDocMsg = "";
         console.log("Error: "+error);
         this.commonFileErrMsg =error;
      });
    }
    },
    (error) =>
    {
       this.commonFileErrMsg = "";
       this.fileMsg = "";
       this.fileMainMsg = "";
       this.warningFileMsg = "";
       this.warningFileDocMsg = "";
       console.log("Error: "+error);
       this.commonFileErrMsg =error;
    });
  }
}
    else
    {
      this.warningFileMsg = "";
      this.warningFileDocMsg = "";
      this.warningFileMsg="Please select Document Type and upload file";     
    }
  }
    else
  {
    this.warningFileMsg = "";
    this.warningFileMsg="Please select Files size less than or equal to 2MB";
    this.fileToUpload = null;
  }
    
  }
  
    else
    {
      this.warningFileMsg = "";
      this.warningFileDocMsg = "";
      //this.warningFileMsg="Please Upload Files with  file types .pdf,.xls,.txt,.doc,.docx,.xlsx";
      this.warningFileDocMsg = "Please Upload Files with  file types ";
      this.fileToUpload = null;
    }
  }
  else
  {
    this.warningFileMsg = "";
    this.warningFileDocMsg = "";
    this.warningFileMsg="Please Upload the file and click Upload";
    this.fileToUpload = null;
  }
  }
  
}
