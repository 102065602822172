import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Util } from 'src/app/app.util';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
      
  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})
export class CompensationDetailsService {

  defaultUrl : String;
  constructor(private http: HttpClient,private util : Util) {
    this.defaultUrl = util.getBaseUrl();
   }

   //dealer
  getCompensationDetailsDealer(inputParams):Observable<any>{
    inputParams = JSON.stringify(inputParams);
    let initialUrl = this.defaultUrl + "finance/gcdd";
    console.log("inputParams ",inputParams);
    return this.http.post(initialUrl,inputParams,httpOptions)
            .pipe(catchError(this.handleError("getCompensationDetailsDealer")));
  }

  //csa
  getCompensationDetailsCsa(inputParams):Observable<any>{
    inputParams = JSON.stringify(inputParams);
    let initialUrl = this.defaultUrl + "finance/gcdc";
    console.log("inputParams ",inputParams);
    return this.http.post(initialUrl,inputParams,httpOptions)
            .pipe(catchError(this.handleError("getCompensationDetailsCsa")));
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {

      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  csaCompensationDetailsLabels =["invoiceDate","dealerName","dealerSiteCode","serialNumber","contractOrOrderNumber","custPoNumber","model","compensationType",
                                "amount","paymentNumber","paymentDate","invoiceNumber","billingPeriodFrom","billingPeriodTo","copyIndicator","prevCount","currentCount","allowance",
                                "allocatedCopies","costPerCopy","contractTerm","installDate","acct","customerName","machineLocationName","machineLocationAdd1",
                                "machineLocationAdd2","city","state","zip"];
      
  dealerCompensationDetailsLabels =["invoiceDate","dealerName","dealerSiteCode","serialNumber","contractOrOrderNumber","custPoNumber","model","compCategory","compensationType",
                                    "amount","paymentNumber","paymentDate","invoiceNumber","billingPeriodFrom","billingPeriodTo","copyIndicator","prevCount","currentCount","allowance",
                                    "allocatedCopies","costPerCopy","contractTerm","installDate","acct","customerName","machineLocationName","machineLocationAdd1",
                                    "machineLocationAdd2","city","state","zip"];

  camel2title(camelCase) {
    return camelCase
     // inject space before the upper case letters
     .replace(/([A-Z])/g, function(match) {
        return " " + match;
     })
     // replace first char with upper case
     .replace(/^./, function(match) {
       return match.toUpperCase();
     });
 }

jsonToExcel(JSONData, outputFileName, ShowLabel) {
              
  //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
  var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
  var CSV = '';    
  //Set Report title in first row or line
  //CSV += ReportTitle + '\r\n\n';
  
   
  var lbls;
  var custArr;
  var bCustom=false;
  if(outputFileName=="CSA Compensation Details"){  
      lbls= this.csaCompensationDetailsLabels;
      ;
      bCustom=true;  
  }else if (outputFileName=="Dealer Compensation Details"){  
      lbls=this.dealerCompensationDetailsLabels;
      bCustom=true;
  }else{
      lbls=arrData[0];
  }
  
  //This condition will generate the Label/Header
  if (ShowLabel) {
      var row = "";
      //This loop will extract the label from 1st index of on array
      if(bCustom){
          for (var index in lbls) {
              //Now convert each value to string and comma-seprated
              var lbl=lbls[index];
              lbl=this.camel2title(lbl);  
              row += lbl + ',';
          }
      }else{ 
          for (var index in arrData[0]) {
              //Now convert each value to string and comma-seprated
              index=this.camel2title(index);
              row += index + ',';
          }
      }
      row = row.slice(0, -1);
      //append Label row with line break
      if(row.indexOf("Dealer Dba")>-1){
        row=row.replace("Dealer Dba","Dealer DBA");
      }
      if(row.indexOf("Rma")>-1){
         row=row.replace("Rma","Contains Return");
       }
       if(row.indexOf("Cust Po Number")>-1){
        row=row.replace("Cust Po Number","Dealer PO Number");
      }
      CSV += row + '\r\n';
  }
  
  if(bCustom){
      
          //1st loop is to extract each row
          for (var i = 0; i < arrData.length; i++) {
              var row = "";
          //2nd loop will extract each column and convert it in string comma-seprated
               var oData=arrData[i];
              
              for(const k in lbls){  
                  
                  //Skip "" when '=' is appended for preserving leading zeros
                  var arrDataVal = oData[lbls[k]];
                  //console.log(arrDataVal);
                  //check for null and assign "" string
                  if(null == arrDataVal)
                      arrDataVal = "";
                  if(!isNaN(arrDataVal))
                      arrDataVal = arrDataVal.toString();
                  if(arrDataVal.startsWith('=')){
                      row += arrDataVal + ',';
                      continue;
                  }
                   
                  row += '"' + arrDataVal + '",';
               
          }
              row.slice(0, row.length - 1);
              //add a line break after each row
              CSV += row + '\r\n';
      }
      
  }else{
  //1st loop is to extract each row
  for (var i = 0; i < arrData.length; i++) {
      var row = "";
      //2nd loop will extract each column and convert it in string comma-seprated
      for (var index in arrData[i]) {
          
          //Skip "" when '=' is appended for preserving leading zeros
          var arrDataVal = (arrData[i][index]);
          //check for null and assign "" string
          if(null == arrDataVal)
              arrDataVal = "";
          if(!isNaN(arrDataVal))
              arrDataVal = arrDataVal.toString();
          if(arrDataVal.startsWith('=')){
              row += arrDataVal + ',';
              continue;
          }
          row += '"' + arrDataVal + '",';
      }
      row.slice(0, row.length - 1);
      //add a line break after each row
      CSV += row + '\r\n';
  } 
  
  }
  if (CSV == '') {        
      alert("Invalid data");
      return;
  }
  
 //this trick will generate a temp "a" tag
  var link = document.createElement("a");    
  link.id="lnkDwnldLnk";

  //this part will append the anchor tag and remove it after automatic click
  document.body.appendChild(link);

  var csv = CSV;  
  var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;'}); 
  
  //Generate a file name
  var fileName =outputFileName;   
  //this will remove the blank-spaces from the title and replace it with an underscore
 // fileName += outputFileName.replace(/ /g," ");

  if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, fileName + ".csv")
          } else {
              var link = document.createElement("a");
              if (link.download !== undefined) { // feature detection
                  // Browsers that support HTML5 download attribute
                  var csvUrl = window.URL.createObjectURL(blob);
                  link.setAttribute("href", csvUrl);
                  link.setAttribute("download", fileName + ".csv");
                  link.style.display = "visibility:hidden";
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
              }           
          }
  }




}





