
export class NewOrder {
    requestId: string;
    copyFromRequestId: string;
    recoverRequestId: string;
    versionNumber:any;
    action:string;
    requestNumber:string;
    orderHeader: OrderHeader;
    linesList: OrderLine[];
    maintList: Maintenance[];
    pickupReturnList: PickupReturn[];

    constructor() {
        this.requestId = "";
        this.copyFromRequestId="";
        this.recoverRequestId="";
        this.versionNumber="";
        this.action="";
        this.requestNumber="";
        this.orderHeader = new OrderHeader();
        this.linesList = [];
        this.maintList = [];
        this.pickupReturnList = [];
    }


}


export class OrderHeader {


    dealerPO: string;
    totalAmount: any;
    orderDate: string;
    inventorySourceId: number;
    inventorySource: string;
    reqInstlDate: string;
    customerName: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    primaryCustContactName: string;
    primaryCustContactEmail: string;
    primaryCustContactPhone: string;
    secondaryCustContactName: string;
    secondaryCustContactEmail: string;
    secondaryCustContactPhone: string;
    primaryDealerContactName: string;
    primaryDealerContactEmail: string;
    primaryDealerContactPhone: string;
    dealerPsn:string;
    dealerAddress:string;
    dealerCity:string;
    dealerState:string; 
    dealerZip:string;

    dealerMeterContactName:string; 
    dealerMeterContactEmail:string;
    dealerMeterContactPhone:string; 
    additionalInstruction: string;
    shipToCustomerNumber: number;

}



export class OrderLine {


    


    chk: any;
    sortOrder: number;
    itemId: number;
    itemCode: string;
    quantity: number;
    itemDescription: string;
    unitPrice: any;
    totalPrice: any;
    findersFee: any;
    installationFee: any;
    machineFlag: string = 'N';
    serviceModel: string;
    merchandiseType:string;
    
    clrFlag: string;
    constructor() {


            }


}


export class Maintenance {

    chk: any;
    configSortOrder:number;
    declineMaintenance: string;
    quantity: number;
    serviceModel: string;
    pricePlan: string = 'Fixed';
    contractType: string;
    term: number;
    basePrice: any;
    bwAllowance: any;
    bwCpc: any;
    clrAllowance: any;
    clrCpc: any;
    baseBillingCycle: string;
    usageBillingCycle: string;
    clrFlag: string;
    constructor() {
    }
}


export class PickupReturn {
    chk: any;
    serialNumber: string;
    itemId: number;
    itemCode: string;
    quantity: number;
    serviceModel: string;

    itemDescription: string;
    returnAddress: string;
    returnCity: string;
    returnState: string;

    returnZipcode: string;
    hardDriveRemoval: string;
    disposalOrPickup: string;
    removalComp: any;
    machineFlag: string = 'N';
    merchandiseType:string;
    serializedItem:string;

    constructor() {


 
    }


}



