import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Util } from 'src/app/app.util';
import { catchError } from 'rxjs/operators';


const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};


@Injectable({
  providedIn: 'root'
})
export class MeterEntryService {
  defaultUrl: string;
  constructor(private http: HttpClient, private util: Util) {
    this.defaultUrl = util.getBaseUrl();

   }

  getSerialList(srlParam): Observable<any> {
    let FN = "[getSerialList]";
    let urlSrl = this.defaultUrl + "meterentry/gsl";
    let params = new URLSearchParams();
    for (let key in srlParam) {
      params.set(key, srlParam[key])
    }
    
    return this.http
      .post(urlSrl, params.toString(), httpOptions)
      .pipe(catchError(this.handleError(FN)));
  }

  getCustomerList(custParam): Observable<any> {
    let FN = "[getCustomerList]";
    let urlCust = this.defaultUrl + "meterentry/gcl";
    let params = new URLSearchParams();
    for (let key in custParam) {
      params.set(key, custParam[key])
    }
    
    return this.http
      .post(urlCust, params.toString(), httpOptions)
      .pipe(catchError(this.handleError(FN)));
  }


  getDealerName(dnParams): Observable<any> {
    let FN = "[getDealerName]";
    let urlDN = this.defaultUrl + "meterentry/gdn";
    let params = new URLSearchParams();
    for (let key in dnParams) {
      params.set(key, dnParams[key])
    }
   
    return this.http
      .post(urlDN, params.toString(), httpOptions)
      .pipe(catchError(this.handleError(FN)));
  }

  uploadReadsTemplate(fileToUpload: File, source: string): Observable<any> {
    let upldUrl = this.defaultUrl + "meterentry/vmru?source=" + source;
    let FN = "uploadReadsTemplate"
    const formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    return this.http
      .post(upldUrl, formData)
      .pipe(catchError(this.handleError(FN)))
  }  
 

  submitMeterReads(readsData): Observable<any> {

    let FN = "[submitOrderRequest]";
    let urlSor = this.defaultUrl + "meterentry/smr";
    console.log('Meter Reads : ', readsData);
    return this.http
      .post(urlSor, readsData, httpJsonOptions)
      .pipe(catchError(this.handleError(FN)));

  }



  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {

      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }


}
