import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';
import { Util } from 'src/app/app.util';
import { componentFactoryName } from '@angular/compiler';
import { SetScreenService } from 'src/app/service/common-service/set-screen.service';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit {

     userRoles = {};
    
  
    constructor(private route:ActivatedRoute,private loginAuthenticationService:LoginAuthenticationService,private util : Util,private _setCsaValueService:SetScreenService, private titleService: Title) { }  
        
    setScreen(num)
         {
           
           this._setCsaValueService.changeMyVar(num);
          
        }
    

   getLoginUserRoles() {

      this.loginAuthenticationService.getUserRoles().subscribe(data => {
      this.userRoles = data;
       console.log('user Roles : ',this.userRoles);
    
    });  

  }

private subscription: Subscription;
myVar;
    

  ngOnInit() {
    


    this.subscription = this._setCsaValueService.changeVar.subscribe(message => {

      //if (message !== this.myVar) {
        //alert("sidenav message"+message);
          this.myVar = Number(message);
          //alert("this.myVar"+this.myVar);
            
          if(this.myVar == 0){
            this._setCsaValueService.setCSACSS();

  
            }else if(this.myVar == 1){
               this._setCsaValueService.setDealersCSS();           

            }           
     // }
  });

    


    
      
   this.getLoginUserRoles();     
// jquery code for menu
      
  $(document).ready(function () {
    //var setCSA = 0;
    var sideMoreOpenFlag = 0;

    // side menu functions
    function sideMenuClose() {
        $('.side-link-text').css("display", "none");
        $('#main-side-nav .inner ul li.side-menu-li img,.single-link-side-menu-li img').css("width", "40px");
        $('#main-side-nav .inner ul li.side-menu-li,.single-link-side-menu-li').css({ "border-radius": "50px", "padding": "1px", "height": "45px", "margin": "10px 0" });
        $('.side-menu-button-wrapper i').css({ "float": "left", "margin-top": "10px", "margin-left": "12px", "margin-bottom": "-5px" });
        $('.side-more-icons-wrapper').css("width", "100%");
        $('#top-nav-right-arrow').css("display", "block");
        $('#top-nav-left-arrow ').css("display", "none");
        $('#main-side-nav').css({ "max-width": "75px", "min-width": "75px", "overflow": "visible" });
        $('.side-menu-wrapper-inner ul').css({ "padding": "0 5px" });

    }
    function sideMenuOpen() {
        $('.side-link-text').css("display", "inline");
        $('#main-side-nav .inner ul li.side-menu-li,.single-link-side-menu-li').css({ "border-radius": "50px", "padding": "2px" });
        $('.side-menu-button-wrapper i').css({ "float": "right", "margin-top": "10px", "margin-left": "0", "margin-bottom": "0" });
        $('.side-more-icons-wrapper').css("width", "20%");
        $('#top-nav-right-arrow').css("display", "none");
        $('#top-nav-left-arrow ').css("display", "block");
        $('#main-side-nav').css({ "max-width": "275px", "min-width": "275px" });
        $('.side-menu-wrapper-inner ul').css({ "padding": "0" });
    }
    function sideMoreOpen(w) {




        var h = $(window).height();
        var topHeader = $("#side-nav-logo-button-wrapper").height();
        var subHeader = $(".group-switcher-menu-wrapper").height();
        $('.side-menu-wrapper-inner').css("height", h - topHeader - subHeader - 70);


        $('.side-link-text').css("display", "inline");
        $('#top-nav-right-arrow').css("display", "none");
        $("#main-side-nav .inner ul li.side-menu-li, .single-link-side-menu-li").css("padding", "6px")
        $('.side-menu-button-wrapper i').css("float", "right");
        $('#main-side-nav .inner ul li.side-menu-li img,.single-link-side-menu-li img').css("width", "55px");
        $('#main-side-nav').css({ "max-width": "100%", "width": "100%" });
        $('#side-nav-logo-wrapper img').css({ "width": "10%" });


        if (w > 991) {
            $('#main-side-nav .inner ul li.side-menu-li,.single-link-side-menu-li').css({ "width": "23%" });
        } else if (w > 768) {
            $('#main-side-nav .inner ul li.side-menu-li,.single-link-side-menu-li').css({ "width": "31%" });
        } else if (w > 500) {
            $('#main-side-nav .inner ul li.side-menu-li,.single-link-side-menu-li').css({ "width": "48%" });
        } else { $('#main-side-nav .inner ul li.side-menu-li,.single-link-side-menu-li').css({ "width": "98%" }); }
        $('#main-side-nav .inner ul li.side-menu-li,.single-link-side-menu-li').hide().css({ "float": "left", "margin": "10px 1%" }).delay(800).show();
        $('#main-side-nav .inner ul li.side-menu-li,.single-link-side-menu-li').css({ "border-radius": "50px" });
        $('.side-menu-hidden-items').css({ "display": "block" });
        $('.side-less-top-icons-wrapper').css({ "display": "block" });
        $('.side-less-icons-wrapper').css({ "display": "block" });
        $('.side-more-icons-wrapper').css({ "display": "none" });
        $('#top-nav-left-arrow').css({ "display": "none" });
        $("#main-side-nav#main-side-nav .inner ul li.side-menu-li,.single-link-side-menu-li").css('height', '70px');
        sideMoreOpenFlag = 1;
    }
    function sideMoreClose() {

        $('#main-side-nav').css({ "max-width": "275px", "min-width": "275px", "overflow": "hidden" });
        $('#side-nav-logo-wrapper img').delay(3000).css({ "width": "75%" });
        $('#main-side-nav .inner ul li.side-menu-li,.single-link-side-menu-li').css({ "float": "none", "width": "100%", "height": "auto", "margin": "10px 0" });
        $('.side-menu-hidden-items').css({ "display": "none" });
        $('.side-less-top-icons-wrapper').css({ "display": "none" });
        $('.side-less-icons-wrapper').css({ "display": "none" });
        $('.side-more-icons-wrapper').css({ "display": "block" });
        $('#top-nav-left-arrow').css({ "display": "block" });
        sideMenuClose();
        toolTipOn();
        sideMoreOpenFlag = 0;

    }
    function sizeMain() {
        var w = $(window).width();
        var h = $(window).height();
        var topHeader = $("#side-nav-logo-button-wrapper").height();
        var subHeader = $(".group-switcher-menu-wrapper").height();
        var mobileTopHeader = $("#mobile-menu-modal-header").height();
        var sidebarSize = $("#main-side-nav").width();
        var mainContentSize = w - sidebarSize;
        $('#main-flex-content-wrapper').css("width", mainContentSize);
        $('.side-menu-wrapper-inner').css("height", h - topHeader - subHeader - 28);
        $('#mobile-menu-modal-body').css("height", h - mobileTopHeader - 16);
    }

    function toolTipOff() {
        $('[data-toggle="tooltip"]').tooltip("destroy");
    }
    function toolTipOn() {
        $('[data-toggle="tooltip"]').tooltip(); // re-enabling
    }

//    function setDealersCSS() {       
//        if($('.dealer-tab').length > 0){
//        //$('.dealer-tab').trigger('click');
//        }
//        $(".csa-page-header-title").hide();
//        $("#side-nav-logo-button-wrapper,#mobile-menu-modal-header").css("background", " url('assets/img/dealers-bg.jpg')");
//        $(".site-logo").attr("src", "assets/img/ep-dealers-logo.png");
//        $("#main-top-nav-wrapper-clone").css("background", "#7395AE");
//        $("#main-side-nav,#mobile-menu-modal-body").css("background", "#5d5c61");
//        $(".main-top-navbar").css("border-top", "1px solid #fff");
//        $(".fa-ellipsis-v").css("color", "#396f96");
//        $("#main-side-nav .inner ul li.side-menu-li,#mobile-menu-links ul li.mobile-menu-li,.single-link-side-menu-li,.single-link-mobile-menu-li").css({ "border": "1px solid #1b6d85", "background-color": "#7395ae", "color": "#fff" });
//
//        $(".side-less-icons-wrapper").hover(function () {
//            $(".side-less-icons-wrapper .side-more-icons").css("color", "rgb(38, 154, 188)");
//        }, function () {
//            $(".side-less-icons-wrapper .side-more-icons").css("color", "#fff");
//        });
//        $(".side-more-icons-wrapper").hover(function () {
//            $(".side-more-icons-wrapper .side-more-icons").css("color", "rgb(38, 154, 188)");
//        }, function () {
//            $(".side-more-icons-wrapper .side-more-icons").css("color", "#fff");
//        });
//        $(".side-menu-button-wrapper i").hover(function () {
//            $(this).css({ "color": "rgb(38, 154, 188)", "border": "3px solid rgb(38, 154, 188)" });
//        }, function () {
//            $(this).css({ "color": "#fff", "border": "3px solid #fff" });
//        });
//        $(".tooltip .tooltip-inner").css("border-left", "7px solid rgb(38, 154, 188)");
//        $(".group-switcher").css({ "border": "1px solid #2aabd1", "background-color": "#2286a3" });
//        $(".side-menu-wrapper-inner").css("border-top", "1px solid #777");
//        $(".main-csa-content-wrapper,.csa-page-header-title").show();
//        $(".main-approvalcsa-content-wrapper,.csa-page-header-title").hide();
//        $(".main-dealers-content-wrapper,.dealer-page-header-title").fadeIn();
//        $(".btn-primary").css({ "border-color": "#2aabd1", "background-color": "#2286a3", "color": "#fff" });
//        $(".result-table tr td i").css({ "color": "#2aabd1", "border": "solid 1px #2aabd1" });
//        $("html,body").css({ "background-color": "#ddd" });
//        $(".main-top-navbar-left-content,.main-top-navbar-links,.group-switcher-inner").css({ "color": "#fff" });
//        $(".result-table tr th").css({ "background-color": "#7395ae" });
//
//        $('.sm-switcher .group-switcher-inner').css("margin-left", "-55px");
//        $('.md-switcher .group-switcher-inner').css("margin-left", "-60px");
//
//        setCSA = 1;
//
//        setTimeout(function(){ 
//            //alert("Hello"); 
//        sessionStorage.setItem("setScreen", "1");
//            $(".csa-page-header-title").hide();
//        }, 100);
//
//    }
//    function setCSACSS() {       
//        if($('.CSA-tab').length > 0){
//        //$('.CSA-tab').trigger('click');
//        }
//        $("#side-nav-logo-button-wrapper,#mobile-menu-modal-header").css("background", " url('assets/img/csa-bg.jpg')");
//        $(".site-logo").attr("src", "assets/img/ep-csa-logo.png");
//        $("#main-top-nav-wrapper-clone").css("background", "#555");
//        $("#main-side-nav,#mobile-menu-modal-body").css("background", "#333");
//        $(".main-top-navbar").css("border-top", "1px solid rgb(186, 179, 171)");
//        $(".fa-ellipsis-v").css("color", "rgb(204, 204, 204)");
//        $("#main-side-nav .inner ul li.side-menu-li,#mobile-menu-links ul li.mobile-menu-li,.single-link-side-menu-li,.single-link-mobile-menu-li").css({ "border": "1px solid #333", "background-color": "#dbd5ce", "color": "#59534d" });
//
//        $(".side-less-icons-wrapper").hover(function () {
//            $(".side-less-icons-wrapper .side-more-icons").css("color", "#dbd5ce");
//        }, function () {
//            $(".side-less-icons-wrapper .side-more-icons").css("color", "#fff");
//        });
//        $(".side-more-icons-wrapper").hover(function () {
//            $(".side-more-icons-wrapper .side-more-icons").css("color", "#dbd5ce");
//        }, function () {
//            $(".side-more-icons-wrapper .side-more-icons").css("color", "#fff");
//        });
//
//        $(".side-menu-button-wrapper i").hover(function () {
//            $(this).css({ "color": "#dbd5ce", "border": "3px solid #dbd5ce" });
//        }, function () {
//            $(this).css({ "color": "#fff", "border": "3px solid #fff" });
//        });
//        $(".tooltip .tooltip-inner").css("border-left", "7px solid #dbd5ce");
//        $(".group-switcher").css({ "border": "1px solid #969089", "background-color": "#999" });
//        $(".side-menu-wrapper-inner").css("border-top", "1px solid #ccc");
//        $(".main-dealers-content-wrapper,.dealer-page-header-title").hide();
//        $(".main-approvalcsa-content-wrapper,.csa-page-header-title").fadeIn();
//        $(".main-csa-content-wrapper,.csa-page-header-title").fadeIn();
//        $(".btn-primary").css({ "border-color": "#969089", "background-color": "#999", "color": "#fff" });
//        $(".result-table tr td i").css({ "color": "#333", "border": "solid 1px #dbd5ce" });
//        $("html,body").css({ "background-color": "#ebe7e4" });
//        $(".main-top-navbar-left-content,.main-top-navbar-links").css({ "color": "#fff" });
//        $(".group-switcher-inner").css({ "color": "#fff" });
//        $(".result-table tr th").css({ "background-color": "#333" });
//        $('.sm-switcher .group-switcher-inner').css("margin-left", "5px");
//        $('.md-switcher .group-switcher-inner').css("margin-left", "0");
//
//        setCSA = 0;
//        sessionStorage.setItem("setScreen", "0");
//    }
//











    $('#chooseColumnModalList-selectAll').click(function (event) {


        if (this.checked) {
            // Iterate each checkbox
            $(':checkbox').each(function () {
                this.checked = true;
                $(this).parent().parent().parent("li").css("background-image", "linear-gradient(lightgreen, green)"); $(".table-columns").show();
            });
        } else {
            $(':checkbox').each(function () {
                this.checked = false;
                $(this).parent().parent().parent("li").css({ "background-image": "linear-gradient(#fff, #ccc)", "color": "#333" }); $(".table-columns").hide();
            });
        }
    });


    $('#chooseColumnModalList li').click(function (event) {

        var clicked = $(this).find("input");
        var colID = $(clicked).attr('id');
        var colSplit = colID.split('-');
        var colNumber = colSplit[1];




        if ($(clicked).is(":checked")) {

            $(clicked).parent().parent().parent("li").css({ "background-image": "linear-gradient(lightgreen, green)", "color": "#fff" });
            $(".table-column-" + colNumber).show();



        } else {

            $(clicked).parent().parent().parent("li").css({ "background-image": "linear-gradient(#fff, #ccc)", "color": "#333" });
            $(".table-column-" + colNumber).hide();
        }
    });



    $('[data-toggle="tooltip"]').tooltip();









    var w = $(window).width();
    var h = $(window).height();

    if (w > 1650) {
        sideMenuOpen();
        setTimeout(function () { sizeMain(); }, 300);
        setTimeout(function () { sizeMain(); }, 600);
    } else {
        sideMenuClose();
        setTimeout(function () { sizeMain(); }, 300);
        setTimeout(function () { sizeMain(); }, 600);
    }



    sideMoreClose();

    $('#search-result-table-area-overlay').delay(2000).fadeOut();
    $('.wrapper,.inner,#side-nav-logo-button-wrapper').delay(300).fadeIn();
    $('.inner,#side-nav-logo-button-wrapper').delay(300).css("display", "block");
    $('#main-top-nav-wrapper-clone').css("margin-top", "0");

    $(window).resize(function () {
        if ($('.side-less-icons-wrapper').css("display") == "block") { sideMoreClose(); }
        if ($('#top-nav-left-arrow').css("display") == "block") { $('#top-nav-left-arrow').click(); }
        if ($('#mobile-menu-modal').css("display") == "block") { $('#mobile-menu-close-button').click(); }

        sizeMain();

        setTimeout(function () { sizeMain(); }, 300);
        setTimeout(function () { sizeMain(); }, 1000);
        setTimeout(function () { sizeMain(); }, 2000);



    });

    var mMenu = 0;

    $('#m-menu').click(function () {
        if (mMenu == 0) {
            $('.main-page-navbar').css("height", "auto");
            mMenu = 1;

        } else { $('.main-page-navbar').css("height", "0"); mMenu = 0; }
    });


    $('#top-nav-left-arrow').click(function () {
        sideMenuClose();
        setTimeout(function () { sizeMain(); }, 300);
        setTimeout(function () { sizeMain(); }, 600);
    });
    $('#top-nav-right-arrow').click(function () {
        sideMenuOpen();
        setTimeout(function () { sizeMain(); }, 300);
        setTimeout(function () { sizeMain(); }, 600);
    });

    $('.side-more-icons-wrapper').click(function () {
        var w = $(window).width();
        sideMoreOpen(w);

    });
    $('.side-less-icons-wrapper,#top-nav-less-right-arrow,.dashboardIcon,.orderIcon,.serviceIcon,.fleetIcon,.financeIcon').click(function () {
        sideMoreClose();
        setTimeout(function () { sizeMain(); }, 400);
        setTimeout(function () { sizeMain(); }, 600);
    });




//    $('.group-switcher').click(function () {
//        //alert('test');
//        var setScreen = Number(sessionStorage.getItem("setScreen"));
//        if (setScreen == 1) {
//            setTimeout(function(){ 
//                //alert("Hello"); 
//                setDealersCSS();
//            }, 100);
//            
//
//        } else {
//            setTimeout(function(){ 
//                //alert("Hello"); 
//                setCSACSS();
//            }, 100);
//            
//        }
//    });



//  $('.group-switcher').click(function () {
//        //alert('test');
//        //setCSA = Number(localStorage.getItem("setScreen"));
//        if (setCSA == 1) {
//            setTimeout(function(){ 
//                //alert("Hello"); 
//                setDealersCSS();
//            }, 100);
//            
//
//        } else {
//            setTimeout(function(){ 
//                //alert("Hello"); 
//                setCSACSS();
//            }, 100);
//            
//        }
//    });


    $("#page-loader-overlay")
        .delay(800)
        .fadeOut();
    $("#delay-loader")
        .delay(1000)
        .fadeOut();
    //$(".chosen-select").chosen({ width: "100%" });




    $('.date-textbox').datepicker({
        format: 'mm/dd/yyyy',
        autoclose: true,
        todayHighlight: true
    });

    //setDealersCSS();


}); // End of use ready


          
 // end of menu jquery code    
      
      
      
      
  }

}
