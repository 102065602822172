import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { UserIdleService } from 'angular-user-idle';
import { LoginAuthenticationService } from './service/common-service/login-authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit{
 @BlockUI() blockUI: NgBlockUI;   
  title = 'canonEpartners';
  invalidLogin=false;
  showModal = false;
  authenticated = false;
  showHeader = false;
  showSidebar = false;
  showFooter = false;
  loggedUserName='';
  loggedUserFullName='';
  userAuthToken: string ='';

  constructor(private router:Router,private loginAuthenticationService:LoginAuthenticationService,private userIdle: UserIdleService,private activatedRoute: ActivatedRoute) { }
  ngOnInit()
  {
    this.loginAuthenticationService.checkAuthLoginService().subscribe
	    (data =>{      
	     console.log("CheckAuth --> Authenticated :" + data.authenticated); 
	      if(data.authenticated == true)
	      {
              this.authenticated = true;
              this.invalidLogin=false;
              this.loggedUserName= data.userName;
              this.loggedUserFullName= data.userFullName;
            //  this.userIdle.startWatching();
            //this.router.navigate(['home'])
            console.log("LoggedIn user: from AppComponent"+this.loggedUserName);
            console.log("LoggedIn user Full Name: from AppComponent"+this.loggedUserFullName);
	      }
	      else
	    	  {
	    	  //this.userIdle.startWatching();
	    	  }
     });
    //  this.loginAuthenticationService.getUserAuthToken().subscribe(data => {
    //   this.userAuthToken = data;
    //   console.log('userAuthToken AppComponent: ', this.userAuthToken);

    // });

  //this.blockUI.start("Please wait...");
      this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showHeader =this.activatedRoute.firstChild.snapshot.data['showHeader'];
        this.showSidebar = this.activatedRoute.firstChild.snapshot.data['showSidebar'];
        this.showFooter = this.activatedRoute.firstChild.snapshot.data.showFooter !== false;
      }
    });
    setTimeout(() => {
      //this.blockUI.stop();
    }, 2500);
	  
    
  //    this.userIdle.onTimerStart().subscribe(
  //       count => 
  //       {
  //       console.log("Time Starts"+count);
  //         this.showModal = true;
  //      }
        
  //    );
    
  //    // Start watch when time is up.
  //     this.userIdle.onTimeout().subscribe(() => 
  //    {
  //       console.log('Time is up!');
  //       //alert("Time is Up");
  //       this.userIdle.stopWatching();
  //       this.showModal = false;
  //       this.loginAuthenticationService.executeUserSessionTimeoutService().subscribe
	//     (data =>{      
	// 	     console.log("Page Redirect to login --> response :" + data); 
	// 	     this.invalidLogin=true;
	//          this.router.navigate(['login']);
  //          });
     
  //     }
  //  );
	}
  restart() {
    console.log('Timer reset done.');
    //this.showModal = false;
    window.location.reload();
    this.userIdle.resetTimer();
    this.showModal = false;
  }
}

