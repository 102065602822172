import { Injectable } from '@angular/core';
import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { HttpClient, HttpHeaders} from "@angular/common/http";
import { Util } from '../../app.util';

const httpOptions = {
    headers: new HttpHeaders({
        
    }).set('Content-Type', 'application/json')
  };


@Injectable({
  providedIn: 'root'
})
export class AcknowledgementService {
  
  defaultUrl = 'assets/acknowledgement.json';
  defaultUrlNew : string;

  constructor(private http: HttpClient, private util : Util) {
      
        this.defaultUrlNew = this.util.getBaseUrl();  
  }


  getAcknowledgementList(data): Observable<any> {
    //data = JSON.stringify(data);
    let initialUrl = this.defaultUrlNew+"ack/gadc";
    //let initialUrl = this.defaultUrl;

   return this.http
   .post(initialUrl,data,httpOptions)
   //.get(initialUrl)
     .pipe(catchError(this.handleError("getOrderDealerList")))
     // .pipe(map(xml => this.toColleciton(this.tojson(xml))));
     //.pipe(map(data => this.toColleciton2(data)));
 }

 
 confirmAcknowledgement(data): Observable<any> {
  //data = JSON.stringify(data);
  let initialUrl = this.defaultUrlNew+"dealer/servackd";
  //let initialUrl = this.defaultUrl;

 return this.http
 .post(initialUrl,data,httpOptions)
 //.get(initialUrl)
   .pipe(catchError(this.handleError("getOrderDealerList")))
   // .pipe(map(xml => this.toColleciton(this.tojson(xml))));
   //.pipe(map(data => this.toColleciton2(data)));
}


 private handleError<T>(operation = "operation", result?: T) {
  return (error: any): Observable<T> => {
    // TODO: send the error to remote logging infrastructure
    console.error(error); // log to console instead

    // TODO: better job of transforming error for user consumption
    console.log(`${operation} failed: ${error.message}`);

    // Let the app keep running by returning an empty result.
    return of(result as T);
  };
}










}
