import { Component, OnInit } from '@angular/core';
import { LoginAuthenticationService } from '../../service/common-service/login-authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(private loginAuthenticationService:LoginAuthenticationService,private router:Router) { }
  invalidLogin=false;
  ngOnInit() {
    this.loginAuthenticationService.executeUserlogoutService().subscribe
    (data =>{
     console.log(data)
     this.invalidLogin=true;
     this.router.navigate(['login']);
  },
   error=>{
     console.log(error)
     this.invalidLogin=false;
   }
    );    
  }

}
