import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserIdleModule } from 'angular-user-idle';
import { BlockUIModule } from 'ng-block-ui';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './common/login/login.component';
import { LogoutComponent } from './common/logout/logout.component';
import { DashboardComponent } from './common/dashboard/dashboard.component';
import { HomeComponent } from './common/home/home.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FooterComponent } from './layout/footer/footer.component';
import { ErrorComponent } from './common/error/error.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { HeaderComponent } from './layout/header/header.component';
import { SideNavComponent } from './layout/side-nav/side-nav.component';
import { MatDatepickerModule,MatNativeDateModule,MatInputModule} from '@angular/material';
import { OrderQueryComponent } from './order-mgmt/order-query/order-query.component';
import { OrderCreateNewComponent } from './order-mgmt/order-create-new/order-create-new.component';
import { OrderDetailDealerComponent } from './order-mgmt/order-detail-dealer/order-detail-dealer.component';
import { OrderDetailCsaComponent } from './order-mgmt/order-detail-csa/order-detail-csa.component';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { FleetComponent } from './fleet/fleet/fleet.component';
import { ServiceRequestComponent } from './service-request/service-request/service-request.component';
import { NumberFieldDirective } from './customdirectives/number-field.directive';
import { CurrencyFieldDirective } from './customdirectives/currency-field.directive';
import { AccessDeniedComponent } from './common/access-denied/access-denied.component';
import { ServiceCreateNewComponent } from './service-request/service-create-new/service-create-new.component';
import { ServiceCsaCreateNewComponent } from './service-request/service-csa-create-new/service-csa-create-new.component';
import { MeterHistoryComponent } from './fleet/meter-history/meter-history.component';
import { OrderApprovalsComponent } from './order-mgmt/order-approvals/order-approvals.component';
import { AcknowledgementComponent } from './fleet/acknowledgement/acknowledgement.component';
import { FleetDetailsComponent } from './fleet/fleet-details/fleet-details.component';
import { ServiceDetailsComponent } from './service-request/service-details/service-details.component';
import { MeterEntryComponent } from './fleet/meter-entry/meter-entry.component';
import { CompensationDetailsComponent } from './finance/compensation-details/compensation-details.component';
import { FinanceComponent } from './finance/finance/finance.component';
import { FaqComponent } from './common/faq/faq.component';





@NgModule({
  
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BlockUIModule.forRoot(),
     // Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
    // Default values: `idle` is 7200 (120 minutes), `timeout` is 60 (1 minute) 
    // and `ping` is 10 (10 seconds).
    UserIdleModule.forRoot({idle: 7200, timeout: 60, ping: 10}),
    ReactiveFormsModule,
   BrowserAnimationsModule,
   MaterialModule,
   MatNativeDateModule,
   MatInputModule,
   MatDatepickerModule
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    LogoutComponent,
    DashboardComponent,
    HomeComponent,    
    FooterComponent,
    ErrorComponent,
    HeaderComponent,
    SideNavComponent,  
    OrderQueryComponent,
    OrderDetailDealerComponent,   
    OrderCreateNewComponent, 
    OrderDetailCsaComponent,
    FleetComponent, 
    ServiceRequestComponent,
    NumberFieldDirective,
    AccessDeniedComponent,
    CurrencyFieldDirective,
    ServiceCreateNewComponent,
    ServiceCsaCreateNewComponent,
    MeterHistoryComponent,
    MeterEntryComponent,
    OrderApprovalsComponent,
    AcknowledgementComponent,
    FleetDetailsComponent,
    ServiceDetailsComponent,
    CompensationDetailsComponent,
    FinanceComponent,
    FaqComponent

    
  ],
  providers: [
    DatePipe, Title,CurrencyPipe 
    // {
    //    provide:HTTP_INTERCEPTORS, useClass: HttpInterceptorBasicAuthService, multi:true
    //  }
  ],     
  bootstrap: [AppComponent]
})
export class AppModule { }
 