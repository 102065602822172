import { Component, OnInit, OnDestroy  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { FinanceService } from '../../service/finance-service/finance.service';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';
import { Util } from 'src/app/app.util';
import { SetScreenService } from 'src/app/service/common-service/set-screen.service';
import { Subscription } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.css']
})
export class FinanceComponent implements OnInit, OnDestroy  {

compensationList = [];
paymentList = [];
startDate: any = "";
endDate: any = "";
serialNumber = "";
dealerPONumber = "";
invoiceNumber = "";
paymentNumber = "";
customerName = "";
dealerNameSrch = "";
selectedStartDate: any;
selectedEndDate: any;
//pselectedStartDate: any;
//pselectedEndDate: any;

totalRecordsDealer;
totalDealerOrders: Array<number>;
totalDealerPagers;

ptotalRecordsDealer;
ptotalDealerOrders: Array<number>;
ptotalDealerPagers;


totalRecordsCsa;
totalCsaOrders: Array<number>;
totalCsaPagers;
selectedSortBy;
selectedSortOrder;
pselectedSortBy;
pselectedSortOrder;
dSelectAll = true;
dInvDate = true;
dSerial = true;
dDealerName = true;
dCustomerName = true;
dInvNum = true;
dDueDate = true;
compDetails = true;
dStatus = true;
dAmounts = true;
dPaymentNum = true;
dPaymentDate = true;
dFlag = true;
dComments = true;
cSelectAll = true;
dealerName="";
pageNum = 1;
ppageNum = 1;

recPerPage = 10;
precPerPage = 10;
tableLoader = true;
ptableLoader = true;

csaTab: string ='';
dealerTab: string ='CSA-tab-active dealerTabs';

//selectedPeriod = "PY";
selectedPeriod = "CM";
advanceSearchFlag = 'N';
selPeriod:any;
csaDealerFlag : any;
compensationTotal: any;
paymentTotal: any;
isAdmin: string;


period = [
  //{ value: 1, text: 'Status' },
  { value: 'CM', text: 'Activity For Current Month' },
  { value: 'PM', text: 'Activity For Prior Month' },
  { value: 'CY', text: 'Activity For Current Year' },
  { value: 'PY', text: 'Activity For Prior Year' },
  { value: 'ADS', text: 'Advanced Search' }

];


clearButton() {
  this.serialNumber = "";
  this.startDate = "";
  this.endDate = "";
  this.customerName = "";
  this.invoiceNumber = "";
  this.paymentNumber = "";
  this.dealerNameSrch = "";
  this.dealerPONumber = "";


}

changeDSelectAll() {
  if(this.dSelectAll) {
    this.dInvDate = true;
    this.dSerial = true;
    this.dDealerName = true;
    this.dCustomerName = true;
    this.dInvNum = true;
    this.dDueDate = true;
    this.compDetails = true;
    this.dStatus = true;
    this.dAmounts = true;
    this.dPaymentNum = true;
    this.dPaymentDate = true;
    this.dFlag = true;
	this.dComments = true;
   
  }else {

    this.dInvDate = false;
    this.dSerial = false;
    this.dDealerName = false;
    this.dCustomerName = false;
    this.dInvNum = false;
    this.dDueDate = false;
    this.compDetails = false;
    this.dStatus = false;
    this.dAmounts = false;
    this.dPaymentNum = false;
    this.dPaymentDate = false;
    this.dFlag = false;
	this.dComments = false;
  } 
}



getCompensationDetails(inv){
    let objParams=this.getCompensationSearchParam();
    if(inv){
      objParams["in"]=inv;
    }else {
    objParams["in"]=this.invoiceNumber; 
  }

  if(this.advanceSearchFlag == 'Y') {
    objParams["period"] = this.selectedPeriod;
  }

  objParams["cn"]=this.customerName; 
      this.routing.navigate(['compensationdetails'],{ queryParams: objParams});
}


getCompensationSearchParam () {

  if(this.startDate) {
    this.changeEvent(this.startDate,'sd');
  }else{
    this.selectedStartDate = "";
  }

  if(this.endDate) {
    this.changeEvent(this.endDate,'ed');
  }else{
    this.selectedEndDate = "";
  }

  if(this.advanceSearchFlag == 'Y') {
    this.selPeriod = "";
  }else{
    this.selPeriod = this.selectedPeriod;
  }
  

return {

    
  "csaDealerFlag": this.csaDealerFlag,
  "paymentNum" : this.paymentNumber,	
  //"userName": "Q11989",
  "advancedSearchFlag": this.advanceSearchFlag,
  "period" : this.selPeriod,
  "invFromDate" : this.selectedStartDate,
  "invToDate" : this.selectedEndDate,
  "serialNumber" : this.serialNumber,
  "dealerPONumber" : this.dealerPONumber,
  "invoiceNumber" : this.invoiceNumber,
  "customerName" : this.customerName,
  "dealerName" : this.dealerNameSrch,
  //"sortBy" : "INVOICE_DATE",
  "sortBy" : this.selectedSortBy,
  //"sortOrder" : "ASC",
  "sortOrder" : this.selectedSortOrder,
  "pageNum": this.pageNum,
  "recPerPage": this.recPerPage
  }
  
}



getPaymentSearchParam () {

  if(this.advanceSearchFlag == 'Y') {
    this.selPeriod = "";
  }else{
    this.selPeriod = this.selectedPeriod;
  }
  
  

  return {
      
    "csaDealerFlag": this.csaDealerFlag,
    "paymentNum" : this.paymentNumber,	
    //"userName": "Q11989",
    "advancedSearchFlag": this.advanceSearchFlag,
    "period" : this.selPeriod,
    "invFromDate" : this.selectedStartDate,
    "invToDate" : this.selectedEndDate,
    "serialNumber" : this.serialNumber,
    "dealerPONumber" : this.dealerPONumber,
    "invoiceNumber" : this.invoiceNumber,
    "customerName" : this.customerName,
    "dealerName" : this.dealerNameSrch,
    "sortBy" : this.pselectedSortBy,
    "sortOrder" : this.pselectedSortOrder,
    // "sortBy" : "INVOICE_NUM",
    // "sortOrder" : "ASC",

    "pageNum": this.ppageNum,
    "recPerPage": this.precPerPage
    }
    
  }

  changeActivity() {
    if(this.selectedPeriod != 'ADS') {
    this.clearButton();
    this.pageNum = 1;
    this.ppageNum = 1;
    this.advanceSearchFlag = 'N';
    this.searchComensationService(this.getCompensationSearchParam());
    this.searchPaymentDetails(this.getPaymentSearchParam());
    
  
    }


  }


  compensationSearch() {
    this.advanceSearchFlag = 'Y';
    this.searchComensationService(this.getCompensationSearchParam());
    this.searchPaymentDetails(this.getPaymentSearchParam());
    
   }


  setTab(event:any){
    console.log(event);
    //this.pageNum = 1;
    if(event == 'dealer'){
    this.csaDealerFlag = "DEALER"
    this.tableLoader = true;
    this.ptableLoader = true;
    this.setScreenService.setDealersCSS();
     this.pageNum = 1;
     this.ppageNum = 1;
     this.dealerTab = 'CSA-tab-active dealerTabs';
     this.csaTab = '';
     //this.clearButton();
     //this.selectedPeriod = "PY";
     //this.selectedPeriod = "CM";
     if(this.selectedPeriod != 'ADS') {
      this.advanceSearchFlag = 'N';
      }
     this.searchComensationService(this.getCompensationSearchParam());
     this.searchPaymentDetails(this.getPaymentSearchParam());


     //this.setPgLinks(10000,1,10,10);
    }else if (event == 'csa') {
      this.csaDealerFlag = "CSA"
      this.setScreenService.setCSACSS();
     this.tableLoader = true;
     this.ptableLoader = true;
     this.pageNum = 1;
     this.ppageNum = 1;
     this.csaTab = 'CSA-tab-active';
     this.dealerTab = '';
     //this.clearButton();
     //this.selectedPeriod = "PY";
     //this.selectedPeriod = "CM";
     if(this.selectedPeriod != 'ADS') {
      this.advanceSearchFlag = 'N';
      }
     this.searchComensationService(this.getCompensationSearchParam());
     this.searchPaymentDetails(this.getPaymentSearchParam());
    }

  }

  dowloadInvoiceDetails() {
    let totalDealerCompensationList = [];
    const dPageNumber = this.pageNum;
    this.recPerPage = 1000000;
    this.pageNum = 1;
      this.financeService.getCompensationList(this.getCompensationSearchParam()).subscribe(data => {
      totalDealerCompensationList = data.data;
      console.log('totalDealerCompensationList',totalDealerCompensationList);
      if(this.myVar == 1){
        this.financeService.jsonToExcel(totalDealerCompensationList,'Compensation Earned', true);
      } else {
        this.financeService.jsonToExcel(totalDealerCompensationList,'Compensation Owned to CSA', true);

      }
      this.recPerPage = 10;
      this.pageNum = dPageNumber;
    
    });

  
  }

  dowloadPaymentDetails() {

    
    let totalDealerPaymentList = [];
    const pPageNumber = this.ppageNum;
    this.precPerPage = 1000000;
    this.ppageNum = 1;

    this.financeService.getPaymentDetailsList(this.getPaymentSearchParam()).subscribe(data => {
      totalDealerPaymentList = data.data;
      console.log('totalDealerCompensationList',totalDealerPaymentList);
      if(this.myVar == 1){
        this.financeService.jsonToExcel(totalDealerPaymentList,'Payment Details Dealer', true);
      } else {
        this.financeService.jsonToExcel(totalDealerPaymentList,'Payment Details CSA', true);

      }
      this.precPerPage = 10;
      this.ppageNum = pPageNumber;
    
    });

  }

  changeEvent(event,date){ 
    // Return date object
    let dd = event.getDate();
    let mm = event.getMonth() + 1; //January is 0!
    let yyyy = event.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    if (date=='sd'){
    this.selectedStartDate =  mm + '/' + dd + '/' + yyyy ; 
    //this.pselectedStartDate =  dd + '-' + mm + '-' + yyyy ;
    }
    if (date=='ed'){
      this.selectedEndDate =  mm + '/' + dd + '/' + yyyy ; 
      //this.pselectedEndDate =  dd + '-' + mm + '-' + yyyy ;
      }
    //console.log(event);
   // console.log('dd',dd);
    return;
  }

  calTotalPages (pages){
    let totalPages = pages/10;
    let roundedTotalPages = Math.round(totalPages);
  
    
    if (totalPages > roundedTotalPages) {
      totalPages = roundedTotalPages + 1;
    }else {
      totalPages = roundedTotalPages;
    }
  
    console.log('totalPages',totalPages);
    return totalPages;
   }


  searchComensationService(params) { 

    this.tableLoader = true;
  
    this.financeService.getCompensationList(params).subscribe(data => {
    this.compensationList = data.data;
    this.compensationTotal = data.total;
    let totalDealerPages = data.recCount;
    this.dealerName = data.dealerName;
    this.totalRecordsDealer = totalDealerPages;
    totalDealerPages = this.calTotalPages(totalDealerPages);
    this.totalDealerPagers = totalDealerPages;
  

    this.totalDealerOrders = new Array(totalDealerPages);
    console.log('compensationList ',this.compensationList);
    console.log('totalRecordsDealer',this.totalRecordsDealer);
    this.tableLoader = false;
    //this.advanceSearchFlag = 'F';

  
  });
  }

  searchPaymentDetails(params) { 

    this.ptableLoader = true;
  
    this.financeService.getPaymentDetailsList(params).subscribe(data => {
    this.paymentList = data.data;
    this.paymentTotal = data.total;
    
    let ptotalDealerPages = data.recCount;
    
    this.ptotalRecordsDealer = ptotalDealerPages;
    ptotalDealerPages = this.calTotalPages(ptotalDealerPages);
    this.ptotalDealerPagers = ptotalDealerPages;
  
    //this.totalDealerOrders = new Array(data['recCount']);
    this.ptotalDealerOrders = new Array(ptotalDealerPages);
    console.log('paymentList ',this.paymentList);
   // console.log('totalRecordsDealer',this.totalRecordsDealer);
    this.ptableLoader = false;
    //console.log('totalRecordsDealer ',this.totalRecordsDealer );
    //console.log(JSON.stringify(this.csaOrderList));
  
  });
  }


  setSortOrder(sortBy,sortOrder,type) {
    
    if(type=="dealer"){
      this.selectedSortBy = sortBy;
      this.selectedSortOrder = sortOrder;
      this.searchComensationService(this.getCompensationSearchParam());  
    }else if(type=="payment"){
      this.pselectedSortBy = sortBy;
      this.pselectedSortOrder = sortOrder;
      this.searchPaymentDetails(this.getPaymentSearchParam());
    }
  
  }


  
setPage (param,i, event: any) {
  event.preventDefault();
  this.pageNum= i;  
  if(param=='dealer'){
   //this.searchDealerList();
   this.searchComensationService(this.getCompensationSearchParam());

 }else{
 //this.searchCsaList();
  this.ppageNum = i;
  this.searchPaymentDetails(this.getPaymentSearchParam());
 }

}

setNextPage (param,event: any) {
 event.preventDefault();
 this.pageNum++;
 if(param=='dealer'){
  this.searchComensationService(this.getCompensationSearchParam());
 }else{
  this.ppageNum++; 
  this.searchPaymentDetails(this.getPaymentSearchParam());
 }


}
setPrevPage (param,event: any) {
 event.preventDefault();
 if (this.pageNum > 1){
 this.pageNum--;
}
if(param=='dealer'){
  this.searchComensationService(this.getCompensationSearchParam());
}else{
  if (this.ppageNum > 1){
    this.ppageNum--;
   }
  this.searchPaymentDetails(this.getPaymentSearchParam());
}


}

setFirstPage (param:string,event: any) {
 event.preventDefault();
 this.pageNum = 1;
 if(param=='dealer'){
  this.searchComensationService(this.getCompensationSearchParam());
 }else{
  this.ppageNum = 1;
  this.searchPaymentDetails(this.getPaymentSearchParam());
 }

}

setLastPage (param,i,event: any) {
 event.preventDefault();
 this.pageNum = i;
 if(param=='dealer'){
  this.searchComensationService(this.getCompensationSearchParam());
 }else{
  this.ppageNum = i;
  this.searchPaymentDetails(this.getPaymentSearchParam());
 }


}

  


  setScreen(num)
  {
     
    this.setScreenService.changeMyVar(num);
   
 }





  private subscription: Subscription;
  myVar;
 


  constructor(private route:ActivatedRoute,private loginAuthenticationService:LoginAuthenticationService, private financeService: FinanceService,private util : Util, private titleService: Title,private setScreenService:SetScreenService,private routing:Router) {}

  ngOnInit() {


    
  

    this.titleService.setTitle("Finance");
    this.advanceSearchFlag = 'F';
    this.isAdmin=	sessionStorage.getItem('Admin');
    this.subscription = this.setScreenService.changeVar.subscribe(message => {

      this.route.queryParams
  .subscribe(params => {
    this.selectedStartDate = params['invoiceFromDate'];
    this.selectedEndDate = params['invoiceToDate'];
    let pFromDate=params['invoiceFromDate'];
    let pToDate=params['invoiceToDate'];
    if(pFromDate){
      this.startDate =new Date(pFromDate);
    }
    if(pToDate){
      this.endDate =new Date(pToDate);
    }
    this.customerName = params['cn'];
    this.serialNumber = params['serialNumber'];
    this.paymentNumber = params['paymentNumber'];
    this.dealerPONumber = params['dealerPONumber'];
    if(params['sp'] == 'ADS') {
    this.invoiceNumber = params['invoiceNumber']
    this.dealerNameSrch = params['dealerName'];
    }
    if(params['sp']){
      this.selPeriod = params['sp'];
      this.selectedPeriod = params['sp'];
  }
    //this.selectedPeriod = params['period'];
    
    // if(!this.period || (this.period&&this.period=="ADS") || this.invoiceNumber){
    //   this.period="AS";
    // }else{
       
    //   this.advanceSearchFlag = "N";
    // }
    
  }); 

      //if (message !== this.myVar) {
        //alert("fleet message"+message);
          this.myVar = Number(message);
          if(this.myVar == 0){
            this.csaDealerFlag = "CSA"
            this.setTab('csa');
            


            }else if(this.myVar == 1){
              this.csaDealerFlag = "DEALER"
              this.setTab('dealer');
             

            }           
     // }
  });
  }
  
  ngOnDestroy() {
    if (this.subscription) {
    this.subscription.unsubscribe();
    }

  }

  

}
