import { Component, OnInit } from '@angular/core';
import { CreateServiceRequest } from 'src/app/models/CreateServiceRequest';
import { ServiceCreateNewService } from 'src/app/service/service-request-service/service-create-new.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';

@Component({
  selector: 'app-service-create-new',
  templateUrl: './service-create-new.component.html',
  styleUrls: ['./service-create-new.component.css']
})
export class ServiceCreateNewComponent implements OnInit {

  arrProblemType: any = [];
  arrProblemDescription: any = [];
  arrProblemCode: any = [];
  csr: CreateServiceRequest = new CreateServiceRequest();


  arrSrl: any[] = [];
  srchSerialNumber: string = '';
  srlPageNum = 1;
  srlRecPerPage = 10;
  srlTotalRecords = 0;
  srlLastPage = 0;
  arrSrlPgLinks: Array<number>;

  msg: string = '';
  msgClass: any = [];
  bSubmitInProgress: boolean = false;
  bSubmitSuccess: boolean = false;
  bSubmitError: boolean = false;

  objServiceTypes: any = { "CSA": "CSA_SERVICED", "DEALER": "DEALER_SERVICED" };
  serviceType: any;
  SERVICE_TYPE_KEY:string='';
  

  bDealerServiceType: boolean = false;

  bDupSR: boolean = false;
  dupSRFlag: string = 'N';
  dupSRMsgSrl: string = '';
  dupSRMsgSRNum: string = '';

  
  userRoles = {};

  constructor(private router: Router, private route: ActivatedRoute, private createSrService: ServiceCreateNewService,private loginAuthenticationService: LoginAuthenticationService) { }

  ngOnInit() {
    this.loginAuthenticationService.getUserRoles().subscribe(data => {
      this.userRoles = data;
      if (this.userRoles["epSRVAccess"] ||  this.userRoles["epFLTAccess"]){
         // has access
      }else{
        this.router.navigate(['dashboard']);
      }
    });
    this.getProblemTypes();

    this.route.queryParams.subscribe(params => {
      let st: string = "";
      let srl: string = "";
      st = params['st'];
      srl = params['srl'];
      let isAdmin=  sessionStorage.getItem('Admin');
      if(isAdmin && isAdmin=='Y'){
         this.router.navigate(['service']);
      }

      if (st && st.trim().length > 0) {
        st = st.trim();
        st = st.toUpperCase();
        this.serviceType = this.objServiceTypes[st];
        this.SERVICE_TYPE_KEY=st;
      } else {
        console.log("Service Type is null. Sending user to Search SR Page");
        this.router.navigate(['service']);
      }
      if (srl && srl.trim().length > 0) {
        srl = srl.trim();
        srl = srl.toUpperCase();
        this.srchSerialNumber = srl;
        this.checkDuplicateSR(srl);
       }

    });

    if (this.serviceType && this.serviceType == 'DEALER_SERVICED') {
      this.bDealerServiceType = true;
    }

  }


  getProblemTypes() {
    let params = {};
    this.arrProblemDescription = [];
    this.arrProblemCode = [];
    this.csr.problemType = '';
    this.csr.problemDescription = '';
    this.csr.problemCode = '';
    this.createSrService.getProblemTypes(params).subscribe(data => {
      this.arrProblemType = data.data;

    });

  }

  getProblemDescriptions() {
    this.arrProblemCode = [];
    this.csr.problemDescription = '';
    this.csr.problemCode = '';
    let params = { "problemType": this.csr.problemType };
    if (this.csr.problemType && this.csr.problemType.length > 0) {
      this.createSrService.getProblemDescriptions(params).subscribe(data => {
        this.arrProblemDescription = data.data;
      });
    }
  }

  getProblemCodes() {
    this.csr.problemCode = '';
    let params =
    {
      "problemType": this.csr.problemType,
      "problemDescription": this.csr.problemDescription
    };
    this.createSrService.getProblemCodes(params).subscribe(data => {
      this.arrProblemCode = data.data;
    });

  }

  checkDuplicateSR(sSrl) {
    let params = {
      "serialNumber": sSrl,
    };
   
    this.createSrService.checkDuplicateSR(params).subscribe(data => {
      this.dupSRFlag = data.duplicateSR;
      if (this.dupSRFlag == 'Y') { 
        this.dupSRMsgSRNum=data.srNumber;
        this.dupSRMsgSrl=sSrl;
        this.bDupSR = true;
         this.clearAll();
      } else {
        this.bDupSR = false;
        this.dupSRFlag = 'N';
        this.dupSRMsgSRNum = '';
        this.dupSRMsgSrl = '';
        this.srchSerialNumber=sSrl;
        this.populateSerialDetails();
      }
    });

  }


  getSerialList(pageNum: any, event: any) {
    event.preventDefault();
    this.srlPageNum = pageNum;
    let srlParam = { "serialNumber": this.srchSerialNumber, "srlPageNum": this.srlPageNum, "srlRecPerPage": this.srlRecPerPage, "serviceType": this.serviceType };
    this.createSrService.getSerialList(srlParam).subscribe(data => {
      this.arrSrl = data.data;
      this.srlTotalRecords = data.recCount;
      this.srlLastPage = (data.recCount % 10 == 0) ? (data.recCount / 10) : (parseInt(("" + data.recCount / 10)) + 1);
      this.arrSrlPgLinks = new Array(this.srlLastPage);

    });

  }
 

  serialOnTab(){
    let srlParam = { "serialNumber": this.csr.serialNumber, "srlPageNum": 1, "srlRecPerPage": this.srlRecPerPage, "serviceType": this.serviceType };
    this.createSrService.getSerialList(srlParam).subscribe(data => {
      this.arrSrl = data.data;
      this.srlTotalRecords = data.recCount;
      if (this.arrSrl.length == 1) {
         let objSrl=this.arrSrl[0]
        /* this.csr.serialNumber = objSrl.serialNumber;
         this.csr.model = objSrl.model;
         this.csr.orgranization = objSrl.orgranization;*/
         this.arrSrl = [];
         this.checkDuplicateSR(objSrl.serialNumber);
         this.srchSerialNumber='';
       }else{ 
        let btnEle:HTMLElement=document.getElementById("btnSrlLkp");
        btnEle.click();
       } 
      });
  
  }


  populateSerialDetails() {
    let srlParam = { "serialNumber": this.srchSerialNumber, "srlPageNum": 1, "srlRecPerPage": this.srlRecPerPage, "serviceType": this.serviceType };
    this.createSrService.getSerialList(srlParam).subscribe(data => {
      this.arrSrl = data.data;
      this.srlTotalRecords = data.recCount;
      if (this.arrSrl.length > 0) {
         let objSrl=this.arrSrl[0]
         this.csr.serialNumber = objSrl.serialNumber;
         this.csr.model = objSrl.model;
         this.csr.orgranization = objSrl.orgranization;
         this.arrSrl = [];
         this.srchSerialNumber='';
       }
    });
  }

  setSerial(objSrl) {
    this.clearAll();
    /*this.csr.serialNumber = objSrl.serialNumber;
    this.csr.model = objSrl.model;
    this.csr.orgranization = objSrl.orgranization;*/
    this.checkDuplicateSR(objSrl.serialNumber);
  }


  clearAll() {

    this.csr = new CreateServiceRequest();
    this.srchSerialNumber = '';

  }


  createServiceRequest(sFlag: string) {
    this.msg = '';
    this.bSubmitSuccess = false;
    this.bSubmitInProgress = false;
    let bInvalid: boolean = false;
    let errorMsg: string = '';
    if (this.csr.serialNumber.trim().length < 1) {
      errorMsg += " \nPlease select valid Serial Number ";
      bInvalid = true;
    }
    if (this.bDealerServiceType && this.csr.dealerReferenceNumber.trim().length < 1) {
      errorMsg += " \nPlease enter Dealer Reference Number";
      bInvalid = true;
    }
    if (this.csr.problemType.trim().length < 1) {
      errorMsg += " \nPlease select valid Problem Type ";
      bInvalid = true;
    }

    if (this.csr.problemDescription.trim().length < 1) {
      errorMsg += " \nPlease select valid Problem Description";
      bInvalid = true;
    }
    if (this.csr.problemCode.trim().length < 1) {
      errorMsg += " \nPlease select valid Problem Code";
      bInvalid = true;
    }
    if (this.csr.problemSummary.trim().length < 1) {
      errorMsg += " \nPlease enter Problem Summary";
      bInvalid = true;
    }



    if (bInvalid) {
      this.msg = errorMsg;
      this.msgClass = ['error', 'white-space-pre-line'];
      this.bSubmitError = true;
      return;
    } else {
      this.bSubmitError = false;
    }


    if (!bInvalid && sFlag == 'C') {
      this.bSubmitInProgress = true;
      this.msg = "Submitting Service Request.Please wait.";
      this.msgClass = ['success'];
      this.createSrService.createServiceRequest(this.csr).subscribe(data => {
        //console.log(data);
        if (data.errFlag == 'S') {
          let tmpSrl=this.csr.serialNumber;
          $(".modal-backdrop").remove(); // modal backgrond fix
          this.msg = "Service Request has been created.  Service Request Id : " + data.incident;
          this.msgClass = ['success'];
          this.bSubmitSuccess = true;
          this.bSubmitInProgress = false;
          this.clearAll();
          this.router.navigate(['service'],{ queryParams: {st: this.SERVICE_TYPE_KEY, sn:  tmpSrl}});
        } else {
          this.msg = "Error Occurred while creating Service Request. Please try again.";
          this.msgClass = ['error'];
          this.bSubmitInProgress = false;
        }
      });
    }

  }

}
