import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';
import { Util } from 'src/app/app.util';
import { Title } from '@angular/platform-browser';
import { OrderApprovalsService } from 'src/app/service/order-mgmt-service/order-approvals.service';
import { SetScreenService } from 'src/app/service/common-service/set-screen.service';
import { DOCUMENT, CurrencyPipe } from '@angular/common';
import { OrderCreateNewService } from 'src/app/service/order-mgmt-service/order-create-new.service';
import { NewOrder } from 'src/app/models/NewOrder';
declare var $: any;
enum CheckBoxType { ALL, DEALER, CSA ,NONE};

@Component({
  selector: 'app-order-approvals',
  templateUrl: './order-approvals.component.html',
  styleUrls: ['./order-approvals.component.css']
})
export class OrderApprovalsComponent implements OnInit {
  loggedUserName: string= "";
  loggedUserFullName: string= "";
    myVar: string = "";
    reqNo: any  = "";
    dlrScreen=false;
    csaScreen=false;
    tableLoader = true;
    dealerName: string = "";
    dlrApprovalList: any;
    csaApprovalList: any;
    totalCsaPages: any;
    totalRecordsCsa: any;
    fromDate: string;
    totalCsaOrders: any[];
    totalRecordsDealer: any;
    totalDealerPages: any;
    totalDealerOrders: any[];
    isAdmin: string;
  ordNum: any = "";
  ittNum: any = "";
  reqNum: any = "";
  status = '';
  shipToCust: any = "";
  pageNum = 1;
  selectedSortBy;
  selectedSortOrder;
  recPerPage = 10;
  orderNum: string = "";
  uploadedDocs = true;
  uploadedDocsC = true;
  approvalstatus = [
    //{ value: 1, text: 'Status' },
    { value: 'Pending CSA Approval', text: 'Pending CSA Approval' },
    { value: 'Pending CSA Review', text: 'Pending CSA Review' },
    { value: 'Pending Dealer Approval', text: 'Pending Dealer Approval' },
    { value: 'Pending Dealer Review', text: 'Pending Dealer Review' },
    { value: 'Approved', text: 'Approved' },
    {  value: 'Rejected', text: 'Rejected' }
  

  ];
  defaultUrlNew: string;
  setDSelectAll= true;
  setDITT = true;
  setDStatus= true;
  setDOrder= true;
  setDReq=true;
  setDInstallBy= true;
  setDRefPo= true;
  setSubmittedBy= true;
  setDSubmitDate= true;
  setDResDate= true;
  setDResComments= true;
  setDShipCust= true;
  setApprovalHist =true;
  setCounterInitial =false;
  setColspan = "2";
  pageType = "";
  pgType: any = "";
  approveType: string ="";
  rqNo: any = "";
  approvalHist: any;
  compensationHist: any;
  shipToCodeList: any;
  seldealerCode: any;
  dealerCode: any;
  address: string ="";
  city: string ="";
  state: string ="";
  zipCode: string ="";
  csaApproval: any;
  approvNotesList: any;
  approveTypeAll: boolean;
  approveTypecsaCheck: boolean;
  approveTypedealerCheck: boolean;
  approveStatus: any;
  approveErrorMsg: string ="";
  approveCancelStatus: any;
  comments: string = "";
  proceedBtn = false;
  cnacodebtn = false;
  hideDetails:  boolean = false;
  defaultDealerCode: any ="";
  modalReqNum: string ="";
  modalIttNum: string ="";
  modalInventorySource: string ="";
  modalApprovald: string ="";
  modalApprovalId: string ="";
  modalSourcedBy: string ="";
  cnaFlag: string;
  csaemailId: string ="";
  bounceemailId: string ="";
  dealerEmail:string ="";
  modalShipToCust:string ="";
  counterList: any;
  requestId: any ="";
  approvalId: any ="";
  counterapprovalId: any ="";
  counterorderNum: any ="";
  counterittNum: any ="";
  counterrequestId: any ="";
  counterreqNum: any ="";
  counterversionNum: any="";
  notesCounter: any ="";
  counterCompStatus: any ="";
  versionNumber: number = 0;
  respondedBy: any="";
  versionStatus: any="";
  submitBtn: boolean = false;
  errorApproveStatus: any ="";
  counterShipToCust: any ="";
 validationErrMsg: any ="";
  counterCompErrStatus: string="";
  changedErrMsg: string ="";
  changeErrFlag: boolean = false;  
  histLoaded: boolean = false;
  finValue: number;
  feeValue: number;
  tableheadercomp: string ="";
  tablecsaheadercomp: string="";
  counterAction: string="";
  versionHeader: string="";
  qpDealerName:  string="";
  tablecounterheadercomp: string="";
  tablecsacounterheadercomp: string="";
  changeColorFlag: boolean= false;
  initialSubmittedName: string="";
  counterComittNum: string="";
  counterComreqNum:string="";
  modalSubmittedBy:string ="";
  modalApprovalStatus: string ="";
  srcQpDealerName: string ="";
  newOrder: NewOrder = new NewOrder();
  modalrequestId: string ="";
  defaultShiptoCnaCode: string ="";
  defaultShiptoCnaAddress: string ="";
  defshipToCodeList: any;
  cnaAddress: string ="";
  constructor(private route:ActivatedRoute,private loginAuthenticationService:LoginAuthenticationService,private orderApprovalService:OrderApprovalsService, private util : Util, private titleService: Title,private _setCsaService:SetScreenService,@Inject(DOCUMENT) private document: Document,private router:Router,private newOrderServce: OrderCreateNewService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {

this.titleService.setTitle("Order Approvals");  

    this.pageLoad();
    //this.getDlrApprovalList(this.getDlrApprovalParams());
    this.isAdmin=	sessionStorage.getItem('Admin');
    this.hideDetails = false; 
    this.rqNo = this.route
    .queryParams
    .subscribe(params => {
      this.reqNo = params['reqn'];
    });
    console.log("this.reqNo :"+this.reqNo);
    
    this.pgType = this.route
    .queryParams
    .subscribe(params => {
      this.pageType = params['pt'];
    });
   
    if(this.pageType=="dealer"){
     // alert("in Dealer");
      this.dlrScreen = true;
      this.csaScreen = false;
      this.setDealersCSS();
      if(this.reqNo != undefined)
      this.ittNum = this.reqNo;
      this.currentlyChecked = 1;
      this.approveType ="DEALER";
      this.dealerName ="";
      this.getDlrApprovalList(this.getDlrApprovalParams());
    }
    else if(this.pageType=="csa"){
     // alert("in csa");
      this.csaScreen = true;
      this.dlrScreen = false;
      this.setCSACSS();
      if(this.reqNo != undefined)
      this.reqNum = this.reqNo;
      this.currentlyChecked = 2;
      this.approveType ="CSA";
      this.dealerName ="";
      this.getDlrApprovalList(this.getDlrApprovalParams());
    }
    


    this.loggedUserName= this.loginAuthenticationService.getAuthenticatedUser();
    this.loggedUserFullName= this.loginAuthenticationService.getAuthenticatedUserFullName();
    let username = this.loggedUserName;  
    this.pageLoad();   
  }

  check_box_type = CheckBoxType;

  currentlyChecked: CheckBoxType;
  showInitialCol()
  {
    this.setCounterInitial=true;
  }
  hideInitialCol()
  {
    this.setCounterInitial=false;
  }
  selectCheckBox(targetType: CheckBoxType) {
    // If the checkbox was already checked, clear the currentlyChecked variable
    if(targetType == 0)
    this.approveType = "ALL";
    else if(targetType == 1)
    this.approveType = "DEALER";
    else if(targetType == 2)
    this.approveType = "CSA";
    if(this.currentlyChecked === targetType) {
      this.currentlyChecked = CheckBoxType.NONE;
      return;
    }

    this.currentlyChecked = targetType;
    this.hideDetails = true;
    this.setCounterInitial =false;
    this.getDlrApprovalList(this.getDlrApprovalParams());

  }
  getDlrApprovalParams()
  {
    // if(this.pageType == "csa")
    // {
    // this.approveType ="CSA";
    // }
    // if(this.pageType == "dealer")
    // {
    // this.approveType = "DEALER";
    // }
    return {
        //"userName":  "CITSMO27AC@SADP.COM",
        "orderNumber": this.orderNum.trim(),
        "ittNumber" :this.ittNum.trim(),
        "requestId" : "",
        "requestNumber" :this.reqNum.trim(),
        "approvalStatus" : this.status.trim(),
        "shipToCust" : this.shipToCust.trim(),
        "submitDate" :  "",
        "pageNum": this.pageNum,
        "recPerPage":this.recPerPage,
        "sortBy": this.selectedSortBy,
        "sortOrder": this.selectedSortOrder,
        "approverType":this.approveType,
        "qpDealerName": this.srcQpDealerName.trim()
     
    }
  }

  setPage (param,i, event: any) {
    this.hideDetails = true; 
    event.preventDefault();
    this.pageNum= i;
    if(param=='dlrApproval'){
     this.searchApprovalList();
   }
  }

  setNextPage (param,event: any) {
    this.hideDetails = true; 
   event.preventDefault();
   this.pageNum++;
   if(param=='dlrApproval'){
     this.searchApprovalList();
   }
   }

 setPrevPage (param,event: any) {
  this.hideDetails = true; 
   event.preventDefault();
   if (this.pageNum > 1){
   this.pageNum--;
 }
 if(param=='dlrApproval'){
   this.searchApprovalList();
 }
 }

 setFirstPage (param:string,event: any) {
  this.hideDetails = true; 
   event.preventDefault();
   this.pageNum = 1;
   if(param=='dlrApproval'){
     this.searchApprovalList();
   }
 }

 setLastPage (param,i,event: any) {
  this.hideDetails = true; 
   event.preventDefault();
   this.pageNum = i;
   if(param=='dlrApproval'){
     this.searchApprovalList();
   }
   
 }
 setSortOrder(sortBy,sortOrder,type) {
  this.hideDetails = true; 
  this.selectedSortBy = sortBy;
  this.selectedSortOrder = sortOrder;
  if(type=="dlrApproval"){
  this.searchApprovalList();    
  }
}
  getCsaApprovalParams()
  {
    return {
    }
  }
  searchApprovalListBtn(){
    this.pageNum = 1;
    this.hideDetails = true; 
    this.setCounterInitial =false;
    this.getDlrApprovalList(this.getDlrApprovalParams());

   }
   searchApprovalList(){  
    this.hideDetails = true;  
    this.setCounterInitial =false;
    this.getDlrApprovalList(this.getDlrApprovalParams());

   }
   opencolumnModal()
   {
    this.hideDetails = true; 
   }
   openCounterComp(params)
   {
    this.counterCompStatus = "";
    this.comments = "";
    this.changedErrMsg ="";
    this.histLoaded = false;
    this.compensationHist = "";
    this.counterCompErrStatus ="";
     this.counterapprovalId=params.approvalId;
     this.counterorderNum=params.orderNumber;
     this.counterittNum=params.ittNumber;
     this.counterrequestId=params.requestId;
     this.counterreqNum=params.requestNumber;
     this.respondedBy =params.installedBy;
     this.counterShipToCust =params.shipToCust;
    this.hideDetails = true; 
    this.orderApprovalService.getCounterComp(this.getCounterCompParams()).subscribe(data => {
      this.counterList = data.data;
      this.histLoaded = false;
      this.versionNumber = data.versionNumber;
      this.versionStatus = data.versionStatus;
      this.qpDealerName = data.qpDealerName;
      for (var i = 0; i < this.counterList.length; i++) {       
        this.notesCounter=this.counterList[i].comments_p; 
        this.counterComittNum =this.counterList[i].ittNumber;
        this.counterComreqNum =this.counterList[i].reqNumber;
       }
       console.log("this.qpDealerName:"+this.qpDealerName);
       this.tablecsaheadercomp = (this.versionStatus == 'Pending CSA Review' || this.versionStatus =='Pending CSA Approval') ? 'CSA' :this.qpDealerName;
       this.tableheadercomp = (this.versionStatus == 'Pending Dealer Review' ||  this.versionStatus == 'Pending Dealer Approval') ? this.qpDealerName :'CSA';
       this.tablecounterheadercomp = this.tableheadercomp == 'CSA' ? this.qpDealerName :'CSA';
       this.tablecsacounterheadercomp = this.tablecsaheadercomp == 'CSA' ? this.qpDealerName :'CSA';
       this.initialSubmittedName = this.counterComittNum != '' ? 'CSA':this.qpDealerName;
       console.log(" this.tablecsaheadercomp:"+ this.tablecsaheadercomp);
       console.log(" this.tableheadercomp:"+ this.tableheadercomp);

      console.log('getCounterComp list',this.counterList);
      console.log('getCounterComp versionNumber',this.versionNumber);
      console.log('getCounterComp versionStatus',this.versionStatus);
      console.log('notesCounter',this.notesCounter);
      //console.log(JSON.stringify(this.csaOrderList));
   // this.totalconfigList=this.csaOrderGsd.length;
    },
    (error) =>
    {
      
       console.log("Error: "+error);
      
    });
   }
   openCounterCompHist(params)
   {
    this.counterCompStatus = "";
    this.comments = "";
    this.counterCompErrStatus ="";
     this.counterapprovalId=params.approvalid;
     this.counterorderNum=params.ordNum;
     this.counterittNum=params.ittNum;
     this.counterrequestId=params.reqId;
     this.counterreqNum=params.reqNum;
     this.counterversionNum=params.versionNumber;
     this.respondedBy =params.installedBy;
     this.counterAction =params.action;
     this.hideDetails = true; 
     this.orderApprovalService.getCounterComp(this.getCounterCompParamsHist()).subscribe(data => {
      this.compensationHist = data.data;
      this.versionNumber = data.versionNumber;
      this.versionStatus = data.versionStatus;
      this.qpDealerName = data.qpDealerName;
      this.histLoaded = true;
      this.versionHeader = (params.action == 'Pending Dealer Review' ||  params.action == 'Approved' ||  params.action == 'Rejected') ? 'CSA' :this.qpDealerName;
        console.log('getCounterComp list',this.compensationHist);
        console.log('getCounterComp versionNumber',this.versionNumber);
        console.log('getCounterComp versionStatus',this.versionStatus);
    },
    (error) =>
    {
       console.log("Error: "+error);     
    });
   }
   convertStringCurrencyToNum(sNum: string): number {
    let fmtNum: number = 0.0;

    try {
      sNum = sNum.replace(/\$/g, "");
      sNum = sNum.replace(/\,/g, "");
      sNum = sNum.trim();
      if (sNum) {
        fmtNum = Number(sNum);

      }
    } catch (error) {
      fmtNum = 0.0;
    }

    return fmtNum;
  }
  insertCounterComp(counterList,submittedBy)
  {
    this.counterCompStatus = "";
    this.submitBtn = false;   
    this.counterCompErrStatus ="";
    this.validationErrMsg = "";
    for (var i = 0; i < this.counterList.length; i++) {       
        this.counterList[i].comments_p=this.comments; 
      
          if (this.counterList[i].bwCpc_l) {
            this.counterList[i].bwCpc_l = this.convertStringCurrencyToNum(this.counterList[i].bwCpc_l + "");
            try {
              this.counterList[i].bwCpc_l=(this.counterList[i].bwCpc_l).toFixed(5);
            }
            catch (error) {
              console.log("Some error while rounding of to decimal of bwCpc_l");
            }
          }
          if (this.counterList[i].bwCpc_p) {
            this.counterList[i].bwCpc_p = this.convertStringCurrencyToNum(this.counterList[i].bwCpc_p + "");
            try {
              this.counterList[i].bwCpc_p=(this.counterList[i].bwCpc_p).toFixed(5);
            }
            catch (error) {
              console.log("Some error while rounding of to decimal of bwCpc_p");
            }
          }
          if (this.counterList[i].clrCpc_l) {
            this.counterList[i].clrCpc_l = this.convertStringCurrencyToNum(this.counterList[i].clrCpc_l + "");
            try {
              this.counterList[i].clrCpc_l=(this.counterList[i].clrCpc_l).toFixed(5);
            }
            catch (error) {
              console.log("Some error while rounding of to decimal of clrCpc_l");
            }
          }
          if (this.counterList[i].clrCpc_p) {
            this.counterList[i].clrCpc_p = this.convertStringCurrencyToNum(this.counterList[i].clrCpc_p + "");
            try {
              this.counterList[i].clrCpc_p=(this.counterList[i].clrCpc_p).toFixed(5);
            }
            catch (error) {
              console.log("Some error while rounding of to decimal of clrCpc_p");
            }
          }
         
         
       
       }
    this.orderApprovalService.insertCounterComp(counterList,submittedBy,this.versionNumber,this.csaemailId,this.bounceemailId,this.dealerEmail,this.counterShipToCust,this.dealerName).subscribe(data => {
      this.counterCompStatus = data.status;
      this.versionNumber =data.versionNumber;
      this.submitBtn = true;
      for (var i = 0; i < this.counterList.length; i++) {       
          this.notesCounter=this.counterList[i].comments_p; 
         }
      console.log('getCounterComp list',this.counterList);
      //console.log(JSON.stringify(this.csaOrderList));
   // this.totalconfigList=this.csaOrderGsd.length;
    },
    (error) =>
    {
      
       console.log("Error: "+error);
      
    });
  }
  changeCounterComp()
  {
    this.changeColorFlag = true;
  }
  changeInputCounter(inType,changedobj)
   {
     this.changeErrFlag = true;
    if((inType == 'FindersFee') && (changedobj.findersFee_l <= changedobj.findersFee))
    {
        this.changedErrMsg ="Please enter Finders Fee value greater than or equal to Initial Fee";
        console.log("changedErrMsg:"+this.changedErrMsg);
        this.changeErrFlag =true;
    }
    if((inType == 'InstallationFee') && (changedobj.installationFee_l <= changedobj.installationFee))
    {
        this.changedErrMsg ="Please enter Installation Fee value greater than or equal to Initial Fee";
        this.changeErrFlag =true;
    }
    if((inType == 'RemovableComp') && (changedobj.removableComp_l <= changedobj.removableComp))
    {
        this.changedErrMsg ="Please enter Removable Comp value greater than or equal to Initial";
        this.changeErrFlag =true;
    }
    if((inType == 'ExpenseAmt') && ((changedobj.expenseAmt_l <= changedobj.expenseAmt) && changedobj.colorMeterReadFlag == 'Y' && changedobj.gsdFlag == 'Y'))
    {
        this.changedErrMsg ="Please enter Expense Amount greater than than or equal to Initial"
        this.changeErrFlag =true;
    }
   }
   
   validateCounterComp(counterList)
   {
     let validateErr: string = ""
     let validateErrFlag: boolean = false;
     let validationCounterErrorMsg = '';
     for (let k = 0; k < counterList.length; k++) {
       let counterobj = counterList[k];    
       if(counterobj.findersFee > this.convertStringCurrencyToNum(counterobj.findersFee_l))
       {
        validateErr += "\n Finders Fee should be greater than or equal to Initial for Service Model:" + counterobj.serviceModel;
      
        validateErrFlag = true;
        counterobj.findersFee_l = this.convertStringCurrencyToNum(counterobj.findersFee_l); 
       }
       if(counterobj.installationFee > this.convertStringCurrencyToNum(counterobj.installationFee_l))
       {
        validateErr += "\n Installation Fee  greater than or equal to Initial for Service Model: " + counterobj.serviceModel+"\n";
        validateErrFlag = true;    
        counterobj.installationFee_l = this.convertStringCurrencyToNum(counterobj.installationFee_l);
       }
       if(counterobj.removableComp >this.convertStringCurrencyToNum(counterobj.removableComp_l) && counterobj.rma != 'Y')
       {
        validateErr += "\nRemovable Comp  greater than or equal to Initial for Service Model: " + counterobj.serviceModel+"\n";
        validateErrFlag = true;    
        counterobj.removableComp_l = this.convertStringCurrencyToNum(counterobj.removableComp_l);
       }
        if((counterobj.expenseAmt >this.convertStringCurrencyToNum(counterobj.expenseAmt_l)) && (counterobj.gsdFlag == 'N' || counterobj.reqNumber == ''))
       {
        validateErr += "\nExpense Amt  greater than or equal to Initial for Service Model: " + counterobj.serviceModel+"\n";
        validateErrFlag = true; 
        counterobj.expenseAmt_l = this.convertStringCurrencyToNum(counterobj.expenseAmt_l);
       }
       if(counterobj.basePrice > this.convertStringCurrencyToNum(counterobj.basePrice_l))
       {
        validateErr += "\nBase Price  should be greater than or equal to Initial for Service Model: " + counterobj.serviceModel +"\n";
      
        validateErrFlag = true;      
        counterobj.basePrice_l = this.convertStringCurrencyToNum(counterobj.basePrice_l);  
       }
       if(counterobj.bwAllowance > counterobj.bwAllowance_l)
       {
        validateErr += "\nBase Allowances should be  greater than or equal to Initial for Service Model: " + counterobj.serviceModel+"\n";
        validateErrFlag = true;    
         
       }
       if(counterobj.bwCpc >this.convertStringCurrencyToNum(counterobj.bwCpc_l))
       {
        validateErr += "\nBW CPC should be greater than or equal to Initial for Service Model: " + counterobj.serviceModel+"\n";
        validateErrFlag = true; 
        counterobj.bwCpc_l = this.convertStringCurrencyToNum(counterobj.bwCpc_l);   
        
       }
       if((counterobj.clrAllowance >counterobj.clrAllowance_l) && counterobj.colorMeterReadFlag == 'Y')
       {
        validateErr += "\nCLR Allowances should be greater than or equal to Initial for Service Model: " + counterobj.serviceModel+"\n";
        validateErrFlag = true; 
          
       }
       if((counterobj.clrCpc >this.convertStringCurrencyToNum(counterobj.clrCpc_l)) && counterobj.colorMeterReadFlag == 'Y')
       {
        validateErr += "\nCLR CPC should be  greater than or equal to Initial for Service Model: " + counterobj.serviceModel+"\n";
        validateErrFlag = true; 
        counterobj.clrCpc_l = this.convertStringCurrencyToNum(counterobj.clrCpc_l);
       }
     }
     if (validateErrFlag) {
      validationCounterErrorMsg += validateErr;
      
    }
    return validationCounterErrorMsg;
   }

//   insertCounterComp(counterList,submittedBy)
//   {
//     this.counterCompStatus = "";
//     this.counterCompErrStatus ="";
//     this.validationErrMsg = "";
//     this.submitBtn = false;
//     for (var i = 0; i < this.counterList.length; i++) {       
//         this.counterList[i].comments_l=this.comments; 
//        }
      
       
//         this.validationErrMsg = this.validateCounterComp(counterList)        
//           if(this.validationErrMsg == '')
//           {
//     this.orderApprovalService.insertCounterComp(counterList,submittedBy,this.versionNumber,this.csaemailId,this.bounceemailId,this.dealerEmail,this.counterShipToCust,this.dealerName).subscribe(data => {
//       this.counterCompStatus = data.status;
//       this.versionNumber =data.versionNumber;
//       this.submitBtn = true;
//       for (var i = 0; i < this.counterList.length; i++) {       
//           this.notesCounter=this.counterList[i].comments_l; 
//          }
//       console.log('getCounterComp list',this.counterList);
//       //console.log(JSON.stringify(this.csaOrderList));
//    // this.totalconfigList=this.csaOrderGsd.length;
//     },
//     (error) =>
//     {
//       this.counterCompErrStatus = error;
//        console.log("Error: "+error);
      
//     });
//   }
//   else
//   {
//     this.counterCompErrStatus = this.validationErrMsg;
//   }

//  }
  getCounterCompParams(): any {
    return {
      //"userName":  "CITSMO27AC@SADP.COM",
      "approvalId": this.counterapprovalId.trim(),
      "orderNumber": this.counterorderNum.trim(),
      "ittNumber" :this.counterittNum.trim(),
      "reqId" : this.counterrequestId.trim(),
      "reqNumber" :this.counterreqNum.trim()
  }
  }
    getCounterCompParamsHist(): any {
    return {
      //"userName":  "CITSMO27AC@SADP.COM",
      "approvalId": this.counterapprovalId.toString().trim(),
      "orderNumber": this.counterorderNum.trim(),
      "ittNumber" :this.counterittNum.trim(),
      "reqId" : this.counterrequestId.trim(),
      "reqNumber" :this.counterreqNum.trim(),
      "versionNumber": this.counterversionNum.trim()
  }
  }
getDlrApprovalList(params){
    this.tableLoader = true;
    this.hideDetails = true; 
    this.orderApprovalService.getApprovalDealerList(params).subscribe(data=>{
      this.dlrApprovalList = data.data;
      let totalDealerPages = data.recCount;
      this.csaemailId =data.emailId;
      this.bounceemailId =data.bounceEmail;
      for (var i = 0; i < this.dlrApprovalList.length; i++) {       
        this.dealerName=this.dlrApprovalList[i].userName; 
        this.seldealerCode =  this.dlrApprovalList[i].seldealerCode;
        this.defaultDealerCode =   this.seldealerCode;  
        this.dealerEmail = this.dlrApprovalList[i].approvalEmailAddress;       
       }
      //this.dealerName = data.dealerName;
      this.totalRecordsDealer = totalDealerPages;
      totalDealerPages = this.calTotalPages(totalDealerPages);
      this.totalDealerPages = totalDealerPages;
      this.totalDealerOrders = new Array(totalDealerPages);
      console.log("dlrApprovalList ",this.dlrApprovalList);
      console.log('totalRecordsDealer',this.totalRecordsDealer);
      this.tableLoader = false;
    });
  }

  setShipToCode(shipToCode) {
   this.seldealerCode =shipToCode.shiptocnaCode;
   this.address =shipToCode.shiptocnaAddress2;
   this.city =shipToCode.shiptocnaCity;
   this.state =shipToCode.shiptocnaState;
   this.zipCode =shipToCode.shiptocnaZipCode;
  }

  selectedUploadedOrder: any[] = [];

   uploadDoc(id,rid){
    console.log('order id is:',id) 
    let data = { "orderNumber": id, "requestId": rid}
       
    this.orderApprovalService.getUploadedDocs(data).subscribe(data => {
    this.selectedUploadedOrder = data.data;

    $("#uploadDocApprovalModal").modal("show");
    console.log('data passed to approval modal: ',this.selectedUploadedOrder);
  });
   }
   onNavigate(params,event) {
    event.preventDefault();
     console.log(params);
     var pgUrl = window.location.host;
     var encodedFileName= encodeURIComponent(params.fileName);
     this.defaultUrlNew = this.util.getBaseUrl();
     var link =this.defaultUrlNew+'ordersearch/da?requestId='+params.requestId+'&orderNumber='+params.orderNumber+'&attachmentId='+params.attachmentId+'&fileIdentifierId='+params.fileIdentifierId+'&dataType='+params.dataType+'&fileName='+encodedFileName+'&schema='+params.schema;
      console.log('upload url is : ',link);
     window.open(link, "_blank");
   }
   openApprovalHistory(approvalId)
   {
    // alert("openModel");
    this.compensationHist = "";
    this.histLoaded = false;
    this.hideDetails = true; 
      this.orderApprovalService.getApprovalHist(approvalId).subscribe(data => {
       this.approvalHist = data.data;
       console.log('Approval History list',this.approvalHist.length);
       //console.log(JSON.stringify(this.csaOrderList));
    // this.totalconfigList=this.csaOrderGsd.length;
     },
     (error) =>
     {       
        console.log("Error: "+error);
       
     });
     
   }

   openCnaShipToCode(ittNumber,reqNumber,cnaShipcode)
   {
    // alert("openModel");
    this.approveStatus = "";
    this.approveErrorMsg  = "";
      this.orderApprovalService.getShipToCnaCode(ittNumber,reqNumber,cnaShipcode).subscribe(data => {
       this.shipToCodeList = data.data;
       console.log('getShipToCnaCode list',this.shipToCodeList.length);
       //console.log(JSON.stringify(this.csaOrderList));
    // this.totalconfigList=this.csaOrderGsd.length;
     },
     (error) =>
     {
       
        console.log("Error: "+error);
       
     });
     
   }
   changeDetailsFlag()
   {
    this.hideDetails = false;
   }
   openRequest()
   {
    this.approveErrorMsg  = "";
    this.approveStatus = "";
     this.comments = "";
     this.address = "";
     this.seldealerCode = this.defaultDealerCode; 
     //this.getDlrApprovalList(this.getDlrApprovalParams());
   }
   openApproveModalRequest(approvalObj)
   {
    this.proceedBtn = false;
    this.cnacodebtn =false;
    this.approveErrorMsg  = "";
    this.approveStatus = "";
     this.comments = "";
     this.address = "";
     this.cnaAddress ="";
     this.seldealerCode =  approvalObj.seldealerCode;//this.defaultDealerCode; 
     this.modalReqNum =approvalObj.requestNumber;
     this.modalIttNum =approvalObj.ittNumber;
     this.modalInventorySource =approvalObj.inventorySource;
     this.modalSourcedBy =approvalObj.sourcedBy;
     this.modalShipToCust =approvalObj.shipToCust;
     this.modalApprovalId =approvalObj.approvalId;    
     this.dealerEmail = approvalObj.approvalEmailAddress;
     this.modalSubmittedBy =approvalObj.submittedBy;
     this.modalApprovalStatus=approvalObj.approvalStatus;
     this.dealerName =approvalObj.dealerName;
     this.modalrequestId=approvalObj.requestId;
     this.hideDetails = true;
     if(this.modalIttNum == null || this.modalIttNum == "")
     {
      this.orderApprovalService.getDefaultShipToCnaCode(this.modalrequestId,this.modalApprovalId).subscribe(data => {
        this.approveErrorMsg  = "";
        
        if(data.status == 'SUCCESS')
        {
          //this.approveStatus= data.status;
        //this.defaultShiptoCnaCode = data.defCnaCode;
        this.defshipToCodeList = data.data;
        this.seldealerCode = data.defCnaCode;
        this.proceedBtn = false;
        for (var i = 0; i < this.defshipToCodeList.length; i++) {       
        //  this.dealerName=this.defshipToCodeList[i].userName; 
          this.address =  this.defshipToCodeList[i].shiptocnaAddress;
          this.state =  this.defshipToCodeList[i].shiptocnaState;
          this.city =  this.defshipToCodeList[i].shiptocnaCity;
          this.zipCode =  this.defshipToCodeList[i].shiptocnaZipCode;
          
         }
        }
        else
        {
       this.approveErrorMsg = "Some Error occured while retrieving default ship to cna code";
       this.proceedBtn = true;
        }
        console.log('getDefaultShipToCnaCode Approve Status',this.approveStatus);
        console.log('getDefaultShipToCnaCode Error',this.approveErrorMsg);
       
        //console.log(JSON.stringify(this.csaOrderList));
     // this.totalconfigList=this.csaOrderGsd.length;
      },
      (error) =>
      {
        this.approveStatus = "";
        this.approveErrorMsg  = "";
         console.log("Error: "+error);
        
      });
     }
      
   }
   sendReminderEmail(ittNumber,reqNumber,approvalId,actionType,actionBy,shipToCust)
   {
      this.orderApprovalService.sendReminderEmail(ittNumber,reqNumber,approvalId,actionType,actionBy,this.modalShipToCust,this.csaemailId,this.bounceemailId,this.dealerEmail,this.dealerName).subscribe(data => {
      this.approveErrorMsg  = "";
      
      if(data.status == 'Reminder Email Sent Successfully')
      this.approveStatus = data.status;
      else
      this.approveErrorMsg = data.status;
      console.log('proceedApproveRequest Approve Status',this.approveStatus);
      console.log('proceedApproveRequest Error',this.approveErrorMsg);
      this.proceedBtn = true;
      //console.log(JSON.stringify(this.csaOrderList));
   // this.totalconfigList=this.csaOrderGsd.length;
    },
    (error) =>
    {
      this.approveStatus = "";
      this.approveErrorMsg  = "";
       console.log("Error: "+error);
      
    });
   }
proceedApproveRequest(ittNumber,reqNumber,approvalId,actionType,actionBy,seldealerCode,comments,shipToCust,address,city,state,zipcode)
{
  this.approveErrorMsg  = "";
  this.approveStatus = "";
  this.cnaAddress = "";
    if((actionType == 'APPROVED' && actionBy == 'CSA' && this.modalInventorySource != 'CSA Inventory') || (actionType == 'APPROVED' &&  actionBy == 'DEALER' && this.modalSourcedBy == 'CSA') )
    {
     if(seldealerCode != "")
     {
      this.cnaAddress = this.address+","+this.city+","+this.state+","+this.zipCode;
  this.orderApprovalService.approveRequest(ittNumber,reqNumber,approvalId,actionType,actionBy,seldealerCode,comments,this.csaemailId,this.bounceemailId,this.dealerEmail,this.modalShipToCust,this.address,this.city,this.state,this.zipCode,this.dealerName,this.modalrequestId,this.cnaAddress).subscribe(data => {
    this.approveErrorMsg  = "";
    //this.approveStatus = data.status;
    if(data.status.indexOf('Error') != -1)
    {
      this.approveErrorMsg = data.status;
      console.log('proceedApproveRequest',this.approveStatus);
      this.proceedBtn = false;
      this.cnacodebtn =false;
    }
    else
    {
    this.approveStatus = data.status;
    console.log('proceedApproveRequest',this.approveStatus);
    this.proceedBtn = true;
    this.cnacodebtn =true;
    }
    //console.log(JSON.stringify(this.csaOrderList));
 // this.totalconfigList=this.csaOrderGsd.length;
  },
  (error) =>
  {
    this.approveStatus = "";
    this.approveErrorMsg  = "";
     console.log("Error: "+error);
    
  });
   }
   
   else  
   {
     this.approveErrorMsg ="Please select CNA Ship To Code"; 
   }

   
  }
      else
    {
      this.approveStatus = "";
    this.approveErrorMsg  = "";
      this.orderApprovalService.approveRequest(ittNumber,reqNumber,approvalId,actionType,actionBy,"",comments,this.csaemailId,this.bounceemailId,this.dealerEmail,this.modalShipToCust,this.address,this.city,this.state,this.zipCode,this.dealerName,this.modalrequestId, this.cnaAddress).subscribe(data => {
        this.approveErrorMsg  = "";
        if(data.status.indexOf('Error')!= -1)
    {
      this.approveErrorMsg = data.status;
      console.log('proceedApproveRequest',this.approveStatus);
      this.proceedBtn = false;
      this.cnacodebtn =false;
    }
    else
    {
    this.approveStatus = data.status;
    console.log('proceedApproveRequest',this.approveStatus);
    this.proceedBtn = true;
    this.cnacodebtn =true;
    }
        //console.log(JSON.stringify(this.csaOrderList));
     // this.totalconfigList=this.csaOrderGsd.length;
        // if(actionType == 'CANCELLED')
        // {
        //   this.approveErrorMsg  = "";
        //   this.approveCancelStatus = data.status;
        //  // $("#cancel-modal-123").modal("hide");
        //   //$("#csacancel-modal-123").modal("hide");
        //   this.getDlrApprovalList(this.getDlrApprovalParams());
        // }
      },
      (error) =>
      {
        this.approveStatus = "";
        this.approveErrorMsg  = "";
         console.log("Error: "+error);
        
      });
    }

}
   openApprNotes(ittNumber,reqNumber,approvalId)
   {
    // alert("openModel");
    this.hideDetails = true; 
      this.orderApprovalService.getApprovalNotes(ittNumber,reqNumber,approvalId).subscribe(data => {
       this.approvNotesList = data.data;
       console.log('getApprovalNotes list',this.approvNotesList.length);
       //console.log(JSON.stringify(this.csaOrderList));
    // this.totalconfigList=this.csaOrderGsd.length;
     },
     (error) =>
     {
       
        console.log("Error: "+error);
       
     });
     
   }

  pageLoad() {
    // alert("page Load");
$("#page-loader-overlay")
    .delay(800)
    .fadeOut();
$("#delay-loader")
    .delay(1000)
    .fadeOut();
   
   }

   clearButton() {
    this.orderNum = "";
    this.reqNum = "";
    this.ittNum = "";
    this.status = '';
    this.shipToCust = "";
  }
  changeDSelectAll() {
    if(this.setDSelectAll) {
     this.uploadedDocs = true;
     this.setDITT = true;
     this.setDStatus = true;
     this.setDOrder = true; 
     this.setDReq = true;
     this.setDRefPo = true;
     this.setDInstallBy = true;
     this.setSubmittedBy = true;
     this.setDSubmitDate = true;
     this.setDResDate = true;
     this.setDResComments = true;
     this.setDShipCust =true;
     this.setApprovalHist =true;
    }else {

     this.uploadedDocs = false;
     this.setDITT = false;
     this.setDStatus = false;
     this.setDOrder = false; 
     this.setDReq = false;
     this.setDRefPo = false;
     this.setDInstallBy = false;
     this.setSubmittedBy = false;
     this.setDSubmitDate = false;
     this.setDResDate = false;
     this.setDResComments = false;
     this.setDShipCust =false;
     this.setApprovalHist =false;


    } 
  }
   

  setDealersCSS() {
    $("#side-nav-logo-button-wrapper,#mobile-menu-modal-header").css("background", " url('assets/img/dealers-bg.jpg')");
    $(".site-logo").attr("src", "assets/img/ep-dealers-logo.png");
    $("#main-top-nav-wrapper-clone").css("background", "#7395AE");
    $("#main-side-nav,#mobile-menu-modal-body").css("background", "#5d5c61");
    $(".main-top-navbar").css("border-top", "1px solid #fff");
    $(".fa-ellipsis-v").css("color", "#396f96");
    $("#main-side-nav .inner ul li.side-menu-li,#mobile-menu-links ul li.mobile-menu-li,.single-link-side-menu-li,.single-link-mobile-menu-li").css({
        "border": "1px solid #1b6d85",
        "background-color": "#7395ae",
        "color": "#fff"
    });

    $(".side-less-icons-wrapper").hover(function () {
        $(".side-less-icons-wrapper .side-more-icons").css("color", "rgb(38, 154, 188)");
    }, function () {
        $(".side-less-icons-wrapper .side-more-icons").css("color", "#fff");
    });
    $(".side-more-icons-wrapper").hover(function () {
        $(".side-more-icons-wrapper .side-more-icons").css("color", "rgb(38, 154, 188)");
    }, function () {
        $(".side-more-icons-wrapper .side-more-icons").css("color", "#fff");
    });
    $(".side-menu-button-wrapper i").hover(function () {
        $(this).css({
            "color": "rgb(38, 154, 188)",
            "border": "3px solid rgb(38, 154, 188)"
        });
    }, function () {
        $(this).css({
            "color": "#fff",
            "border": "3px solid #fff"
        });
    });
    $(".tooltip .tooltip-inner").css("border-left", "7px solid rgb(38, 154, 188)");
    $(".group-switcher").css({
        "border": "1px solid #2aabd1",
        "background-color": "#2286a3",
        "pointer-events": "none"
    });
    $(".side-menu-wrapper-inner").css("border-top", "1px solid #777");
    $(".main-approvalcsa-content-wrapper,.csa-page-header-title").hide();
    $(".main-dealers-content-wrapper,.dealer-page-header-title").fadeIn();
    $(".btn-primary").css({
        "border-color": "#2aabd1",
        "background-color": "#2286a3",
        "color": "#fff"
    });
    $(".result-table tr td i").css({
        "color": "#2aabd1"
    });
    $("html,body").css({
        "background-color": "#ddd"
    });
    $(".main-top-navbar-left-content,.main-top-navbar-links,.group-switcher-inner").css({
        "color": "#fff"
    });
    $(".result-table tr th").css({
        "background-color": "#7395ae",
        "color": "#fff"
    });

    $('.sm-switcher .group-switcher-inner').css("margin-left", "-55px");
    $('.md-switcher .group-switcher-inner').css("margin-left", "-60px");
    $(".compensation-work-pay-inner-wrapper").css({
        "background-color": "#7395AE",
        "color": "#fff"
    });
    $(".CSA-tab-active").css({
        "background-color": "#7395AE",
        "color": "#fff"
    });

    //setCSA = 1;

}

setCSACSS() {
    $("#side-nav-logo-button-wrapper,#mobile-menu-modal-header").css("background", " url('assets/img/csa-bg.jpg')");
    $(".site-logo").attr("src", "assets/img/ep-csa-logo.png");
    $("#main-top-nav-wrapper-clone").css("background", "#555");
    $("#main-side-nav,#mobile-menu-modal-body").css("background", "#333");
    $(".main-top-navbar").css("border-top", "1px solid rgb(186, 179, 171)");
    $(".fa-ellipsis-v").css("color", "rgb(204, 204, 204)");
    $("#main-side-nav .inner ul li.side-menu-li,#mobile-menu-links ul li.mobile-menu-li,.single-link-side-menu-li,.single-link-mobile-menu-li").css({
        "border": "1px solid #333",
        "background-color": "#dbd5ce",
        "color": "#59534d"
    });

    $(".side-less-icons-wrapper").hover(function () {
        $(".side-less-icons-wrapper .side-more-icons").css("color", "#dbd5ce");
    }, function () {
        $(".side-less-icons-wrapper .side-more-icons").css("color", "#fff");
    });
    $(".side-more-icons-wrapper").hover(function () {
        $(".side-more-icons-wrapper .side-more-icons").css("color", "#dbd5ce");
    }, function () {
        $(".side-more-icons-wrapper .side-more-icons").css("color", "#fff");
    });

    $(".side-menu-button-wrapper i").hover(function () {
        $(this).css({
            "color": "#dbd5ce",
            "border": "3px solid #dbd5ce"
        });
    }, function () {
        $(this).css({
            "color": "#fff",
            "border": "3px solid #fff"
        });
    });
    $(".tooltip .tooltip-inner").css("border-left", "7px solid #dbd5ce");
    $(".group-switcher").css({
        "border": "1px solid #969089",
        "background-color": "#999",
        "pointer-events": "none"
    });
    $(".side-menu-wrapper-inner").css("border-top", "1px solid #ccc");
    $(".main-dealers-content-wrapper,.dealer-page-header-title").hide();
    $(".main-approvalcsa-content-wrapper,.csa-page-header-title").fadeIn();
    $(".btn-primary").css({
        "border-color": "#969089",
        "background-color": "#999",
        "color": "#fff"
    });
    $(".result-table tr td i").css({
        "color": "#333"
    });
    $("html,body").css({
        "background-color": "#ebe7e4"
    });
    $(".main-top-navbar-left-content,.main-top-navbar-links").css({
        "color": "#fff"
    });
    $(".group-switcher-inner").css({
        "color": "#fff"
    });
    $(".result-table tr th").css({
        "background-color": "#333",
        "color": "#fff"
    });
    $('.sm-switcher .group-switcher-inner').css("margin-left", "5px");
    $('.md-switcher .group-switcher-inner').css("margin-left", "0");
    $(".compensation-work-pay-inner-wrapper").css({
        "background-color": "#333",
        "color": "#fff"
    });
    $(".CSA-tab-active").css({
        "background-color": "#333",
        "color": "#fff"
    });

    //setCSA = 0;
}

  calTotalPages (pages){
    let totalPages = pages/10;
    let roundedTotalPages = Math.round(totalPages);
  
    
    if (totalPages > roundedTotalPages) {
      totalPages = roundedTotalPages + 1;
    }else {
      totalPages = roundedTotalPages;
    }
  
    console.log('totalPages',totalPages);
    return totalPages;
   }
   formatCurrency(sNum: any): string {
    let fmtNum: string = '';
    fmtNum = sNum + "";
    try {
      fmtNum = fmtNum.replace(/\$/g, "");
      fmtNum = fmtNum.replace(/\,/g, "");

      if (fmtNum) {

        let formattedVal = this.currencyPipe.transform(fmtNum, 'USD', 'symbol', '1.2-2');
        fmtNum = formattedVal;

      }
    } catch (error) {
      fmtNum = sNum;
    }

    return fmtNum;
  }
  formatCurrencyCpc(sNum: any): string {
    let fmtNum: string = '';
    fmtNum = sNum + "";
    try {
      fmtNum = fmtNum.replace(/\$/g, "");
      fmtNum = fmtNum.replace(/\,/g, "");

      if (fmtNum) {

        let formattedVal = this.currencyPipe.transform(fmtNum, 'USD', 'symbol', '1.2-5');
        fmtNum = formattedVal;

      }
    } catch (error) {
      fmtNum = sNum;
    }

    return fmtNum;
  }
   getRequestJson(){
    let reqParam = { "requestId": this.requestId};
  	this.newOrderServce.getRequestJson(reqParam).subscribe(
  	data => {
  		if(data.reqJson){
	  		this.newOrder = JSON.parse(data.reqJson);	  	
        console.log("newOrder JSON: " + data.reqJson);
        if (this.newOrder.linesList.length > 0) {
		  		for (let i = 0; i < this.newOrder.linesList.length,i< this.counterList.length; i++) {
			     // this.newOrder.linesList[i].unitPrice = this.formatCurrency(this.newOrder.linesList[i].unitPrice);
            //this.newOrder.linesList[i].totalPrice = this.formatCurrency(this.newOrder.linesList[i].totalPrice);
            if(this.newOrder.linesList[i].serviceModel == this.counterList[i].serviceModel)
            {
            this.newOrder.linesList[i].findersFee = this.counterList[i].findersFee_l;
            this.newOrder.linesList[i].installationFee = this.counterList[i].installationFee_l;

            this.newOrder.linesList[i].unitPrice = this.formatCurrency(this.newOrder.linesList[i].unitPrice);
			      this.newOrder.linesList[i].totalPrice = this.formatCurrency(this.newOrder.linesList[i].totalPrice);
			      this.newOrder.linesList[i].findersFee = this.formatCurrency(this.newOrder.linesList[i].findersFee);
			      this.newOrder.linesList[i].installationFee = this.formatCurrency(this.newOrder.linesList[i].installationFee);
            }
			    }
	  		}
	  		
	  		if (this.newOrder.maintList.length > 0) {
		  		for (let i = 0; i < this.newOrder.maintList.length; i++) {
			      this.newOrder.maintList[i].basePrice = this.formatCurrency(this.newOrder.maintList[i].basePrice);
			      this.newOrder.maintList[i].bwCpc = this.formatCurrencyCpc(this.newOrder.maintList[i].bwCpc);
			      this.newOrder.maintList[i].clrCpc = this.formatCurrencyCpc(this.newOrder.maintList[i].clrCpc);
			    }
	  		}
	  		
	  		if (this.newOrder.pickupReturnList.length > 0) {
		  		for (let i = 0; i < this.newOrder.pickupReturnList.length; i++) {
			      this.newOrder.pickupReturnList[i].removalComp = this.formatCurrency(this.newOrder.pickupReturnList[i].removalComp);
			    }
	  		}
  		}else{
  			//this.loadFlag = false;
  		} 
  	});
  }



}