import { Component, OnInit, AfterViewInit, OnDestroy  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { OrderQueryService } from '../../service/order-mgmt-service/order-query.service';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';
import { Util } from 'src/app/app.util';
import { SetScreenService } from 'src/app/service/common-service/set-screen.service';
import { Subscription } from 'rxjs';
import { bypassSanitizationTrustResourceUrl } from '@angular/core/src/sanitization/bypass';

declare var $: any;

@Component({
  selector: 'app-order-query',
  templateUrl: './order-query.component.html',
  styleUrls: ['./order-query.component.css']
})
export class OrderQueryComponent implements OnInit, AfterViewInit, OnDestroy  {

	 loggedUserName=''; 
   loggedUserFullName='';
   status = [
    //{ value: 1, text: 'Status' },
    // { value: 1, text: 'Booked' },
    // { value: 2, text: 'Closed' },
    // { value: 3, text: 'Cancelled' },
    // { value: 4, text: 'Pending Approval' }
    //{ value: 5, text: 'Rejected' }

    { value: 1, text: 'In Process' },
    { value: 2, text: 'Pending Shipment' },
    { value: 3, text: 'Pending Delivery' },
    { value: 4, text: 'Pending Return' },
    { value: 5, text: 'Pending Documentation'},
    { value: 6, text: 'Pending Approval'},
    { value: 7, text: 'Closed'}

  ];
  rma = [
    //{ value: 1, text: 'RMA' },
    { value: 'Y', text: 'Yes' },
    { value: 'N', text: 'No' }

  ];
  orderType = [
    //{ value: 1, text: 'Order Type' },
    { value: 'ALL', text: 'All' },
    { value: 'dealer', text: 'My Orders' },
    { value: 'csa', text: 'My CSA Orders'}


  ];
   orderNum:  any = "";
   selectedStatus = '';
   customerName: string ="";
   completionDate: string;
   orderDate = "";
   selectedOrderDate: any;
   selectedCompletionDate: any;
   selectedCity = "";
   selectedZip="";
   selectedRma = '';
   selectedOrderType = '';
   stateList: any[] = [];
   selectedState = '';
   matDatepicker;
   pageNum = 1;
   totalRecordsDealer;
   totalDealerOrders: Array<number>;
   totalDealerPagers;
   totalRecordsCsa;
   totalCsaOrders: Array<number>;
   totalCsaPagers;
   selectedSortBy;
   selectedSortOrder;
   uploadedDocs = true;
   setDITT = true;
   setDStatus = true;
   setDOrder = true; 
   setDTotalComp = true;
   setDDo = true;
   setDRma = true;
   setDCn = true;
   setDSta = true;
   setDStc = true;
   setDStp = true;
   setDBta= true;
   setDBtc = true;
   setDBtp = true;
   setDCnaCode = true;
   setDCnaAddress = true;
   uploadedDocsC = true;
   setCRef = true;
   setCStatus = true;
   setCOrder = true; 
   setCTotalComp = true;
   setCDo = true;
   setCRma = true;
   setCCn = true;
   setCSta = true;
   setCStc = true;
   setCStp = true;
   setCBta= true;
   setCBtc = true;
   setCBtp = true;
   setCCnaCode = true;
   setCCnaAddress = true;
   setDSelectAll = true;
   setCSelectAll = true;
   recPerPage = 10;
   filterClosedOrders= false;
   setfilterClosedOrders = 'Y';
   defaultUrlNew: any;
   dealerName = "";  
   tableLoader = true;
   referenceNum = '';
   requestNumber = '';
   setCRequest = true;
   isAdmin: any; 
   selectedQpDealerName = "";
   setDQpDealerName = true;
   setCQpDealerName = true;
   toOrderDate = "";
   selectedToOrderDate: any;
  dateErrMsg: string ="";
  validateDateError: boolean = true;
  dateErrorFlag: boolean =false;
  commonErrMsg: string  = '';
  fileMsg: string= '';
  fileToUpload: File = null;
ord: any;
fileLineId: any;
lineId: any;
disableProp: any;
warningFileMsg: string;
warningFileDocMsg: string;
desc: string;
fileName: any;
attachId: any;
valFiletype: boolean;
valFileSize :boolean;
  headerId: string;
  id: string;
  orderNumber: any;
  dealerCsaObj: any;
  source: string= '';
  masterOrderLineSelected: boolean =false;
  orderLineCheckedList: any[];
  conCatLineNumber: string= '';
  parentId: any;
  docTypebtn: boolean = false;
  requestId: string= '';
  searchInstall:  string= '';
  searchOrderLine:  string= '';
  searchOrderItem:  string= '';
  searchOrderServiceModel:  string= '';
  searchOrderDescription:  string= '';
  searchSerialNumber:  string= '';
  searchRequestNumber: string= '';
  orderLineNumberValue: string= '';
  checkBoxError: string;
  checkBoxErrorMsg: string= '';
  selectedDocType = '';
  commonFileErrMsg: string = '';
  searchOrderDesc: string;
  searchOrderSerNum: string;
  orderLineHeaderId: string = '';
  orderLineRequestNumber: string = '';
  shipToCodeList: any;
  selectedCnaShiptoCode: string= '';
  shiptosourcedBy: string = '';
  address: any;
  city: any;
  state: any;
  zipCode: any;
  shiptocnaCode: string;
  shiptocnaAddress: string;
  shiptocnaAddress2: string;
  shiptocnaState: string;
  shiptocnaCity: string;
  shiptocnaZipCode: string;
  picker: Date;
  clearButton() {    
    this.orderNum = "";
    this.requestNumber = "";
    this.selectedStatus = '';
    this.customerName = "";
    this.completionDate = "";
    this.orderDate = "";
    this.selectedOrderDate = "";
    this.selectedCompletionDate = "";
    this.selectedCity = "";
    this.selectedZip="";
    this.selectedRma = '';
    this.selectedOrderType = '';
    this.selectedState = '';
    this.referenceNum = '';
    this.filterClosedOrders= false;
    this.selectedQpDealerName = "";
    this.toOrderDate = "";
    this.selectedToOrderDate = "";
    this.dateErrMsg ="";
    this.selectedCnaShiptoCode ="";
  }


   changeDSelectAll() {
     if(this.setDSelectAll) {
      this.uploadedDocs = true;
      this.setDITT = true;
      this.setDStatus = true;
      this.setDOrder = true; 
      this.setDTotalComp = true;
      this.setDDo = true;
      this.setDRma = true;
      this.setDCn = true;
      this.setDSta = true;
      this.setDStc = true;
      this.setDStp = true;
      this.setDBta= true;
      this.setDBtc = true;
      this.setDBtp = true;
      this.setDQpDealerName =true;
      this.setDCnaCode = true;
      this.setDCnaAddress = true;
     }else {

      this.uploadedDocs = false;
      this.setDITT = false;
      this.setDStatus = false;
      this.setDOrder = false; 
      this.setDTotalComp = false;
      this.setDDo = false;
      this.setDRma = false;
      this.setDCn = false;
      this.setDSta = false;
      this.setDStc = false;
      this.setDStp = false;
      this.setDBta= false;
      this.setDBtc = false;
      this.setDBtp = false;
      this.setDQpDealerName =false;
      this.setDCnaCode = false;
      this.setDCnaAddress = false;
     } 
   }
    
   changeCSelectAll() {
     if(this.setCSelectAll) {
      this.uploadedDocsC = true;
      this.setCRef = true;
      this.setCStatus = true;
      this.setCRequest = true;
      this.setCOrder = true; 
      this.setCTotalComp = true;
      this.setCDo = true;
      this.setCRma = true;
      this.setCCn = true;
      this.setCSta = true;
      this.setCStc = true;
      this.setCStp = true;
      this.setCBta= true;
      this.setCBtc = true;
      this.setCBtp = true;
      this.setCQpDealerName =true;
      this.setCCnaCode = true;
      this.setCCnaAddress = true;
     }else {

      this.uploadedDocsC = false;
      this.setCRef = false;
      this.setCStatus = false;
      this.setCRequest = false;
      this.setCOrder = false;
      this.setCTotalComp = false;
      this.setCDo = false;
      this.setCRma = false;
      this.setCCn = false;
      this.setCSta = false;
      this.setCStc = false;
      this.setCStp = false;
      this.setCBta= false;
      this.setCBtc = false;
      this.setCBtp = false;
      this.setCQpDealerName =false;
      this.setCCnaCode = false;
      this.setCCnaAddress = false;
     } 
   }
 

   setFilteredClosedOrders() {
     if(this.filterClosedOrders){
      this.setfilterClosedOrders = 'N';
     }else {
      this.setfilterClosedOrders = 'Y';
     }
   }

   changeEvent(event,date){ 
    // Return date object
    event = new Date(event);
    //alert("Before this.picker:"+this.picker);
    let dd = event.getDate();
    let mm = event.getMonth() + 1; //January is 0!
    let yyyy = event.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    if (date=='od'){
    this.selectedOrderDate =  mm + '/' + dd + '/' + yyyy ; 
     this.orderDate = this.selectedOrderDate ;
     this.picker = new Date(this.selectedOrderDate);
    // alert("Aftwer this.picker:"+this.picker);
    }
    if (date=='cd'){
      this.selectedCompletionDate =  mm + '/' + dd + '/' + yyyy ; 
      }
      if (date=='td'){
        this.selectedToOrderDate =  mm + '/' + dd + '/' + yyyy ; 
        this.toOrderDate= this.selectedOrderDate ;
        }
      //  this.orderDate = event.value ; 
    console.log(event);
    console.log('dd',dd);
    return;
  }

  setSortOrder(sortBy,sortOrder,type) {
    this.selectedSortBy = sortBy;
    this.selectedSortOrder = sortOrder;
    if(type=="dealer"){
    this.searchOrderList();    
    }else {
      this.searchCsaList();
    }

  }
  valueChange(event,type)
  {
    if(type == 'fd' &&  (this.toOrderDate == "" || this.toOrderDate == null))
    this.toOrderDate = event.value;
    if(type == 'td' &&  (this.orderDate == "" || this.orderDate == null))
    this.orderDate = event.value;
  }
validateDate(fromDate,toDate)
{
    this.dateErrMsg ="";
    if((fromDate == "" || fromDate == null) && (toDate == "" || toDate == null))
    {
      this.validateDateError =true;
       return true;
    }
    else{

   if((fromDate == "" || fromDate == null) && (toDate != "" || toDate != null))
   {
   //  console.log("toDate Null");
   // this.toOrderDate = fromDate;
     this.dateErrMsg= "Please enter From Date";
    this.validateDateError =false;
    return false;
   }
     
   else if((toDate == "" || toDate == null) && (fromDate != "" || fromDate != null))
   {
   // console.log("fromDate Null");
   this.dateErrMsg= "Please enter To Date";
   this.validateDateError =false;
   return false;
   }
   else if((fromDate != "" || fromDate != null) && (toDate != "" || toDate != null))
   {
        if(toDate < fromDate)
   {
    this.dateErrMsg= "To Date cannot be earlier than From Date";
    this.validateDateError =false;
    return false;
   }
   else
   {
    this.validateDateError =true;
    return true;
   }
  }
}
  //  else
  //  {
  //   this.validateDateError =true;
  //   return true;
  //  }
 
}
  getDealerSearchParam () {
   
    if(this.orderDate) {
      this.changeEvent(this.orderDate,'od');
    }else{
      this.selectedOrderDate = "";
    }
    if(this.toOrderDate) {
      this.changeEvent(this.toOrderDate,'td');
    }else{
      this.selectedToOrderDate = "";
    }
    this.dateErrMsg ="";
    //alert("getDealerSearchParam:"+this.selectedStatus);
  return {
      
      "orderNumber": this.orderNum.trim(),
      "orderStatus": this.selectedStatus,
      "orderedDate": this.selectedOrderDate.trim(),
      "invoiceDate": "",
      "customerName": this.customerName.trim(),
      "shipToState": this.selectedState,
      "filterClosed":this.setfilterClosedOrders,
      "shipToCity": this.selectedCity.trim(),
      "shipToPostal": this.selectedZip.trim(),
      "orderType": "DEALER",
      "rma": this.selectedRma,
      "pageNum": this.pageNum,
      "recPerPage":this.recPerPage,
      "sortBy": this.selectedSortBy,
      "sortOrder": this.selectedSortOrder,
      "qpDealerName": this.selectedQpDealerName.trim(),
      "toOrderedDate": this.selectedToOrderDate.trim(),
      "cnaShiptoCode": this.selectedCnaShiptoCode.trim(),
    }
    
  }

  getCsaSearchParam () {
    
    if(this.orderDate) {
      this.changeEvent(this.orderDate,'od');
    }else{
      this.selectedOrderDate = "";
    }
    if(this.toOrderDate) {
      this.changeEvent(this.toOrderDate,'td');
    }else{
      this.selectedToOrderDate = "";
    }
    this.dateErrMsg ="";
    return {
      "referenceNumber":this.referenceNum.trim(),
      "requestNumber":this.requestNumber.trim(),
      "orderNumber": this.orderNum.trim(),
      "orderStatus": this.selectedStatus,
      "orderedDate": this.selectedOrderDate.trim(),
      "completiondate": "",
      "orderType": "CSA",
      "rma": this.selectedRma,
      "customerName": this.customerName.trim(),
      "shipToState": this.selectedState,
      "filterClosed":this.setfilterClosedOrders,
      "shipToCity": this.selectedCity.trim(),
      "shipToPostal": this.selectedZip.trim(),
      "pageNum": this.pageNum,
      "recPerPage":this.recPerPage,
      "sortBy": this.selectedSortBy,
      "sortOrder": this.selectedSortOrder,
      "qpDealerName": this.selectedQpDealerName.trim(),
      "toOrderedDate": this.selectedToOrderDate.trim(),
      "cnaShiptoCode": this.selectedCnaShiptoCode.trim(),

}
    }

   searchOrderList(){
    this.searchDealerOrder(this.getDealerSearchParam());
    // console.log('orderNum', this.orderNum);
    //  console.log('selectedStatus', this.selectedStatus);
    //  console.log('customerName', this.customerName);
    //  console.log('selectedRma', this.selectedRma);
    //  console.log('selectedOrderType', this.selectedOrderType);
    //  console.log('matDatepicker', this.matDatepicker);

    //this.changeEvent(this.orderDate,'od');
    //this.changeEvent(this.completionDate,'cd');

      console.log('orderDate', this.orderDate);
      console.log('selectedOrderDate', this.selectedOrderDate);
    //  console.log('selectedCompletionDate', this.selectedCompletionDate);

   }

   searchOrderListBtn(){
    this.setFilteredClosedOrders();
    this.pageNum = 1;
    if(this.validateDate(this.orderDate,this.toOrderDate)== true)
     {
        this.searchDealerOrder(this.getDealerSearchParam());
        this.loginAuthenticationService.retainDealerOrderInquirySearchParams(this.getDealerSearchParam(),this.orderDate,this.toOrderDate);
     }

   }

   searchCsaList(){
    this.searchCsaOrder(this.getCsaSearchParam());
    // console.log('orderNum', this.orderNum);
    //  console.log('selectedStatus', this.selectedStatus);
    //  console.log('customerName', this.customerName);
    //  console.log('selectedRma', this.selectedRma);
    //  console.log('selectedOrderType', this.selectedOrderType);
    //  console.log('matDatepicker', this.matDatepicker);

    //this.changeEvent(this.orderDate,'od');
    //this.changeEvent(this.completionDate,'cd');

    //  console.log('orderDate', this.orderDate);
    //  console.log('selectedOrderDate', this.selectedOrderDate);
    //  console.log('selectedCompletionDate', this.selectedCompletionDate);

   }

   searchCsaListBtn(){
    this.setFilteredClosedOrders();
    this.pageNum = 1;
    if(this.validateDate(this.orderDate,this.toOrderDate)== true)
     {
    this.searchCsaOrder(this.getCsaSearchParam());
    this.loginAuthenticationService.retainCsaOrderInquirySearchParams(this.getCsaSearchParam(),this.orderDate,this.toOrderDate);
     }
    
   }


//    setDealersCSS() {
//     $("#side-nav-logo-button-wrapper,#mobile-menu-modal-header").css("background", " url('assets/img/dealers-bg.jpg')");
//     $(".site-logo").attr("src", "assets/img/ep-dealers-logo.png");
//     $(".csa-page-header-title").hide();
//     //$(".csa-page-header-title").show();
//      $(".group-switcher").css({ "border": "1px solid #2aabd1", "background-color": "#2286a3", "pointer-events": "auto" });

//        $("#main-top-nav-wrapper-clone").css("background", "#7395AE");
//     $("#main-side-nav,#mobile-menu-modal-body").css("background", "#5d5c61");
//     $(".main-top-navbar").css("border-top", "1px solid #fff");
//     $(".fa-ellipsis-v").css("color", "#396f96");
//     $("#main-side-nav .inner ul li.side-menu-li,#mobile-menu-links ul li.mobile-menu-li,.single-link-side-menu-li,.single-link-mobile-menu-li").css({ "border": "1px solid #1b6d85", "background-color": "#7395ae", "color": "#fff" });

  
//         $(".side-less-icons-wrapper .side-more-icons").css("color", "rgb(38, 154, 188)");

//         $(".side-less-icons-wrapper .side-more-icons").css("color", "#fff");

//     $(".side-more-icons-wrapper").hover(() =>{
//         $(".side-more-icons-wrapper .side-more-icons").css("color", "rgb(38, 154, 188)");
//     }, () =>{
//         $(".side-more-icons-wrapper .side-more-icons").css("color", "#fff");
//     });
//     $(".side-menu-button-wrapper i").hover(() => {
//         $(this).css({ "color": "rgb(38, 154, 188)", "border": "3px solid rgb(38, 154, 188)" });
//     }, () =>{
//         $(this).css({ "color": "#fff", "border": "3px solid #fff" });
//     });
//     $(".tooltip .tooltip-inner").css("border-left", "7px solid rgb(38, 154, 188)");
//     $(".side-menu-wrapper-inner").css("border-top", "1px solid #777");
//     //$(".main-csa-content-wrapper,.csa-page-header-title").show();
//     $(".main-dealers-content-wrapper,.dealer-page-header-title").fadeIn();
//     $(".btn-primary").css({ "border-color": "#2aabd1", "background-color": "#2286a3", "color": "#fff" });
//     $(".result-table tr td i").css({ "color": "#2aabd1", "border": "solid 1px #2aabd1" });
//     $("html,body").css({ "background-color": "#ddd" });
//     $(".main-top-navbar-left-content,.main-top-navbar-links,.group-switcher-inner").css({ "color": "#fff" });
//     $(".result-table tr th").css({ "background-color": "#7395ae" });

//     $('.sm-switcher .group-switcher-inner').css("margin-left", "-55px");
//     $('.md-switcher .group-switcher-inner').css("margin-left", "-60px");

// }

// setCSACSS() {
//   $("#side-nav-logo-button-wrapper,#mobile-menu-modal-header").css("background", " url('assets/img/csa-bg.jpg')");
//   $(".site-logo").attr("src", "assets/img/ep-csa-logo.png");
//   $("#main-top-nav-wrapper-clone").css("background", "#555");
//   $("#main-side-nav,#mobile-menu-modal-body").css("background", "#333");
//   $(".main-top-navbar").css("border-top", "1px solid rgb(186, 179, 171)");
//   $(".fa-ellipsis-v").css("color", "rgb(204, 204, 204)");
//   $("#main-side-nav .inner ul li.side-menu-li,#mobile-menu-links ul li.mobile-menu-li,.single-link-side-menu-li,.single-link-mobile-menu-li").css({ "border": "1px solid #333", "background-color": "#dbd5ce", "color": "#59534d" });

//   $(".side-less-icons-wrapper").hover(() => {
//       $(".side-less-icons-wrapper .side-more-icons").css("color", "#dbd5ce");
//   }, () =>{
//       $(".side-less-icons-wrapper .side-more-icons").css("color", "#fff");
//   });
//   $(".side-more-icons-wrapper").hover(() => {
//       $(".side-more-icons-wrapper .side-more-icons").css("color", "#dbd5ce");
//   }, () => {
//       $(".side-more-icons-wrapper .side-more-icons").css("color", "#fff");
//   });

//   $(".side-menu-button-wrapper i").hover(() => {
//       $(this).css({ "color": "#dbd5ce", "border": "3px solid #dbd5ce" });
//   }, () =>{
//       $(this).css({ "color": "#fff", "border": "3px solid #fff" });
//   });
//   $(".tooltip .tooltip-inner").css("border-left", "7px solid #dbd5ce");
//   $(".group-switcher").css({ "border": "1px solid #969089", "background-color": "#999" });
//   $(".side-menu-wrapper-inner").css("border-top", "1px solid #ccc");
//   $(".main-dealers-content-wrapper,.dealer-page-header-title").hide();
//   $(".main-csa-content-wrapper,.csa-page-header-title").fadeIn();
//   $(".btn-primary").css({ "border-color": "#969089", "background-color": "#999", "color": "#fff" });
//   $(".result-table tr td i").css({ "color": "#333", "border": "solid 1px #dbd5ce" });
//   $("html,body").css({ "background-color": "#ebe7e4" });
//   $(".main-top-navbar-left-content,.main-top-navbar-links").css({ "color": "#fff" });
//   $(".group-switcher-inner").css({ "color": "#fff" });
//   $(".result-table tr th").css({ "background-color": "#333" });
//   $('.sm-switcher .group-switcher-inner').css("margin-left", "5px");
//   $('.md-switcher .group-switcher-inner').css("margin-left", "0");

// }




   selectedUploadedOrder: any[] = [];
   selectedOrderLine: any[] = [];
  //  uploadDoc(id,rid){
  //   console.log('order id is:',id) 
  //   let data = { "orderNumber": id, "requestId": rid}
       
  //   this.orderqueryService.getUploadedDocs(data).subscribe(data => {
  //   this.selectedUploadedOrder = data.data;

  //   $("#uploadDocModal").modal("show");
  //   console.log('data passed to modal: ',this.selectedUploadedOrder);
  // });
  //  }

   selectedRecoverOrder: any[] = [];

   recoverOrder(csa){

    this.selectedRecoverOrder = csa;
    $("#recoverOrderModal").modal("show");


   }

   onNavigate(params,event) {
	    event.preventDefault();
	     console.log(params);
	     var pgUrl = window.location.host;
	     var encodedFileName= encodeURIComponent(params.fileName);
	     //localhost:8070/csaE387epartners/dealer/da?requestId=&orderNumber=9000998&attachmentId=787252750&fileIdentifierId=164319598&dataType=SHORT_TEXT&fileName=test.txt
	     this.defaultUrlNew = this.util.getBaseUrl();
	     var link =this.defaultUrlNew+'ordersearch/da?requestId='+params.requestId+'&orderNumber='+params.orderNumber+'&attachmentId='+params.attachmentId+'&fileIdentifierId='+params.fileIdentifierId+'&dataType='+'FILE'+'&fileName='+encodedFileName+'&schema='+params.schema;
	      console.log('upload url is : ',link);
	     window.open(link, "_blank");
	   }


   csaTab: string ='';
   dealerTab: string ='CSA-tab-active dealerTabs';

   setScreen(num)
   {
  
     this.setScreenService.changeMyVar(num);
    
  }
 
  getDealerSearchFromSession() {
  
    this.orderNum = sessionStorage.getItem('orderNum') == null?"":sessionStorage.getItem('orderNum');
    this.selectedStatus= sessionStorage.getItem('selectedStatus')== null?"":sessionStorage.getItem('selectedStatus');
    //alert("this.selectedStatus:"+this.selectedStatus);
    this.selectedState= sessionStorage.getItem('selectedState')== null?"":sessionStorage.getItem('selectedState');
    this.selectedCity= sessionStorage.getItem('selectedCity') == null?"":sessionStorage.getItem('selectedCity');
    this.selectedZip= sessionStorage.getItem('selectedZip') == null ?"":sessionStorage.getItem('selectedZip');
    this.selectedCity= sessionStorage.getItem('selectedCity') == null?"":sessionStorage.getItem('selectedCity');
    this.customerName= sessionStorage.getItem('customerName') == null?"":sessionStorage.getItem('customerName');
    this.selectedRma= sessionStorage.getItem('selectedRma')== null?"":sessionStorage.getItem('selectedRma');
    this.setfilterClosedOrders= sessionStorage.getItem('setfilterClosedOrders');
    if( this.setfilterClosedOrders == "N")
    {
    this.filterClosedOrders = true;
    }
    else
    {
    this.filterClosedOrders = false;
    }
   // alert("fliterClosed:"+this.filterClosedOrders);
    this.selectedQpDealerName= sessionStorage.getItem('selectedQpDealerName') == null?"":sessionStorage.getItem('selectedQpDealerName');
    this.orderDate= sessionStorage.getItem('orderDate') == null ?"":sessionStorage.getItem('orderDate');
    this.toOrderDate= sessionStorage.getItem('toOrderDate') == null?"":sessionStorage.getItem('toOrderDate'); 
  }

  getCsaSearchFromSession() {
  
    this.referenceNum = sessionStorage.getItem('referenceNum') == null?"":sessionStorage.getItem('referenceNum');
    this.requestNumber = sessionStorage.getItem('requestNumber') == null?"":sessionStorage.getItem('requestNumber');
    this.orderNum = sessionStorage.getItem('orderNum') == null?"":sessionStorage.getItem('orderNum');
    this.selectedStatus= sessionStorage.getItem('selectedStatus')== null?"":sessionStorage.getItem('selectedStatus');
    //alert("this.selectedStatus:"+this.selectedStatus);
    this.selectedState= sessionStorage.getItem('selectedState')== null?"":sessionStorage.getItem('selectedState');
    this.selectedCity= sessionStorage.getItem('selectedCity') == null?"":sessionStorage.getItem('selectedCity');
    this.selectedZip= sessionStorage.getItem('selectedZip') == null ?"":sessionStorage.getItem('selectedZip');
    this.selectedCity= sessionStorage.getItem('selectedCity') == null?"":sessionStorage.getItem('selectedCity');
    this.customerName= sessionStorage.getItem('customerName') == null?"":sessionStorage.getItem('customerName');
    this.selectedRma= sessionStorage.getItem('selectedRma')== null?"":sessionStorage.getItem('selectedRma');
    this.setfilterClosedOrders= sessionStorage.getItem('setfilterClosedOrders');
    if( this.setfilterClosedOrders == "N")
    {
    this.filterClosedOrders = true;
    }
    else
    {
    this.filterClosedOrders = false;
    }
    this.selectedQpDealerName= sessionStorage.getItem('selectedQpDealerName') == null?"":sessionStorage.getItem('selectedQpDealerName');
     this.orderDate= sessionStorage.getItem('orderDate') == null ?"":sessionStorage.getItem('orderDate');
    this.toOrderDate= sessionStorage.getItem('toOrderDate') == null?"":sessionStorage.getItem('toOrderDate');
   
  }

   setTab(event:any){
     console.log(event);
     this.shiptosourcedBy ="";
    //alert("setTab selectedStatus null:"+this.selectedStatus);
     this.pageNum = 1;
     if(event == 'dealer'){
      this.getDealerSearchFromSession();
      this.pageNum = 1;
      this.dealerTab = 'CSA-tab-active dealerTabs';
      this.csaTab = '';
     // this.dateErrMsg ="";
      //this.clearButton();
     // this.selectedStatus = '';
      this.shiptosourcedBy ="CSA";
      this.searchDealerOrder(this.getDealerSearchParam());
      this.setScreenService.setDealersCSS();
     // this.setDealersCSS();

      //this.setPgLinks(10000,1,10,10);
     }else if (event == 'csa') {
      this.getCsaSearchFromSession();
      this.pageNum = 1;
      this.csaTab = 'CSA-tab-active';
      this.dealerTab = '';
     // this.dateErrMsg ="";
      //this.clearButton();
     // this.selectedStatus = '';
     this.shiptosourcedBy ="Dealer";
      this.searchCsaOrder(this.getCsaSearchParam());
      this.setScreenService.setCSACSS();
     // this.setCSACSS();
     }

   }

   setPage (param,i, event: any) {
     event.preventDefault();
     this.pageNum= i;
     //this.dateErrMsg ="";
     if(param=='dealer'){
      this.searchOrderList();
    }else{
    this.searchCsaList();
    }

   }

   setNextPage (param,event: any) {
    event.preventDefault();
    this.pageNum++;
    //this.dateErrMsg ="";
    if(param=='dealer'){
      this.searchOrderList();
    }else{
    this.searchCsaList();
    }


  }
  setPrevPage (param,event: any) {
    //this.dateErrMsg ="";
    event.preventDefault();
    if (this.pageNum > 1){
    this.pageNum--;
  }
  if(param=='dealer'){
    this.searchOrderList();
  }else{
  this.searchCsaList();
  }


  }

  setFirstPage (param:string,event: any) {
    event.preventDefault();
   // this.dateErrMsg ="";
    this.pageNum = 1;
    if(param=='dealer'){
      this.searchOrderList();
    }else{
    this.searchCsaList();
    }

  }

  setLastPage (param,i,event: any) {
    event.preventDefault();
    //this.dateErrMsg ="";
    this.pageNum = i;
    if(param=='dealer'){
      this.searchOrderList();
    }else{
    this.searchCsaList();
    }


  }

  dowloadDealer() {
    let totalDealerOrderList = [];
    //this.setfilterClosedOrders = 'N';
    const dPageNumber = this.pageNum;
    this.recPerPage = 1000000;
    this.pageNum = 1;
      this.orderqueryService.getOrderDealerList(this.getDealerSearchParam()).subscribe(data => {
      totalDealerOrderList = data.data;
      this.orderqueryService.jsonToExcel(totalDealerOrderList,'Dealer Orders', true);
      this.recPerPage = 10;
      this.pageNum = dPageNumber;
      //this.setfilterClosedOrders = 'Y';
     //this.setFilteredClosedOrders();
    
    });

    
  

  }

  dowloadCsa() { 
    let totalCsaOrderList = [];
    const cPageNumber = this.pageNum;
    //this.setfilterClosedOrders = 'N';
    this.recPerPage = 1000000;
    this.pageNum = 1;
      this.orderqueryService.getOrderCsaList(this.getCsaSearchParam()).subscribe(data => {
        totalCsaOrderList = data.data;
      this.orderqueryService.jsonToExcel(totalCsaOrderList,'CSA Orders', true)
      this.recPerPage = 10;
      this.pageNum = cPageNumber;
      //this.setfilterClosedOrders = 'Y';
     //this.setFilteredClosedOrders();

    });
    

  }
   
	  constructor(private route:ActivatedRoute,private loginAuthenticationService:LoginAuthenticationService, private orderqueryService: OrderQueryService,private util : Util, private titleService: Title,private setScreenService:SetScreenService,private routing:Router) { }

    csaOrderList: any[] = [];
    dealerOrderList: any[] = [];
    calTotalPages (pages){
      let totalPages = pages/10;
      let roundedTotalPages = Math.round(totalPages);

      
      if (totalPages > roundedTotalPages) {
        totalPages = roundedTotalPages + 1;
      }else {
        totalPages = roundedTotalPages;
      }

      console.log('totalPages',totalPages);
      return totalPages;
     }
    searchDealerOrder(params) {
      this.tableLoader = true; 

      this.orderqueryService.getOrderDealerList(params).subscribe(data => {
      this.dealerOrderList = data.data;
      let totalDealerPages = data.recCount;
      this.dealerName = data.dealerName;
      this.totalRecordsDealer = totalDealerPages;
      totalDealerPages = this.calTotalPages(totalDealerPages);
      this.totalDealerPagers = totalDealerPages;

      //this.totalDealerOrders = new Array(data['recCount']);
      this.totalDealerOrders = new Array(totalDealerPages);
      this.tableLoader = false; 
      //console.log('dealer order list',this.dealerOrderList);
      //console.log('totalDealerOrders',this.totalDealerOrders);
      //console.log('totalRecordsDealer ',this.totalRecordsDealer );
      //console.log(JSON.stringify(this.csaOrderList));
    
    });
  }


  searchCsaOrder(params) { 
    this.tableLoader = true; 
    this.orderqueryService.getOrderCsaList(params).subscribe(data => {
    this.csaOrderList = data.data;
    let totalCsaPages = data.recCount;
    this.totalRecordsCsa = totalCsaPages;
    totalCsaPages = this.calTotalPages(totalCsaPages);
    this.totalCsaPagers = totalCsaPages;

    //this.totalDealerOrders = new Array(data['recCount']);
    this.totalCsaOrders = new Array(totalCsaPages);
    this.tableLoader = false; 
    //console.log('dealer order list',this.dealerOrderList);
    //console.log('totalDealerOrders',this.totalDealerOrders);
    //console.log('totalRecordsDealer ',this.totalRecordsDealer );
    //console.log(JSON.stringify(this.csaOrderList));
  
  });
}
getOrderLineParam (orderNumber,ittNumber,requestId) {
  return {
    "orderNumber": orderNumber, 
    "ittNumber": ittNumber,
    "requestId": requestId
   // "lineId": lineId
     }
  }
  getSearchOrderLineParam (orderNumber,ittNumber,requestId) {
    return {
      "orderNumber": orderNumber, 
      "ittNumber": ittNumber,
      "requestId": requestId,
      "installReturn": this.searchInstall,
      "lineNumber": this.searchOrderLine,
      "item": this.searchOrderItem,
      "serviceModel": this.searchOrderServiceModel,
      "itemDesc": this.searchOrderDescription,
      "serialNumber": this.searchSerialNumber
       }
    }

    
  searchOrderLineBtn(orderNumber,ittNumber,requestId)
  {
    orderNumber=this.dealerCsaObj.orderNumber;
    ittNumber =this.dealerCsaObj.ittNumber;
    requestId =this.dealerCsaObj.requestId;
    this.masterOrderLineSelected = false;
    //requestNumber =this.dealerCsaObj.requestNumber;
    if(this.dealerCsaObj.requestNumber == null)
         this.requestId = '';
      else
      this.orderNumber = '';
    this.orderqueryService.getSearchLineAttachments(this.getSearchOrderLineParam(orderNumber,ittNumber,requestId)).subscribe(data => {
      this.selectedOrderLine = data.data;
     
    console.log("Uploaded Docs List:"+this.selectedOrderLine);
      $("#orderLineModal").modal("show");
      console.log('data passed to modal: ',this.selectedOrderLine);
      
    },
    (error) =>
    {
       this.commonErrMsg = "";  
       console.log("Error: "+error);
       this.commonErrMsg =error;
    });
  }
openOrderLineData(orderNumber,ittNumber,requestId)
{
  this.warningFileMsg = "";
  this.warningFileDocMsg = "";
  this.fileMsg = "";
  this.checkBoxErrorMsg= "";
  this.orderLineNumberValue = "";
  this.searchInstall = "";
  this.masterOrderLineSelected = false;
  this.orderLineCheckedList =[];
 // alert("openModel");
 if(this.selectedDocType == "POD/Pickup Confirmation")
  {
 orderNumber=this.dealerCsaObj.orderNumber;
 ittNumber =this.dealerCsaObj.ittNumber;
 requestId =this.dealerCsaObj.requestId;
 //requestNumber =this.dealerCsaObj.requestNumber;
 if(this.dealerCsaObj.requestNumber == null)
      this.requestId = '';
   else
   this.orderNumber = '';
  
 this.orderqueryService.getSearchLineAttachments(this.getOrderLineParam(orderNumber,ittNumber,requestId)).subscribe(data => {
  this.selectedOrderLine = data.data;
 
console.log("Uploaded Docs List:"+this.selectedOrderLine);
  $("#orderLineModal").modal("show");
  console.log('data passed to modal: ',this.selectedOrderLine);
  
},
(error) =>
{
   this.commonErrMsg = "";  
   console.log("Error: "+error);
   this.commonErrMsg =error;
});
}
else
{
  this.warningFileMsg = "";
  this.warningFileMsg = "Please select Document Type";
  this.warningFileDocMsg = "";
}
}
openFileUpload()
{
  this.commonErrMsg = "";
  this.commonFileErrMsg = "";
  this.fileMsg = "";
 // this.docTypebtn = true;
  //this.selectedDocType ='';
}
uploadDocService(param){
  console.log('order id is:'+param);     
       this.commonErrMsg = "";
       this.commonErrMsg = "";
       this.commonFileErrMsg = "";
       this.fileMsg ="";
  this.orderqueryService.getUploadedDocs(param).subscribe(data => {
  this.selectedUploadedOrder = data.data;
 
console.log("Uploaded Docs List:"+this.selectedUploadedOrder);
 $("#uploadDocModal").modal("show");
 this.docTypebtn = false;
 // console.log('data passed to modal: ',this.selectedUploadedOrder);
},
(error) =>
{
   this.commonErrMsg = "";  
   console.log("Error: "+error);
   this.commonErrMsg =error;
});
}

getFileAttParam (orderNumber,requestId) {
return {
  "orderNumber": orderNumber,
   "requestId": requestId
   }
}
uploadDoc(obj,source){
  this.dealerCsaObj=obj;
  this.fileLineId=obj.lineId;
  this.lineId=obj.lineId;
  this.orderNumber=obj.orderNumber;
  this.requestId=obj.requestId;
  this.searchRequestNumber = obj.requestNumber;
  this.disableProp=obj.disableProp;
  this.fileMsg = "";
  this.commonErrMsg = "";
  this.warningFileMsg = "";
  this.warningFileDocMsg = "";
  this.checkBoxErrorMsg= "";
  this.searchInstall = "";
  this.selectedDocType ="";
  this.desc ="";
  this.source=source;
  this.orderLineNumberValue = "";
  $("#customFile").val(''); 
  if(this.orderNumber == "TBD")
   this.orderNumber = '';
  
  this.uploadDocService(this.getFileAttParam(this.orderNumber,this.requestId));

 }

 handleFileInput(files: FileList) {
  this.fileToUpload = files.item(0);
   //this.uploadFileToActivity();
}
openDeleteFileModal(fileName,attachId)
{
this.commonFileErrMsg = "";
this.fileMsg = "";
this.checkBoxErrorMsg= "";
this.searchInstall = "";
this.selectedDocType ="";
this.orderLineNumberValue = "";
$("#delete-file-modal").modal("show");
this.fileName=fileName;
this.attachId=attachId;
}
deleteDoc(attachId)
{
this.commonFileErrMsg = "";
this.warningFileMsg = "";
this.warningFileDocMsg = "";
this.fileMsg = "";
this.orderLineNumberValue = "";
if(this.dealerCsaObj.orderNumber == "TBD" || this.dealerCsaObj.orderNumber == "" )
{
this.dealerCsaObj.orderNumber = '';
this.dealerCsaObj.ittNumber ='';
this.orderLineRequestNumber = this.dealerCsaObj.requestNumber;
}
else
this.requestId ="";
this.orderqueryService.deleteUploadedDoc(attachId,this.dealerCsaObj.orderNumber,this.dealerCsaObj.ittNumber,this.orderLineRequestNumber).subscribe(data => {
  this.commonFileErrMsg = "";
  if(data.status == 'Successfully Deleted the attachment')
  {
    $("#delete-file-modal").modal("hide");
    this.fileMsg = data.status;    
    this.orderqueryService.getUploadedDocs(this.getFileAttParam(this.dealerCsaObj.orderNumber,this.requestId)).subscribe(data => {        
      this.selectedUploadedOrder = data.data;
      this.fileMsg = "";
      this.docTypebtn = false;     
       console.log("Uploaded Docs List:"+this.selectedUploadedOrder);
    },
    (error) =>
    {
       this.commonFileErrMsg = "";
       this.warningFileMsg = "";
       this.warningFileDocMsg = "";
       this.fileMsg = "";
       console.log("Error: "+error);
       this.commonFileErrMsg =error;
    });
  }
  else
  {
    this.commonFileErrMsg = "";
    this.fileMsg = "";
    this.commonFileErrMsg = "Some Error Occured while deleteing the file";
   
  }
},
error =>
{
  this.commonFileErrMsg = "";
  this.fileMsg = "";
  console.log("Error: "+error);
  this.commonFileErrMsg =error;
});
}
validateFileType(fileToUpload)
{
 if(fileToUpload.type == 'application/pdf' || fileToUpload.type == 'application/msword' ||fileToUpload.type == 'application/vnd.ms-excel'|| fileToUpload.type == 'text/plain' || fileToUpload.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || fileToUpload.type ==  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
this.valFiletype = true;
else
this.valFiletype =  false;

return this.valFiletype;
}
validateFileSize(fileToUpload)
{
  const fsize = fileToUpload.size;
  const file = Math.round((fsize / 1024)); 
   if (file >= 2048)                 
    this.valFileSize = false;
  else
    this.valFileSize =  true;

return this.valFileSize;
}

clearFileDtls(s: string) {
this.desc = '';
this.fileName = null;

if (s == "I") {
  this.warningFileMsg = '';
  this.warningFileDocMsg = "";
  this.fileMsg = '';
}
}
orderLineCheckUncheckAll() { 
  this.orderLineNumberValue = "";
  for (var i = 0; i < this.selectedOrderLine.length; i++) {
 
       this.selectedOrderLine[i].isCheck = this.masterOrderLineSelected;
  }
  this.getCheckedOrderLineList();
}
getSaveParam (param) { 
  return {
    "lineNumber":param.lineNumber,
    "serialNumber":param.serialNumber,
    "itemDesc":param.itemDesc,
    "item":param.item,
    "installReturn":param.installReturn,
}

  }
  parentCheckOrderLine(parentObj) {
    this.orderLineNumberValue = "";
    if( parentObj.isCheck == true)
    {
      this.parentId=parentObj.lineNumber;
  
    for (var i = 0; i < this.selectedOrderLine.length; i++) {
      if(this.selectedOrderLine[i].lineNumber == this.parentId )
      {
        this.selectedOrderLine[i].isCheck =true;
      }
    }
  }
  this.getCheckedOrderLineList();
}
  
  getCheckedOrderLineList() {
    this.orderLineCheckedList = [];
    this.orderLineNumberValue = "";
    this.conCatLineNumber= "";
      for (var i = 0; i < this.selectedOrderLine.length; i++) {
        if(this.selectedOrderLine[i].isCheck)
        {

            this.orderLineCheckedList.push(this.getSaveParam(this.selectedOrderLine[i]));                  
        }

      }
      console.log("orderLineCheckedList:"+this.orderLineCheckedList);
    //  throw new Error('Method not implemented.');
    if(this.searchInstall == "ALL" && this.masterOrderLineSelected == true && this.orderLineCheckedList.length >0)
    {
      this.orderLineNumberValue = "ALL";
    }
    else if(this.searchInstall == "INSTALL" && this.masterOrderLineSelected == true && this.orderLineCheckedList.length >0)
    {
      this.orderLineNumberValue = "Installation Only - All";
    }
    else if(this.searchInstall == "RETURN" && this.masterOrderLineSelected == true && this.orderLineCheckedList.length >0)
    {
      this.orderLineNumberValue = "Return Only - All";
    }
    else
    {
      for (var i = 0; i < this.orderLineCheckedList.length; i++) {
        if(this.orderLineCheckedList.length>1)
        {
          if(i== 0)
          this.conCatLineNumber=this.orderLineCheckedList[i].lineNumber;
          else
          this.conCatLineNumber+=","+this.orderLineCheckedList[i].lineNumber;
        }
      else
      this.conCatLineNumber=this.orderLineCheckedList[i].lineNumber;
      }
      this.orderLineNumberValue =this.conCatLineNumber;
    }
  
      return this.orderLineCheckedList;
  }
  finalOrderLineCheckedList()
  {
   if(this.masterOrderLineSelected == true)
     {
       this.checkBoxErrorMsg = "Please select atleast one line";
     }
     
  }
  
  clearOrderLineButton() {    
    this.searchInstall = "";
    this.searchOrderLine = "";
    this.searchOrderItem = "";
    this.searchOrderServiceModel = "";
    this.searchOrderDesc = "";
    this.searchOrderSerNum = "";
    this.masterOrderLineSelected = false;
    this.orderLineNumberValue = "";
    this.searchOrderLineBtn(this.dealerCsaObj.orderNumber,this.dealerCsaObj.ittNumber,this.dealerCsaObj.requestId);

  }
  opendocType()
  {
    this.orderLineNumberValue = "";
    this.searchInstall = "";
    this.masterOrderLineSelected = false;
    if(this.selectedDocType == "Misc")
    this.docTypebtn = true;
    else if(this.selectedDocType == "POD/Pickup Confirmation")
    this.docTypebtn = false;
    else
    this.docTypebtn = true;
  }
uploadFileToActivity() {
this.fileMsg = "";
this.commonErrMsg = "";
this.warningFileMsg = "";
this.warningFileDocMsg = "";
this.commonFileErrMsg = "";
if(this.desc == undefined)
  this.desc = "";
  
if(this.fileToUpload != null)
{
  this.valFiletype=this.validateFileType(this.fileToUpload);
  this.valFileSize=this.validateFileSize(this.fileToUpload);
   if(this.valFiletype)
  {
    if(this.valFileSize)
    {
     if(this.selectedDocType == "Misc" || this.selectedDocType == "POD/Pickup Confirmation")
     {
      if(this.selectedDocType != "Misc")
      {
        if(this.orderLineCheckedList !== undefined && this.orderLineCheckedList.length !== 0)
        {
          
    for (var i = 0; i < this.orderLineCheckedList.length; i++) {
      if(this.orderLineCheckedList.length>1)
      {
        if(i== 0)
        this.conCatLineNumber=this.orderLineCheckedList[i].lineNumber;
        else
        this.conCatLineNumber+=","+this.orderLineCheckedList[i].lineNumber;
      }
    else
    this.conCatLineNumber=this.orderLineCheckedList[i].lineNumber;
    }
        }
        else
        this.warningFileMsg = "To Upload POD/Pickup Confirmation Document Type please select line Numbers.";
    }
  else
  this.conCatLineNumber = '';
  
  if(this.warningFileMsg  == "")
  {
    if(this.dealerCsaObj.orderNumber == "TBD" || this.dealerCsaObj.orderNumber == "" )
    {
    this.dealerCsaObj.orderNumber = '';
    this.dealerCsaObj.ittNumber ='';
    this.orderLineRequestNumber = this.dealerCsaObj.requestNumber;
    }
  
if(this.dealerCsaObj.ittNumber == undefined)
   this.dealerCsaObj.ittNumber ='';
if(this.dealerCsaObj.requestId == undefined)
     this.dealerCsaObj.requestId ='';
if(this.isAdmin == 'Y')
  this.source = 'CSA';
else
  this.source = 'Dealer';
if(this.dealerCsaObj.orderHeaderId == "" || this.dealerCsaObj.orderHeaderId == undefined)    
    this.orderLineHeaderId =this.dealerCsaObj.headerId;
else
    this.orderLineHeaderId =this.dealerCsaObj.orderHeaderId;
this.orderqueryService.insertUploadedDocs(this.fileToUpload,this.conCatLineNumber,this.dealerCsaObj.orderNumber,this.orderLineHeaderId,this.dealerCsaObj.ittNumber,this.desc,this.selectedDocType,this.source,this.dealerCsaObj.requestId,this.orderLineNumberValue, this.orderLineRequestNumber).subscribe(data => {
  // do something, if upload success
  //this.fileMsg = data.data;
 // alert("Insert");
 this.commonFileErrMsg = "";
 this.warningFileMsg = "";
 this.fileMsg = "";
  if(data.data == "Success")
  {
    this.fileMsg = "Successfully Uploaded";
    this.warningFileMsg = "";
    this.desc ="";
    $("#noRecUploadMsg").empty();
    $("#customFile").val(''); 
    this.clearFileDtls('U');
    this.fileToUpload = null;
    if(this.dealerCsaObj.orderNumber == "TBD" )
    {
    this.dealerCsaObj.orderNumber = '';
    this.dealerCsaObj.ittNumber ='';
    }
    if(this.dealerCsaObj.requestId == undefined)
    this.dealerCsaObj.requestId ='';
    this.orderqueryService.getUploadedDocs(this.getFileAttParam(this.dealerCsaObj.orderNumber,this.dealerCsaObj.requestId)).subscribe(data => {
      this.selectedUploadedOrder = data.data;
      this.docTypebtn = false;
      this.selectedDocType ='';
      this.orderLineNumberValue = '';
      this.orderLineCheckedList =[];
       console.log("Uploaded Docs List:"+this.selectedUploadedOrder);
    },
    (error) =>
    {
       this.commonFileErrMsg = "";
       this.fileMsg = "";
       this.warningFileMsg = "";
       this.warningFileDocMsg = "";
       console.log("Error: "+error);
       this.commonFileErrMsg =error;
    });
  }
  },
  (error) =>
  {
     this.commonFileErrMsg = "";
     this.fileMsg = "";
     this.warningFileMsg = "";
     this.warningFileDocMsg = "";
     console.log("Error: "+error);
     this.commonFileErrMsg =error;
  });
}
}
  else
  {
    this.warningFileMsg = "";
    this.warningFileDocMsg = "";
    this.warningFileMsg="Please select Document Type and upload file";
  }
}
  else
  {
    this.warningFileMsg = "";
    this.warningFileDocMsg = "";
    this.warningFileMsg="Please select Files size less than or equal to 2MB";
    this.fileToUpload = null;
  }
}

  else
  {
    this.warningFileMsg = "";
    this.warningFileDocMsg = "";   
    //this.warningFileMsg="Please Upload Files with  file types .pdf,.xls,.txt,.doc,.docx,.xlsx";
    this.warningFileDocMsg = "Please Upload Files with  file types ";
    this.fileToUpload = null;
  }
}
else
{
  this.warningFileMsg = "";
  this.warningFileDocMsg = "";
  this.warningFileMsg="Please Upload the file and click Upload";
  this.fileToUpload = null;
}
}

openCnaShipToCode(selectedCnaShiptoCode,shiptocnaAddress,shiptocnaAddress2,shiptocnaState,shiptocnaCity,shiptocnaZipCode,shiptoIttNumber,shiptoReqNumber)
{
  //alert("openModel");
  this.shiptocnaCode = "";
  this.shiptocnaAddress = "";
  this.shiptocnaAddress2 = "";
  this.shiptocnaState = "";
  this.shiptocnaCity = "";
  this.shiptocnaZipCode = "";
   this.orderqueryService.getOrderInquiryShipToCnaCode(this.getSearchCnaCodeParam(this.selectedCnaShiptoCode,shiptocnaAddress,shiptocnaAddress2,shiptocnaState,shiptocnaCity,shiptocnaZipCode,shiptoIttNumber,shiptoReqNumber)).subscribe(data => {
    this.shipToCodeList = data.data;
    $("#cnaModal").modal("show");
    console.log('getOrderInquiryShipToCnaCode list',this.shipToCodeList.length);
  },
  (error) =>
  {   
     console.log("Error: "+error);  
  });
  
}
searchCnaCode(shiptocnaCode,shiptocnaAddress,shiptocnaAddress2,shiptocnaState,shiptocnaCity,shiptocnaZipCode,shiptoIttNumber,shiptoReqNumber)
{
 
  this.orderqueryService.getOrderInquiryShipToCnaCode(this.getSearchCnaCodeParam(shiptocnaCode,shiptocnaAddress,shiptocnaAddress2,shiptocnaState,shiptocnaCity,shiptocnaZipCode,shiptoIttNumber,shiptoReqNumber)).subscribe(data => {
    this.shipToCodeList = data.data;
    $("#cnaModal").modal("show");
    console.log('searchCnaCode list',this.shipToCodeList);
  },
  (error) =>
  {   
     console.log("Error: "+error);  
  });
}
clearCnaCodeButton() {    
  this.shiptocnaCode = "";
  this.shiptocnaAddress = "";
  this.shiptocnaAddress2 = "";
  this.shiptocnaState = "";
  this.shiptocnaCity = "";
  this.shiptocnaZipCode = "";
//  this.searchCnaCode(shiptocnaCode,shiptocnaAddress,shiptocnaAddress2,shiptocnaState,shiptocnaCity,shiptocnaZipCode,shiptoIttNumber,shiptoReqNumber);

}

setShipToCode(shipToCode) {
   this.selectedCnaShiptoCode =shipToCode.shiptocnaCode;
  this.address =shipToCode.shiptocnaAddress2;
  this.city =shipToCode.shiptocnaCity;
  this.state =shipToCode.shiptocnaState;
  this.zipCode =shipToCode.shiptocnaZipCode;
 }
getSearchCnaCodeParam (shiptocnaCode,shiptocnaAddress,shiptocnaAddress2,shiptocnaState,shiptocnaCity,shiptocnaZipCode,shiptoIttNumber,shiptoReqNumber) {
  // if(this.isAdmin == "Y")
  //   this.shiptosourcedBy ="CSA";
  // else
  //   this.shiptosourcedBy ="Dealer";
  return {
    "shiptocnaCode": shiptocnaCode, 
    "shiptocnaAddress": shiptocnaAddress,
    "shiptocnaState": shiptocnaState,
    "shiptocnaCity": shiptocnaCity,
    "shiptocnaZipCode": shiptocnaZipCode,
    "ittNumber":this.orderNum.trim(),
    "reqNumber":this.requestNumber.trim(),
    "shiptodealerName":this.dealerName,
    "shiptosourcedBy":this.shiptosourcedBy,
    "shiptocnaAddress2":shiptocnaAddress2
     }
  }
       pageLoad() {
        // alert("page Load");
    $("#page-loader-overlay")
        .delay(800)
        .fadeOut();
    $("#delay-loader")
        .delay(1000)
        .fadeOut();
       
       }
    
       private subscription: Subscription;
       myVar;

  ngAfterViewInit(){
      console.log('after view');
      //this.setPgLinks(10000,1,10,10);
        
    }
  ngOnInit(): void {
    
    this.subscription = this.setScreenService.changeVar.subscribe(message => {

        //console.log("OM message"+message);
          this.myVar = Number(message);

            
          if(this.myVar == 0){
            this.setTab('csa');

  
            }else if(this.myVar == 1){
              this.setTab('dealer');

            }           
     // }
  });
 
    // this.orderCsaService.getOrderCsaList().subscribe(data => {
    //   this.csaOrderList = data.result;
    //   console.log('csa order list',this.csaOrderList);
    //   //console.log(JSON.stringify(this.csaOrderList));

    // });


   //this.searchCsaOrder(this.getCsaSearchParam());
     //this.searchDealerOrder(this.getDealerSearchParam());

    this.stateList = this.orderqueryService.getStates();
    //this.csaOrderList = this.orderqueryService.getOrderCsaList();
    //$(".csa-page-header-title").show();

      this.loggedUserName= this.loginAuthenticationService.getAuthenticatedUser();
      this.loggedUserFullName= this.loginAuthenticationService.getAuthenticatedUserFullName();
  
   
 let username = this.loggedUserName;
 this.titleService.setTitle("Order Inquiry");  
      //this.setDealersCSS();
      
      this.pageLoad();
      this.isAdmin=	sessionStorage.getItem('Admin');
      this.getDealerSearchFromSession();
      this.getCsaSearchFromSession();
  // alert("Insession isAdmin:"+this.isAdmin);
 
// let  objThis=this;
//   setTimeout(function (){
   
//     objThis.setPgLinks(10000,1,10,10);
   
   
//   },2000);


}


// setScreen(value,queryparams){
//   this._setCsaValueService.changeMyVar(value);
//   if(value=='0'){
//     this.routing.navigate(['/orderapprovals','dlr'],{queryParams:{ reqn: queryparams }});
//   }else{
//     this.routing.navigate(['/orderapprovals','csa'],{queryParams:{ reqn: queryparams }});
//   }
// }


ngOnDestroy() {
  if (this.subscription) {
  this.subscription.unsubscribe();
  }

}

}
