import { Component, OnInit } from '@angular/core';
import { CompensationDetailsService } from 'src/app/service/finance-service/compensation-details.service';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { SetScreenService } from 'src/app/service/common-service/set-screen.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-compensation-details',
  templateUrl: './compensation-details.component.html',
  styleUrls: ['./compensation-details.component.css']
})
export class CompensationDetailsComponent implements OnInit {

  tableLoader = true;
  asFlag = true;
  advanceSearchFlag = "Y";
  selectedPeriod :String = "";
  customerName : String ="";
  period = "AS";
  loggedUserName=''; 
  loggedUserFullName='';
  fromDate :any;
  toDate :any;
  selectedFromDate :any;
  selectedToDate :any;
  serialNumber : String ="";
  paymentNumber : String = "";
  invoiceNumber : String = "";
  dealerNameSrch:String="";
  dealerPONumber:String = "";
  dlrCompensationDetailsList : any[] = [];
  csaCompensationDetailsList: any[] = [];
  dealerName = "";
  totalRecordsDealer;
  totalRecordsCsa;
  totalDealerPages;
  totalCsaPages
  totalDealerOrders: Array<number>;
  totalCsaOrders: Array<number>;
  pageNum = 1;
  recPerPage = 10;
  sortOrder: String = "";
  sortBy: String = "";
  dlrOrCsa : any;
  dlrScreen = false;
  csaScreen = false;

  dSelectAll = true; 
  dInvoiceDate = true;
  dDealerName = true;
  dDealerSiteCode = true;
  dSerialNumber = true;
  dContractOrderNumber = true;
  dCustPONumber = true;
  dModel = true;
  dCompensationType = true;
  dAmount = true;
  dInvoiceNumber = true;
  dBillingFrom = true;
  dBillingTo = true;
  dCopyIndicator = true;
  dPrevCount = true;
  dCurrentCount = true;
  dAllowance = true;
  dAllocatedCopies = true;
  dCostPerCopy = true;
  dContractTerm = true;
  dInstallDate = true;
  dAcct = true;
  dCustomerName = true;
  dMachineLocName = true;
  dMachineLocAdd1 = true;
  dMachineLocAdd2 = true;
  dCity = true;
  dState = true;
  dZip = true;
  dPaymentNum = true;
  dPaymentDate = true;
  dFlag = true;

  cSelectAll = true;
  cInvoiceDate = true;
  cDealerName = true;
  cDealerSiteCode = true;
  cSerialNumber = true;
  cContractOrderNumber = true;
  cCustPONumber = true;
  cModel = true;
  cCompensationType = true;
  cAmount = true;
  cInvoiceNumber = true;
  cBillingFrom = true;
  cBillingTo = true;
  cCopyIndicator = true;
  cPrevCount = true;
  cCurrentCount = true;
  cAllowance = true;
  cAllocatedCopies = true;
  cCostPerCopy = true;
  cContractTerm = true;
  cInstallDate = true;
  cAcct = true;
  cCustomerName = true;
  cMachineLocName = true;
  cMachineLocAdd1 = true;
  cMachineLocAdd2 = true;
  cCity = true;
  cState = true;
  cZip = true;
  cPaymentNum = true;
  cPaymentDate = true;
  cFlag = true;

  isAdmin: string;
  private subscription: Subscription;
  myVar;


  constructor(private routing:Router,private activatedRoute:ActivatedRoute,private _CompDetailsService:CompensationDetailsService,private loginAuthenticationService:LoginAuthenticationService,private titleService: Title,private _setCsaService:SetScreenService) { }
 
  ngOnInit() {
    this.titleService.setTitle("Compensation Details");
    this.loggedUserName= this.loginAuthenticationService.getAuthenticatedUser();
    this.loggedUserFullName= this.loginAuthenticationService.getAuthenticatedUserFullName();
    this.isAdmin=	sessionStorage.getItem('Admin');

   $(".group-switcher").css({
      "border-color": "#999",
      "background-color": "#999 !important",
      "pointer-events": "none"
    });

  $("#main-top-nav-switcher").css({ 
    "border-color": "#999",
    "background-color": "#999",

  });
  $("#main-menu-switcher").css({ 
    "border-color": "#999",
    "background-color": "#999",

  });

  this.activatedRoute.queryParams
  .subscribe(params => {
    this.selectedFromDate = params['invFromDate'];
    this.selectedToDate = params['invToDate'];
    let pFromDate=params['invFromDate'];
    let pToDate=params['invToDate'];
    if(pFromDate){
      this.fromDate =new Date(pFromDate);
    }
    if(pToDate){
      this.toDate =new Date(pToDate);
    }
    
    this.serialNumber = params['serialNumber'];
    this.paymentNumber = params['paymentNum'];
    this.invoiceNumber = params['in'];
    this.dealerNameSrch = params['dealerName'];
    this.period = params['period'];
    this.selectedPeriod = params['period'];
    this.customerName = params['cn'];
    this.dealerPONumber = params['dealerPONumber'];
    
    if(!this.period || (this.period&&this.period=="ADS") || this.invoiceNumber){
      this.period="AS";
    }else{
       
      this.advanceSearchFlag = "N";
    }
    
  }); 

    /*this.activatedRoute.queryParams
    .subscribe(params => {
      this.dlrOrCsa = params['cd'];
    });*/

    this.subscription = this._setCsaService.changeVar.subscribe(message => {
      this.myVar = Number(message);
      if(this.myVar == 0){            
            this.csaScreen = true;
            this.dlrScreen = false;
            this.getCsaCompensationDetailsList();

      }else if(this.myVar == 1){        
        this.dlrScreen = true;
        this.csaScreen = false;
        this.getDlrCompensationDetailsList();

        }           
  });   
  this.pageLoad(); 
    /*if(this.dlrOrCsa == "dealer"){
      this.dlrScreen = true;
      this.csaScreen = false;      
      this.getDlrCompensationDetailsList();
    }
    if(this.dlrOrCsa == "csa"){
      this.csaScreen = true;
      this.dlrScreen = false;
      this.getCsaCompensationDetailsList();
    }*/
  }

  getCompensationDetailsParams(){
    if(this.myVar == 0){
      let objParams=this.getCsaCompensationDetailsParams();
      objParams["sp"]=this.selectedPeriod;
      objParams["cn"]=this.customerName;
      this.routing.navigate(['finance'],{ queryParams: objParams});
    }else if(this.myVar == 1){
      let objParams=this.getDlrCompensationDetailsParams();
        objParams["sp"]=this.selectedPeriod;
        objParams["cn"]=this.customerName;
        this.routing.navigate(['finance'],{ queryParams: objParams});
      }
  }

  getDlrCompensationDetailsParams(){
    if(this.fromDate) {
      this.changeEvent(this.fromDate,'fd');
    }else
    {
      this.selectedFromDate="";
    }
    if(this.toDate) {
      this.changeEvent(this.toDate,'td');
    }else{
      this.selectedToDate="";
    }
    return {
      "advanceSearchFlag": this.advanceSearchFlag,
      "serialNumber": this.serialNumber,
      "dealerPONumber": this.dealerPONumber,
      "invoiceNumber": this.invoiceNumber,
      "invoiceFromDate":this.selectedFromDate,
      "invoiceToDate":this.selectedToDate,
      "paymentNumber":this.paymentNumber,
      "period": this.period,        
      "dealerName": this.dealerNameSrch,
      "recPerPage": this.recPerPage,
      "pageNum": this.pageNum,
      "sortBy": this.sortBy,
      "sortOrder":this.sortOrder
    }

  }
  getDlrCompensationDetailsList(){
    this.tableLoader = true;
    this._CompDetailsService.getCompensationDetailsDealer(this.getDlrCompensationDetailsParams()).subscribe(data=>{
      this.dlrCompensationDetailsList = data.data;
      let totalDealerPages = data.recCount;
      this.dealerName = data.dealerName;
      this.totalRecordsDealer = totalDealerPages;
      totalDealerPages = this.calTotalPages(totalDealerPages);
      this.totalDealerPages = totalDealerPages;
      this.totalDealerOrders = new Array(totalDealerPages);
      console.log("dlrCompensationDetailsList ",this.dlrCompensationDetailsList);
      console.log('totalRecordsDealer',this.totalRecordsDealer);
      this.tableLoader = false;
    });
  }

  getCsaCompensationDetailsParams(){
    if(this.fromDate) {
      this.changeEvent(this.fromDate,'fd');
    }else
    {
      this.selectedFromDate="";
    }
    if(this.toDate) {
      this.changeEvent(this.toDate,'td');
    }else{
      this.selectedToDate="";
    }
    return {
      "advanceSearchFlag": this.advanceSearchFlag,
      "serialNumber": this.serialNumber,
      "dealerPONumber": this.dealerPONumber,
      "invoiceNumber": this.invoiceNumber,
      "invoiceFromDate":this.selectedFromDate,
      "invoiceToDate":this.selectedToDate,
      "paymentNumber":this.paymentNumber,
      "period": this.period,   
      "dealerName": this.dealerNameSrch,     
      "recPerPage": this.recPerPage,
      "pageNum": this.pageNum,
      "sortBy": this.sortBy,
      "sortOrder":this.sortOrder
    }
  }

  getCsaCompensationDetailsList(){
    this.tableLoader = true;
    this._CompDetailsService.getCompensationDetailsCsa(this.getCsaCompensationDetailsParams()).subscribe(data=>{
      this.csaCompensationDetailsList = data.data;
      let totalCsaPages = data.recCount;
      this.dealerName = data.dealerName;
      this.totalRecordsCsa = totalCsaPages;
      totalCsaPages = this.calTotalPages(totalCsaPages);
      this.totalCsaPages = totalCsaPages;      
      this.totalCsaOrders = new Array(totalCsaPages);
      console.log("csaCompensationDetailsList ",this.csaCompensationDetailsList);
      console.log('totalRecordsCsa',this.totalRecordsCsa);
      this.tableLoader = false;
    });
  }

  getSelectedPeriod(event:any){
    this.period = event.target.value;
    if(this.period == "AS"){
      //this.asFlag = true;
      this.advanceSearchFlag = "Y";
      if(this.dlrScreen==true){
        this.getDlrCompensationDetailsList();
      }else 
      if(this.csaScreen == true) {
        this.getCsaCompensationDetailsList();
      }
    }else {
      this.advanceSearchFlag = "N";
      //this.asFlag = false;
      if(this.dlrScreen==true){
        this.getDlrCompensationDetailsList();
      }else 
      if(this.csaScreen == true) {
        this.getCsaCompensationDetailsList();
      }
    }
    
  }

  advanceSearch(){
    if(this.dlrScreen==true){
      this.pageNum = 1;
     this.getDlrCompensationDetailsList();
    }if(this.csaScreen == true) {
      this.pageNum = 1;
      this.getCsaCompensationDetailsList();
    }
  }

  changeDSelectAll(){
    if(this.dSelectAll){
      this.dInvoiceDate = true;
      this.dDealerName = true;
      this.dDealerSiteCode = true;
      this.dSerialNumber = true;
      this.dContractOrderNumber = true;
      this.dCustPONumber = true;
      this.dModel = true;
      this.dCompensationType = true;
      this.dAmount = true;
      this.dInvoiceNumber = true;
      this.dBillingFrom = true;
      this.dBillingTo = true;
      this.dCopyIndicator = true;
      this.dPrevCount = true;
      this.dCurrentCount = true;
      this.dAllowance = true;
      this.dAllocatedCopies = true;
      this.dCostPerCopy = true;
      this.dContractTerm = true;
      this.dInstallDate = true;
      this.dAcct = true;
      this.dCustomerName = true;
      this.dMachineLocName = true;
      this.dMachineLocAdd1 = true;
      this.dMachineLocAdd2 = true;
      this.dCity = true;
      this.dState = true;
      this.dZip = true;
      this.dPaymentNum = true;
      this.dPaymentDate = true;
      this.dFlag = true;
    }else{
      this.dInvoiceDate = false;
      this.dDealerName = false;
      this.dDealerSiteCode = false;
      this.dSerialNumber = false;
      this.dContractOrderNumber = false;
      this.dCustPONumber = false;
      this.dModel = false;
      this.dCompensationType = false;
      this.dAmount = false;
      this.dInvoiceNumber = false;
      this.dBillingFrom = false;
      this.dBillingTo = false;
      this.dCopyIndicator = false;
      this.dPrevCount = false;
      this.dCurrentCount = false;
      this.dAllowance = false;
      this.dAllocatedCopies = false;
      this.dCostPerCopy = false;
      this.dContractTerm = false;
      this.dInstallDate = false;
      this.dAcct = false;
      this.dCustomerName = false;
      this.dMachineLocName = false;
      this.dMachineLocAdd1 = false;
      this.dMachineLocAdd2 = false;
      this.dCity = false;
      this.dState = false;
      this.dZip = false;
      this.dPaymentNum = false;
      this.dPaymentDate = false;
      this.dFlag = false;
    }
  }

  changeCSelectAll(){
    if(this.cSelectAll){
      this.cInvoiceDate = true;
      this.cDealerName = true;
      this.cDealerSiteCode = true;
      this.cSerialNumber = true;
      this.cContractOrderNumber = true;
      this.cCustPONumber = true;
      this.cModel = true;
      this.cCompensationType = true;
      this.cAmount = true;
      this.cInvoiceNumber = true;
      this.cBillingFrom = true;
      this.cBillingTo = true;
      this.cCopyIndicator = true;
      this.cPrevCount = true;
      this.cCurrentCount = true;
      this.cAllowance = true;
      this.cAllocatedCopies = true;
      this.cCostPerCopy = true;
      this.cContractTerm = true;
      this.cInstallDate = true;
      this.cAcct = true;
      this.cCustomerName = true;
      this.cMachineLocName = true;
      this.cMachineLocAdd1 = true;
      this.cMachineLocAdd2 = true;
      this.cCity = true;
      this.cState = true;
      this.cZip = true;
      this.cPaymentNum = true;
      this.cPaymentDate = true;
      this.cFlag = true;
    }else{
      this.cInvoiceDate = false;
      this.cDealerName = false;
      this.cDealerSiteCode = false;
      this.cSerialNumber = false;
      this.cContractOrderNumber = false;
      this.cCustPONumber = false;
      this.cModel = false;
      this.cCompensationType = false;
      this.cAmount = false;
      this.cInvoiceNumber = false;
      this.cBillingFrom = false;
      this.cBillingTo = false;
      this.cCopyIndicator = false;
      this.cPrevCount = false;
      this.cCurrentCount = false;
      this.cAllowance = false;
      this.cAllocatedCopies = false;
      this.cCostPerCopy = false;
      this.cContractTerm = false;
      this.cInstallDate = false;
      this.cAcct = false;
      this.cCustomerName = false;
      this.cMachineLocName = false;
      this.cMachineLocAdd1 = false;
      this.cMachineLocAdd2 = false;
      this.cCity = false;
      this.cState = false;
      this.cZip = false;
      this.cPaymentNum = false;
      this.cPaymentDate = false;
      this.cFlag = false;
    }
  }

  clearButton(){
    this.serialNumber = "";
    this.invoiceNumber = "";
    this.paymentNumber = "";
    this.fromDate = "";
    this.toDate = "";
    this.selectedFromDate = "";
    this.selectedToDate = "";
    this.dealerPONumber = "";
  }

  setSortOrder(sortBy,sortOrder,type){
    this.sortBy = sortBy;
    this.sortOrder = sortOrder;
    if(type=="dealer"){
      this.getDlrCompensationDetailsList();
    } else if(type=="csa"){
      this.getCsaCompensationDetailsList();
    }
  }

  changeEvent(event,date){ 
    // Return date object
    let dd = event.getDate();
    let mm = event.getMonth() + 1; //January is 0!
    let yyyy = event.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    if (date=='fd'){
        this.selectedFromDate =  mm + '/' + dd + '/' + yyyy ; 
    }
    if (date=='td'){
        this.selectedToDate =  mm + '/' + dd + '/' + yyyy ; 
      }
    console.log(event);
    console.log('dd',dd);
    return;
  }

  dowloadDealer() {
    let totalDlrCompensationDetailsList = [];
    const dPageNumber = this.pageNum;
    this.recPerPage = 1000000;
    this.pageNum = 1;
      this._CompDetailsService.getCompensationDetailsDealer(this.getDlrCompensationDetailsParams()).subscribe(data => {
        totalDlrCompensationDetailsList = data.data;
      this._CompDetailsService.jsonToExcel(totalDlrCompensationDetailsList,'Dealer Compensation Details', true);
      this.recPerPage = 10;
      this.pageNum = dPageNumber;
    
    });  
  }

  dowloadCsa() {
    let totalCsaCompensationDetailsList = [];
    const dPageNumber = this.pageNum;
    this.recPerPage = 1000000;
    this.pageNum = 1;
      this._CompDetailsService.getCompensationDetailsCsa(this.getCsaCompensationDetailsParams()).subscribe(data => {
        totalCsaCompensationDetailsList = data.data;
      this._CompDetailsService.jsonToExcel(totalCsaCompensationDetailsList,'CSA Compensation Details', true);
      this.recPerPage = 10;
      this.pageNum = dPageNumber;    
    });  
  } 

  calTotalPages (pages){
    let totalPages = pages/10;
    let roundedTotalPages = Math.round(totalPages);
  
    
    if (totalPages > roundedTotalPages) {
      totalPages = roundedTotalPages + 1;
    }else {
      totalPages = roundedTotalPages;
    }
  
    console.log('totalPages',totalPages);
    return totalPages;
   }

   setPage (param,i, event: any) {
    event.preventDefault();
    this.pageNum= i;
    if(param=='dealer'){
      this.getDlrCompensationDetailsList();
   }else{
      this.getCsaCompensationDetailsList();
   }
  
  }
  
  setNextPage (param,event: any) {
   event.preventDefault();
   this.pageNum++;
   if(param=='dealer'){
    this.getDlrCompensationDetailsList();
   }else{
    this.getCsaCompensationDetailsList();
   }
  
  
  }
  setPrevPage (param,event: any) {
   event.preventDefault();
   if (this.pageNum > 1){
   this.pageNum--;
  }
  if(param=='dealer'){
    this.getDlrCompensationDetailsList();
  }else{
    this.getCsaCompensationDetailsList();
  }
  
  
  }
  
  setFirstPage (param:string,event: any) {
   event.preventDefault();
   this.pageNum = 1;
   if(param=='dealer'){
    this.getDlrCompensationDetailsList();
   }else{
    this.getCsaCompensationDetailsList();
   }
  
  }
  
  setLastPage (param,i,event: any) {
   event.preventDefault();
   this.pageNum = i;
   if(param=='dealer'){
    this.getDlrCompensationDetailsList();
   }else{
    this.getCsaCompensationDetailsList();
   }
  
  }

  pageLoad() {
    $("#page-loader-overlay")
      .delay(800)
      .fadeOut();
    $("#delay-loader")
      .delay(1000)
      .fadeOut();
    
    }     

  ngOnDestroy() {
    if (this.subscription) {
    this.subscription.unsubscribe();
    }

  }
  

}
