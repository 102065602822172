import { Injectable } from '@angular/core';
import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { HttpClient, HttpHeaders} from "@angular/common/http";
import { Util } from '../../app.util';

const httpOptions = {
    headers: new HttpHeaders({
        
    }).set('Content-Type', 'application/json')
  };

@Injectable({
    providedIn: 'root'
})

export class FleetService {
        
    defaultUrl1 = 'assets/csaFleetService.json';
    defaultUrl = 'assets/dealerFleetService.json';
     defaultUrlNew : string;

    constructor(private http: HttpClient, private util : Util) {
        
          this.defaultUrlNew = this.util.getBaseUrl();  
    }

    getFleetDealerList(data): Observable<any> {
        //data = JSON.stringify(data);
        let initialUrl = this.defaultUrlNew+"gfsds";
        //let initialUrl = this.defaultUrl;
       //console.log("in getOrderCsaList."+initialUrl);
      // console.log('fleet dealer data',data);
       return this.http
       .post(initialUrl,data,httpOptions)
       //.get(initialUrl)
         .pipe(catchError(this.handleError("getOrderDealerList")))
         // .pipe(map(xml => this.toColleciton(this.tojson(xml))));
         //.pipe(map(data => this.toColleciton2(data)));
     }
    
     
    getFleetCsaList(data): Observable<any> {
        //data = JSON.stringify(data);
        let initialUrl = this.defaultUrlNew+"gfscs";
      //console.log("in getOrderCsaList."+initialUrl);
      //console.log('csa data',data);
      return this.http
        //.get(initialUrl)
        .post(initialUrl,data,httpOptions)
        .pipe(catchError(this.handleError("getOrderCsaList")))
        // .pipe(map(xml => this.toColleciton(this.tojson(xml))));
        //.pipe(map(data => this.toColleciton2(data)));
    }


    
    getFleetTerminateReqList(data): Observable<any> {
        //data = JSON.stringify(data);
        let initialUrl = this.defaultUrlNew+"gfsctr";
      //console.log("in getOrderCsaList."+initialUrl);
      //console.log('csa data',data);
      return this.http
        //.get(initialUrl) 
        .post(initialUrl,data,httpOptions)
        .pipe(catchError(this.handleError("getTerminateReqList")))
        // .pipe(map(xml => this.toColleciton(this.tojson(xml))));
        //.pipe(map(data => this.toColleciton2(data)));
    }


    getAdditionalInfo(serialNum): Observable<any> {
        //data = JSON.stringify(data);
        let initialUrl = this.defaultUrlNew+"ack/getAddInfo?serialNum="+serialNum;
        //let initialUrl = this.defaultUrl;
      //console.log("in getOrderCsaList."+initialUrl);
      //console.log('csa data',data);
      return this.http
        //.get(initialUrl)
        .post(initialUrl,httpOptions)
        .pipe(catchError(this.handleError("getServiceDetails")))
        // .pipe(map(xml => this.toColleciton(this.tojson(xml))));
        //.pipe(map(data => this.toColleciton2(data)));
      }

      terminateRequest(data): Observable<any>{
        let initialUrl = this.defaultUrlNew+"gfst";
        return this.http
        .post(initialUrl,data,httpOptions)
          .pipe(catchError(this.handleError("terminateRequest")))
      }

      private handleError<T>(operation = "operation", result?: T) {
        return (error: any): Observable<T> => {
          // TODO: send the error to remote logging infrastructure
          console.error(error); // log to console instead
    
          // TODO: better job of transforming error for user consumption
          console.log(`${operation} failed: ${error.message}`);
    
          // Let the app keep running by returning an empty result.
          return of(result as T);
        };
      }

      csaFleetLabelsAdmin=["dealerName","serialNumber","model","customerName","installLocation"];
      
      dealerFleetLabelsAdmin=["dealerName","serialNumber","model","customerName","installLocation","division","compBy"];

      csaFleetLabels=["serialNumber","model","customerName","installLocation"];
      
      dealerFleetLabels=["serialNumber","model","customerName","installLocation",,"division","compBy"];
      
      camel2title(camelCase) {
          return camelCase
           // inject space before the upper case letters
           .replace(/([A-Z])/g, function(match) {
              return " " + match;
           })
           // replace first char with upper case
           .replace(/^./, function(match) {
             return match.toUpperCase();
           });
       }
   
   //showLabel = true;
   
    jsonToExcel(JSONData, outputFileName, ShowLabel) {
                
               //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
               var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
               var CSV = '';    
               //Set Report title in first row or line
               //CSV += ReportTitle + '\r\n\n';
               var isAdmin=	sessionStorage.getItem('Admin');
                
               var lbls;
               var custArr;
               var bCustom=false;
               if(outputFileName=="CSA Fleet"){ 
                   if(isAdmin == 'Y'){
                    lbls= this.csaFleetLabelsAdmin;
                   } else{
                    lbls= this.csaFleetLabels;
                   }                   
                   bCustom=true;  
               }else if (outputFileName=="Dealer Fleet"){  
                   if(isAdmin == 'Y'){
                    lbls=this.dealerFleetLabelsAdmin;
                   }else 
                   {
                    lbls=this.dealerFleetLabels;
                   }
                   bCustom=true;
               }else{
                   lbls=arrData[0];
               }
               
               //This condition will generate the Label/Header
               if (ShowLabel) {
                   var row = "";
                   //This loop will extract the label from 1st index of on array
                   if(bCustom){
                       for (var index in lbls) {
                           //Now convert each value to string and comma-seprated
                           var lbl=lbls[index];
                           lbl=this.camel2title(lbl);  
                           row += lbl + ',';
                       }
                   }else{ 
                       for (var index in arrData[0]) {
                           //Now convert each value to string and comma-seprated
                           index=this.camel2title(index);
                           row += index + ',';
                       }
                   }
                   row = row.slice(0, -1);
                   //append Label row with line break
                   if(row.indexOf("Dealer Dba")>-1){
                     row=row.replace("Dealer Dba","Dealer DBA");
                   }
                   if(row.indexOf("Rma")>-1){
                      row=row.replace("Rma","Contains Return");
                    }
                    if(row.indexOf("Install Location")>-1){
                        row=row.replace("Install Location","Install Address");
                      }
                   CSV += row + '\r\n';
               }
               
               if(bCustom){
                   
                       //1st loop is to extract each row
                       for (var i = 0; i < arrData.length; i++) {
                           var row = "";
                       //2nd loop will extract each column and convert it in string comma-seprated
                            var oData=arrData[i];
                           
                           for(const k in lbls){  
                               
                               //Skip "" when '=' is appended for preserving leading zeros
                               var arrDataVal = oData[lbls[k]];
                               //console.log(arrDataVal);
                               //check for null and assign "" string
                               if(null == arrDataVal)
                                   arrDataVal = "";
                               if(!isNaN(arrDataVal))
                                   arrDataVal = arrDataVal.toString();
                               if(arrDataVal.startsWith('=')){
                                   row += arrDataVal + ',';
                                   continue;
                               }
                                
                               row += '"' + arrDataVal + '",';
                            
                       }
                           row.slice(0, row.length - 1);
                           //add a line break after each row
                           CSV += row + '\r\n';
                   }
                   
               }else{
               //1st loop is to extract each row
               for (var i = 0; i < arrData.length; i++) {
                   var row = "";
                   //2nd loop will extract each column and convert it in string comma-seprated
                   for (var index in arrData[i]) {
                       
                       //Skip "" when '=' is appended for preserving leading zeros
                       var arrDataVal = (arrData[i][index]);
                       //check for null and assign "" string
                       if(null == arrDataVal)
                           arrDataVal = "";
                       if(!isNaN(arrDataVal))
                           arrDataVal = arrDataVal.toString();
                       if(arrDataVal.startsWith('=')){
                           row += arrDataVal + ',';
                           continue;
                       }
                       row += '"' + arrDataVal + '",';
                   }
                   row.slice(0, row.length - 1);
                   //add a line break after each row
                   CSV += row + '\r\n';
               } 
               
               }
               if (CSV == '') {        
                   alert("Invalid data");
                   return;
               }
               
              //this trick will generate a temp "a" tag
               var link = document.createElement("a");    
               link.id="lnkDwnldLnk";
   
               //this part will append the anchor tag and remove it after automatic click
               document.body.appendChild(link);
   
               var csv = CSV;  
               var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;'}); 
               
               //Generate a file name
               var fileName =outputFileName;   
               //this will remove the blank-spaces from the title and replace it with an underscore
              // fileName += outputFileName.replace(/ /g," ");
   
               if (navigator.msSaveBlob) { // IE 10+
                   navigator.msSaveBlob(blob, fileName + ".csv")
                       } else {
                           var link = document.createElement("a");
                           if (link.download !== undefined) { // feature detection
                               // Browsers that support HTML5 download attribute
                               var csvUrl = window.URL.createObjectURL(blob);
                               link.setAttribute("href", csvUrl);
                               link.setAttribute("download", fileName + ".csv");
                               link.style.display = "visibility:hidden";
                               document.body.appendChild(link);
                               link.click();
                               document.body.removeChild(link);
                           }           
                       }
           }






















}
