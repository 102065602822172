import { Component, OnInit } from '@angular/core';
import { MeterhistoryService } from 'src/app/service/fleet-service/meterhistory.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SetScreenService } from 'src/app/service/common-service/set-screen.service';

declare var $: any;

@Component({
  selector: 'app-meter-history',
  templateUrl: './meter-history.component.html',
  styleUrls: ['./meter-history.component.css']
})
export class MeterHistoryComponent implements OnInit {
  dlrScreen=false;
  csaScreen=false;
  loggedUserName=''; 
  loggedUserFullName='';
  fromDate = "";
  toDate  = "";
  dealerNameSrch:String="";
  selectedFromDate: any;
  selectedToDate: any;
  serialNumber: String ="";
  customerName: String = "";
  customerAddress: String = "";
  pageNum = 1;
  recPerPage = 10;
  modalPageNum = 1;
  modalRecPerPage = 10;
  sortOrder: String = "";
  sortBy: String = "";
  dlrMeterHistoryList : any[] = [];
  dlrSerialNumberList : any[] = [];
  dlrCustomerNameList : any[] = [];
  csaMeterHistoryList : any[] = []; 
  csaSerialNumberList : any[] = [];
  csaCustomerNameList : any[] = []; 
  totalRecordsDealer;
  totalRecordsCsa;
  totalRecordsSerialNumber;
  totalRecordsCustomerName;
  totalDealerOrders: Array<number>;
  totalCsaOrders: Array<number>;
  totalDealerPages;
  totalCsaPages;
  totalSerialNumberPages;
  totalCustomerNamePages;
  selSrlNum;
  selCustName;
  modalSerialNumber;
  dealerName = "";
  dSelectAll = true;
  dDealerName = true;
  dDate = true;
  dReadingType = true;
  dType = true;
  dTotal = true;
  dSerialNumber = true;
  dCustomer = true;
  dModel = true;
  dAddress = true;
  cSelectAll = true;
  cDealerName = true;
  cDate = true;
  cReadingType = true;
  cType = true;
  cTotal = true;
  cSerialNumber = true;
  cCustomer = true;
  cModel = true;
  cAddress = true; 
  srlNo: any;
  csaOrDlr: any;
  tableLoader = true;
  pgType: any;
  pageType = "";
  isAdmin: string;
  private subscription: Subscription;
  myVar;

  constructor(private router:Router,private activatedRoute:ActivatedRoute, private loginAuthenticationService:LoginAuthenticationService, private _MeterhistoryService : MeterhistoryService,private titleService: Title,private _setCsaService:SetScreenService) {
   }
   
  ngOnInit() {

    this.titleService.setTitle("Meter Reads History");
    this.loggedUserName= this.loginAuthenticationService.getAuthenticatedUser();
    this.loggedUserFullName= this.loginAuthenticationService.getAuthenticatedUserFullName();
    this.isAdmin=	sessionStorage.getItem('Admin');

    this.srlNo = this.activatedRoute
    .queryParams
    .subscribe(params => {
      this.serialNumber = params['sn'];
    });

    this.subscription = this._setCsaService.changeVar.subscribe(message => {
          this.myVar = Number(message);
          if(this.myVar == 0){
                if(this.serialNumber){
                  $(".group-switcher").css({
                        "border-color": "#999",
                        "background-color": "#999 !important",
                        "pointer-events": "none"
                    });
                
                    $("#main-top-nav-switcher").css({ 
                      "border-color": "#999",
                      "background-color": "#999",
                
                  });
                  $("#main-menu-switcher").css({ 
                      "border-color": "#999",
                      "background-color": "#999",
                
                  });
          
              }
                this.csaScreen = true;
                this.dlrScreen = false;
                this.getCsaMeterHistoryList();

          }else if(this.myVar == 1){
            if(this.serialNumber){
                $(".group-switcher").css({
                      "border-color": "#999",
                      "background-color": "#999 !important",
                      "pointer-events": "none"
                  });
              
                  $("#main-top-nav-switcher").css({ 
                    "border-color": "#999",
                    "background-color": "#999",
              
                });
                $("#main-menu-switcher").css({ 
                    "border-color": "#999",
                    "background-color": "#999",
              
                });

            }
            this.dlrScreen = true;
            this.csaScreen = false;
            this.getDlrMeterHistoryList();

            }           
  });      
    this.pageLoad();   
  }

  //for DEALER

  getDlrMeterHistoryList(){
    this.tableLoader = true;
    //let frmDate = this.fromDate;
   // let toDate = this.toDate;
    this._MeterhistoryService.getMeterHistoryDealerList(this.getDlrMeterHistoryParams()).subscribe(data=>{
      this.dlrMeterHistoryList = data.data;
      let totalDealerPages = data.recCount;
      this.dealerName = data.dealerName;
      this.totalRecordsDealer = totalDealerPages;
      totalDealerPages = this.calTotalPages(totalDealerPages);
      this.totalDealerPages = totalDealerPages;
     // this.fromDate = frmDate;
      //this.toDate = toDate;
      this.totalDealerOrders = new Array(totalDealerPages);
      console.log("dlrMeterHistoryList ",this.dlrMeterHistoryList);
      console.log('totalRecordsDealer',this.totalRecordsDealer);
      this.tableLoader = false;
    });
  }

  getDlrSerialNumberList(){
    this._MeterhistoryService.getDlrSerialNumberList(this.getDlrSerialNumberParams()).subscribe(data=>{
      this.dlrSerialNumberList = data.data;
      let totalSerialNumberPages = data.recCount;
      this.totalRecordsSerialNumber = totalSerialNumberPages;
      totalSerialNumberPages = this.calTotalPages(totalSerialNumberPages);
      this.totalSerialNumberPages = totalSerialNumberPages;
      this.totalDealerOrders = new Array(totalSerialNumberPages);
      console.log("dlrSerialNumberList ",this.dlrSerialNumberList);
      console.log('totalRecordsSerialNumber',this.totalRecordsSerialNumber);
    });
  }

  srchDlrSerialNumber(){
    this.serialNumber = "";
    this.getDlrSerialNumberList();
  }

  getDlrCustomerNameList(){
    this._MeterhistoryService.getDlrCustomerNameList(this.getDlrCustomerNameParams()).subscribe(data=>{
      this.dlrCustomerNameList = data.data;
      let totalCustomerNamePages = data.recCount;
      this.totalRecordsCustomerName = totalCustomerNamePages;
      totalCustomerNamePages = this.calTotalPages(totalCustomerNamePages);
      this.totalCustomerNamePages = totalCustomerNamePages;
      this.totalDealerOrders = new Array(totalCustomerNamePages);
      console.log("dlrCustomerNameList ",this.dlrCustomerNameList);
      console.log('totalRecordsCustomerName',this.totalRecordsCustomerName);
    });
  }

  srchDlrCustomerName(){
    this.customerName = "";
    this.getDlrCustomerNameList();
  }

  clearSrlNum(){
    this.serialNumber="";
  }

  clearCustName(){
    this.customerName="";
    this.customerAddress="";
  }

  selectSrlNum(){
    this.serialNumber = this.selSrlNum;
  }

  getDlrMeterHistoryParams()
  {
    if(this.fromDate) {
      this.changeEvent(this.fromDate,'fd');
    }else
    {
      this.selectedFromDate="";
    }
    if(this.toDate) {
      this.changeEvent(this.toDate,'td');
    }else{
      this.selectedToDate="";
    }
    return {
      "dealerName":this.dealerNameSrch,
      "fromDate": this.selectedFromDate,
      "toDate": this.selectedToDate,
      "serialNumber" : this.serialNumber,
      "customerName" : this.customerName,
      "address" : this.customerAddress,
      "pageNum": this.pageNum,
      "recPerPage": this.recPerPage,
      "sortBy": this.sortBy,
      "sortOrder": this.sortOrder
    }
  }

  getDlrSerialNumberParams()
  {
    return{ 
      "serialNumber" : this.serialNumber,
      "pageNum": this.modalPageNum,
      "recPerPage": this.recPerPage,
      "sortBy": this.sortBy,
      "sortOrder": this.sortOrder
    }
  }

  getDlrCustomerNameParams(){
    return{ 
      "customerName" : this.customerName,
      "pageNum": this.modalPageNum,
      "recPerPage": this.recPerPage,
      "sortBy": this.sortBy,
      "sortOrder": this.sortOrder
    }
  }
  
  dowloadDealer() {
    let totalDlrMeterHistoryList = [];
    const dPageNumber = this.pageNum;
    this.recPerPage = 10000;
    this.pageNum = 1;
      this._MeterhistoryService.getMeterHistoryDealerList(this.getDlrMeterHistoryParams()).subscribe(data => {
      totalDlrMeterHistoryList = data.data;
      this._MeterhistoryService.jsonToExcel(totalDlrMeterHistoryList,'Dealer MeterHistory', true);
      this.recPerPage = 10;
      this.pageNum = dPageNumber;
    
    });  
  }

  changeDSelectAll() {
    if(this.dSelectAll) {
     this.dDealerName = true;
     this.dDate= true;
     this.dReadingType = true;
     this.dType = true;
     this.dTotal = true;
     this.dSerialNumber = true; 
     this.dCustomer = true;
     this.dModel = true; 
     this.dAddress = true;

    }else {
      this.dDealerName = false;
     this.dDate= false;
     this.dReadingType = false;
     this.dType = false;
     this.dTotal = false;
     this.dSerialNumber = false; 
     this.dCustomer = false;
     this.dModel = false; 
     this.dAddress = false;
    } 
  }

  setCustomer(custName){
    this.customerName = custName.customerName;
    this.customerAddress = custName.address;
  }

  setSerial(srlNumber){
    this.serialNumber = srlNumber.serialNumber;
  }

  //for CSA View 

  getCsaMeterHistoryList(){
    this.tableLoader = true;
    //let frmDate = this.fromDate;
   // let toDate = this.toDate;
    this._MeterhistoryService.getMeterHistoryCsaList(this.getCsaMeterHistoryParams()).subscribe(data=>{
      this.csaMeterHistoryList = data.data;
      let totalCsaPages = data.recCount;
      this.dealerName = data.dealerName;
      this.totalRecordsCsa = totalCsaPages;
      totalCsaPages = this.calTotalPages(totalCsaPages);
      this.totalCsaPages = totalCsaPages;
      //this.fromDate = frmDate;
      //this.toDate = toDate;
      this.totalCsaOrders = new Array(totalCsaPages);
      console.log('csaFleetList list',this.csaMeterHistoryList);
      console.log('totalRecordsCsa',this.totalRecordsCsa);
      this.tableLoader = false;
    });
  }

  getCsaSerialNumberList(){
    this._MeterhistoryService.getCsaSerialNumberList(this.getCsaSerialNumberParams()).subscribe(data=>{
      this.csaSerialNumberList = data.data;
      let totalSerialNumberPages = data.recCount;
      this.totalRecordsSerialNumber = totalSerialNumberPages;
      totalSerialNumberPages = this.calTotalPages(totalSerialNumberPages);
      this.totalSerialNumberPages = totalSerialNumberPages;
      this.totalCsaOrders = new Array(totalSerialNumberPages);
      console.log("csaSerialNumberList ",this.csaSerialNumberList);
      console.log('totalRecordsSerialNumber',this.totalRecordsSerialNumber);
    });
  }

  srchCsaSerialNumber(){
    this.serialNumber = "";
    this.getCsaSerialNumberList();
  }

  getCsaCustomerNameList(){
    this._MeterhistoryService.getCsaCustomerNameList(this.getCsaCustomerNameParams()).subscribe(data=>{
      this.csaCustomerNameList = data.data;
      let totalCustomerNamePages = data.recCount;
      this.totalRecordsCustomerName = totalCustomerNamePages;
      totalCustomerNamePages = this.calTotalPages(totalCustomerNamePages);
      this.totalCustomerNamePages = totalCustomerNamePages;
      this.totalCsaOrders = new Array(totalCustomerNamePages);
      console.log("csaCustomerNameList ",this.csaCustomerNameList);
      console.log('totalRecordsCustomerName',this.totalRecordsCustomerName);
    });
  }

  srchCsaCustomerName(){
    this.customerName = "";
    this.getCsaCustomerNameList();
  }

  getCsaMeterHistoryParams()
  {
    if(this.fromDate) {
      this.changeEvent(this.fromDate,'fd');
    }else
    {
      this.selectedFromDate="";
    }
    if(this.toDate) {
      this.changeEvent(this.toDate,'td');
    }else{
      this.selectedToDate="";
    }
    return {
      "dealerName":this.dealerNameSrch,
      "fromDate": this.selectedFromDate,
      "toDate": this.selectedToDate,
      "serialNumber" : this.serialNumber,
      "customerName" : this.customerName,
      "address" : this.customerAddress,
      "pageNum": this.pageNum,
      "recPerPage": this.recPerPage,
      "sortBy": this.sortBy,
      "sortOrder": this.sortOrder
    }
  }

  getCsaSerialNumberParams()
  {
    return{ 
      "serialNumber" : this.serialNumber,
      "pageNum": this.modalPageNum,
      "recPerPage": this.recPerPage,
      "sortBy": this.sortBy,
      "sortOrder": this.sortOrder
    }
  }

  getCsaCustomerNameParams(){
    return{ 
      "customerName" : this.customerName,
      "pageNum": this.modalPageNum,
      "recPerPage": this.recPerPage,
      "sortBy": this.sortBy,
      "sortOrder": this.sortOrder
    }
  }

  downloadCsa() {
    let totalCsaMeterHistoryList = [];
    const dPageNumber = this.pageNum;
    this.recPerPage = 10000;
    this.pageNum = 1;
      this._MeterhistoryService.getMeterHistoryCsaList(this.getCsaMeterHistoryParams()).subscribe(data => {
      totalCsaMeterHistoryList = data.data;
      this._MeterhistoryService.jsonToExcel(totalCsaMeterHistoryList,'CSA MeterHistory', true);
      this.recPerPage = 10;
      this.pageNum = dPageNumber;
    
    });  
  }

  changeCSelectAll() {
    if(this.cSelectAll) {
     this.cDealerName = true;
     this.cDate= true;
     this.cReadingType = true;
     this.cType = true;
     this.cTotal = true;
     this.cSerialNumber = true; 
     this.cCustomer = true;
     this.cModel = true; 
     this.cAddress = true;

    }else {
     this.cDealerName = false;
     this.cDate= false;
     this.cReadingType = false;
     this.cType = false;
     this.cTotal = false;
     this.cSerialNumber = false; 
     this.cCustomer = false;
     this.cModel = false; 
     this.cAddress = false;
    } 
  }

  ///////////

  clearButton(){
    this.dealerNameSrch = "";
    this.serialNumber = "";
    this.customerName = "";
    this.customerAddress = "";
    this.fromDate = "";
    this.toDate = "";
    this.selectedFromDate = "";
    this.selectedToDate = "";
  }

  setSortOrder(sortBy,sortOrder,type){
    this.sortBy = sortBy;
    this.sortOrder = sortOrder;
    if(type=="dealer"){
      this.getDlrMeterHistoryList();
    }else{
      this.getCsaMeterHistoryList();
    }
  }

  changeEvent(event,date){ 
    // Return date object
    let dd = event.getDate();
    let mm = event.getMonth() + 1; //January is 0!
    let yyyy = event.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    if (date=='fd'){
        this.selectedFromDate =  mm + '/' + dd + '/' + yyyy ; 
    }
    if (date=='td'){
        this.selectedToDate =  mm + '/' + dd + '/' + yyyy ; 
      }
    console.log(event);
    console.log('dd',dd);
    return;
  }

  calTotalPages (pages){
    let totalPages = pages/10;
    let roundedTotalPages = Math.round(totalPages);
  
    
    if (totalPages > roundedTotalPages) {
      totalPages = roundedTotalPages + 1;
    }else {
      totalPages = roundedTotalPages;
    }
  
    console.log('totalPages',totalPages);
    return totalPages;
   }

  setPage (param,i, event: any) {
    event.preventDefault();     
    if(param=='dealer'){
     this.pageNum= i;
     this.getDlrMeterHistoryList();
    }
    if(param=='csa')
    {
      this.pageNum= i;
      this.getCsaMeterHistoryList();
    }
    if(param=='dlrSerialNumber')
    {
     this.modalPageNum = i;
     this.getDlrSerialNumberList();
    }
    if(param=='csaSerialNumber')
    {
     this.modalPageNum = i;
     this.getCsaSerialNumberList();
    }
    if(param=='dlrCustomerName')
    {
     this.modalPageNum = i;
     this.getDlrCustomerNameList();
    }
    if(param=='csaCustomerName')
    {
     this.modalPageNum = i;
     this.getCsaCustomerNameList();
    }  
  }
  
  setNextPage (param,event: any) {  
   event.preventDefault(); 
   if(param=='dealer'){
    this.pageNum++;
    this.getDlrMeterHistoryList();
   }
   if(param=='csa')
   {
     this.pageNum++;
     this.getCsaMeterHistoryList();
   }
   if(param=='dlrSerialNumber')
   {
    this.modalPageNum++;
    this.getDlrSerialNumberList();
   }
   if(param=='csaSerialNumber')
   {
    this.modalPageNum++;
    this.getCsaSerialNumberList();
   }
   if(param=='dlrCustomerName')
   {
    this.modalPageNum++;
    this.getDlrCustomerNameList();
   }
   if(param=='csaCustomerName')
   {
    this.modalPageNum++;
    this.getCsaCustomerNameList();
   }   
  }

  setPrevPage (param,event: any) {
  event.preventDefault();   
  if(param=='dealer'){
    if (this.pageNum > 1){
      this.pageNum--;
     }
     this.getDlrMeterHistoryList();
    }
    if(param=='csa')
    {
      if (this.pageNum > 1){
        this.pageNum--;
       }
      this.getCsaMeterHistoryList();
    }
    if(param=='dlrSerialNumber')
    {
      if (this.modalPageNum > 1){
        this.modalPageNum--;
       }
     this.getDlrSerialNumberList();
    }
    if(param=='csaSerialNumber')
    {
      if (this.modalPageNum > 1){
        this.modalPageNum--;
       } 
     this.getCsaSerialNumberList();
    }
    if(param=='dlrCustomerName')
    {
      if (this.modalPageNum > 1){
        this.modalPageNum--;
       }
     this.getDlrCustomerNameList();
    }
    if(param=='csaCustomerName')
    {
      if (this.modalPageNum > 1){
        this.modalPageNum--;
       }
     this.getCsaCustomerNameList();
    }  
  
  }
  
  setFirstPage (param:string,event: any) {
   event.preventDefault();
   if(param=='dealer'){
    this.pageNum = 1;
    this.getDlrMeterHistoryList();
   }
   if(param=='csa')
   {
     this.pageNum = 1;
     this.getCsaMeterHistoryList();
   }
   if(param=='dlrSerialNumber')
   {
    this.modalPageNum = 1;
    this.getDlrSerialNumberList();
   }
   if(param=='csaSerialNumber')
   {
    this.modalPageNum = 1;
    this.getCsaSerialNumberList();
   }
   if(param=='dlrCustomerName')
   {
    this.modalPageNum = 1;
    this.getDlrCustomerNameList();
   }
   if(param=='csaCustomerName')
   {
    this.modalPageNum = 1;
    this.getCsaCustomerNameList();
   } 
  
  }
  
  setLastPage (param,i,event: any) {
    event.preventDefault();
   if(param=='dealer'){
    this.pageNum = i;
    this.getDlrMeterHistoryList();
   }
   if(param=='csa')
   {
     this.pageNum = i;
     this.getCsaMeterHistoryList();
   }
   if(param=='dlrSerialNumber')
   {
    this.modalPageNum = i;
    this.getDlrSerialNumberList();
   }
   if(param=='csaSerialNumber')
   {
    this.modalPageNum = i;
    this.getCsaSerialNumberList();
   }
   if(param=='dlrCustomerName')
   {
    this.modalPageNum = i;
    this.getDlrCustomerNameList();
   }
   if(param=='csaCustomerName')
   {
    this.modalPageNum = i;
    this.getCsaCustomerNameList();
   } 
  
  }

  pageLoad() {
      $("#page-loader-overlay")
        .delay(800)
        .fadeOut();
      $("#delay-loader")
        .delay(1000)
        .fadeOut();
      
      }     

    ngOnDestroy() {
      if (this.subscription) {
      this.subscription.unsubscribe();
      }
  
    }


}

    
