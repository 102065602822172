import { Injectable } from '@angular/core';
import { CreateServiceRequest } from 'src/app/models/CreateServiceRequest';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Util } from 'src/app/app.util';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';


const httpOptions = {
  headers: new HttpHeaders({
  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};
const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})
export class ServiceCreateNewService {   
 
 
  defaultUrl : String;
  
  constructor(private http: HttpClient,private util : Util) { 
    this.defaultUrl = util.getBaseUrl();
  }

  getProblemTypes(inputParams):Observable<any>{
    let FN = "[getProblemTypes]";

    let urlGPT = this.defaultUrl + "serviceRequest/gpt";
    let params = new URLSearchParams();
    for (let key in inputParams) {
      params.set(key, inputParams[key])
    }
  //  console.log(FN + "params : " + params.toString());

    return this.http
      .post(urlGPT, params.toString(), httpOptions)
      .pipe(catchError(this.handleError(FN)));
            
  }

  getProblemDescriptions(inputParams):Observable<any>{
    let FN = "[getProblemDescriptions]";

    let urlGPD = this.defaultUrl + "serviceRequest/gpd";
    
    let params = new URLSearchParams();
    for (let key in inputParams) {
      params.set(key, inputParams[key])
    }
   
   // console.log(FN + "params : " + params.toString());

    return this.http
      .post(urlGPD, params.toString(), httpOptions)
      .pipe(catchError(this.handleError(FN)));
            
  }

  getProblemCodes(inputParams):Observable<any>{
    let FN = "[getProblemCodes]";
    let urlGPC = this.defaultUrl + "serviceRequest/gpc";
    
    let params = new URLSearchParams();
    for (let key in inputParams) {
      params.set(key, inputParams[key])
    }
   // console.log(FN + "params : " + params.toString());

    return this.http
      .post(urlGPC, params.toString(), httpOptions)
      .pipe(catchError(this.handleError(FN)));
            
  }


  getSerialList(inputParams): Observable<any>{
    let FN = "[getSerialList]";
    let urlSL = this.defaultUrl + "serviceRequest/gsl";
    
    let params = new URLSearchParams();
    for (let key in inputParams) {
      params.set(key, inputParams[key])
    }
   // console.log(FN + "params : " + params.toString());

    return this.http
      .post(urlSL, params.toString(), httpOptions)
      .pipe(catchError(this.handleError(FN)));

  }

  checkDuplicateSR(inputParams):Observable<any>{
    let FN = "[checkDuplicateSR]";
    let urlCDS = this.defaultUrl + "serviceRequest/cds";
    
    let params = new URLSearchParams();
    for (let key in inputParams) {
      params.set(key, inputParams[key])
    }
   // console.log(FN + "params : " + params.toString());

    return this.http
      .post(urlCDS, params.toString(), httpOptions)
      .pipe(catchError(this.handleError(FN)));
            
  }



  createServiceRequest(csr): Observable<any> {

    let FN = "[createServiceRequest]";
    let urlSor = this.defaultUrl + "serviceRequest/csr"; 

    console.log('csr: ', csr);
    return this.http
      .post(urlSor, csr, httpJsonOptions)
      .pipe(catchError(this.handleError(FN)));

  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {

      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
 
}
