import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  loggedUserName='';
  alphas:string[];
  constructor(private route:ActivatedRoute,private loginAuthenticationService:LoginAuthenticationService) { }

  ngOnInit() {
    $('#sidebarCollapse').on('click', function () {
      $('#sidebar').toggleClass('active');
      $(this).toggleClass('active');
    });
    this.loggedUserName= this.loginAuthenticationService.getAuthenticatedUser();
   // this.loggedUserName=this.route.snapshot.params['loggedUserName'];
    console.log("LoggedIn user:"+this.loggedUserName);
    
    let username = this.loggedUserName;    
    
   
    
  }

}