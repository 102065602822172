import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './common/login/login.component';
import { HomeComponent } from './common/home/home.component';
import { LogoutComponent } from './common/logout/logout.component';
import { ErrorComponent } from './common/error/error.component';
import { OrderQueryComponent } from './order-mgmt/order-query/order-query.component';
import { RouteGuardService } from './service/common-service/route-guard.service';
import { OrderDetailDealerComponent } from './order-mgmt/order-detail-dealer/order-detail-dealer.component';
import { OrderCreateNewComponent } from './order-mgmt/order-create-new/order-create-new.component';
import { DashboardComponent } from './common/dashboard/dashboard.component';
import { OrderDetailCsaComponent } from './order-mgmt/order-detail-csa/order-detail-csa.component';
import { FleetComponent } from './fleet/fleet/fleet.component';
import { ServiceRequestComponent } from './service-request/service-request/service-request.component';
import { AccessDeniedComponent } from './common/access-denied/access-denied.component';
import { ServiceCreateNewComponent } from './service-request/service-create-new/service-create-new.component';
import { ServiceCsaCreateNewComponent } from './service-request/service-csa-create-new/service-csa-create-new.component';
import { MeterHistoryComponent } from './fleet/meter-history/meter-history.component';
import { OrderApprovalsComponent } from './order-mgmt/order-approvals/order-approvals.component';
import { AcknowledgementComponent } from './fleet/acknowledgement/acknowledgement.component';
import { FleetDetailsComponent } from './fleet/fleet-details/fleet-details.component';
import { ServiceDetailsComponent } from './service-request/service-details/service-details.component';
import { MeterEntryComponent } from './fleet/meter-entry/meter-entry.component';
import { CompensationDetailsComponent } from './finance/compensation-details/compensation-details.component';
import { FinanceComponent } from './finance/finance/finance.component';
import { FaqComponent } from './common/faq/faq.component';

const routes: Routes = [
  { path: '', component: LoginComponent,data:{ showHeader: false, showSidebar: false, role: 'EP_COMMON'} },
  { path: 'login', component: LoginComponent,  data: { showHeader: false, showSidebar: false, role: 'EP_COMMON'} },
  { path: 'accessDenied', component: AccessDeniedComponent, data: { showHeader: true, showSidebar: true, role: 'EP_COMMON'} },
  { path: 'home', component: HomeComponent, canActivate:[RouteGuardService]},
  { path: 'dashboard', component: DashboardComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: true ,role: 'EP_COMMON'}},
  { path: 'orderquery', component: OrderQueryComponent, canActivate:[RouteGuardService],data: { showHeader: true, showSidebar: true  ,role: 'EP_OM'}},
  { path: 'orderdtldealer/:dn', component: OrderDetailDealerComponent, canActivate:[RouteGuardService],data: { showHeader: true, showSidebar: true  ,role: 'EP_OM' }},
  { path: 'orderdtlcsa', component: OrderDetailCsaComponent, canActivate:[RouteGuardService],data: { showHeader: true, showSidebar: true  ,role: 'EP_OM'}},
  { path: 'createOrder', component: OrderCreateNewComponent, canActivate:[RouteGuardService],data: { showHeader: true, showSidebar: true  ,role: 'EP_OM'}},
  { path: 'orderapprovals', component: OrderApprovalsComponent, canActivate:[RouteGuardService],data: { showHeader: true, showSidebar: true  ,role: 'EP_OM'},
   children: [
      {path: 'orderdtldealer/:dn', component: OrderDetailDealerComponent},
      {path: 'createOrder/:id/:ad', component: OrderCreateNewComponent},
    ]},
  { path: 'orderapprovals/:cd', component: OrderApprovalsComponent, canActivate:[RouteGuardService],data: { showHeader: true, showSidebar: true  ,role: 'EP_OM'},
   children: [
      {path: 'orderdtldealer/:dn', component: OrderDetailDealerComponent},
      {path: 'createOrder/:id/:ad', component: OrderCreateNewComponent},
    ]},
  { path: 'fleet', component: FleetComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: true ,role: 'EP_FLT' }},
  { path: 'meterhistory', component: MeterHistoryComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: true ,role: 'EP_FLT'}},
  { path: 'meterentry', component: MeterEntryComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: true ,role: 'EP_FLT'}},
  { path: 'acknowledgement', component: AcknowledgementComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: true ,role: 'EP_FLT' }},  
  { path: 'fleetdetails/:type/:sn', component: FleetDetailsComponent, canActivate:[RouteGuardService],data: { showHeader: true, showSidebar: true  ,role: 'EP_FLT'}},
  // { path: 'meterhistory/:cd', component: MeterHistoryComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: true ,role: 'EP_FLT'}},
  { path: 'service', component: ServiceRequestComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: true ,role: 'EP_SRV' }},
  //{ path: 'createSRCsa', component: ServiceCsaCreateNewComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: true ,role: 'EP_SRV' }},
  { path: 'createSR', component: ServiceCreateNewComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: true ,role: 'EP_SRV' }},
  { path: 'servicedetails/:type/:rn', component: ServiceDetailsComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: true ,role: 'EP_SRV' }},
  { path: 'finance', component: FinanceComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: true ,role: 'EP_FIN' }},
  { path: 'compensationdetails', component: CompensationDetailsComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: true ,role: 'EP_FIN' }},
  { path: 'logout', component: LogoutComponent, canActivate:[RouteGuardService],data:{ role: 'EP_COMMON'}},  
  { path: 'faq', component: FaqComponent, canActivate:[RouteGuardService], data: { showHeader: true, showSidebar: true ,role: 'EP_COMMON'}},
  { path: '**', component: ErrorComponent }
];

@NgModule({
   imports: [RouterModule.forRoot(routes,{ useHash: true, scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled',
       scrollOffset: [0, 64]})],
  //imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {



 }
