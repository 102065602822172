import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { FleetService } from '../../service/fleet-service/fleet.service';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';
import { Util } from 'src/app/app.util';
import { SetScreenService } from 'src/app/service/common-service/set-screen.service';
import { Subscription } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-fleet',
  templateUrl: './fleet.component.html',
  styleUrls: ['./fleet.component.css']
})
export class FleetComponent implements OnInit, OnDestroy  {

  csaTab: string ='';
  dealerTab: string ='CSA-tab-active dealerTabs';
  terminateTab: string ='';
  reqSerialNumber: any;
  reqDealerCode: any;
  reqContractNumber: any;
  terminateReqMsg: string;
  reqDealerName: any;
  successMsg: string;
  errorMsg: string;
  
  

  setScreen(num)
  {
   //let num = Number(sessionStorage.getItem("setScreen"));
    //this.myVariable=num;
    //  if(num==0){
    //  this._setCsaService.setCSACSS();
    //  } else{
    //  this._setCsaService.setDealersCSS();
    //  }

     
    this._setCsaService.changeMyVar(num);
   
 }


  setTab(event:any){
    console.log(event);
    //this.pageNum = 1;
    if(event == 'dealer'){
    this._setCsaService.setDealersCSS();
     this.pageNum = 1;
     this.dealerTab = 'CSA-tab-active dealerTabs';
     this.csaTab = '';
     this.terminateTab = '';
     this.searchDealerFleet(this.getDealerSearchParam());
     //this.setDealersCSS();
     //sessionStorage.setItem("setScreen", "1");

     //this.setPgLinks(10000,1,10,10);
    }else if (event == 'csa') {
      this._setCsaService.setCSACSS();
     //this._setCsaValueService.changeMyVar("1");
     this.pageNum = 1;
     this.csaTab = 'CSA-tab-active';
     this.dealerTab = '';
     this.terminateTab = '';
     //this.searchCsaOrder(this.getCsaSearchParam());
     this.searchCsaFleet(this.getCsaSearchParam());
     //this.setCSACSS();
     //this._setCsaService.changeMyVar(1);
     //sessionStorage.setItem("setScreen", "0");
    }else if (event == 'terminate') {
      this._setCsaService.setCSACSS();
     //this._setCsaValueService.changeMyVar("1");
     this.pageNum = 1;
     this.terminateTab = 'CSA-tab-active';
     this.csaTab = '';
     this.dealerTab = '';
     //this.searchCsaOrder(this.getCsaSearchParam());
     this.searchCsaFleetTerminateReq(this.getCsaTerminateReqSearchParam());
     //this.setCSACSS();
     //this._setCsaService.changeMyVar(1);
     //sessionStorage.setItem("setScreen", "0");
    }

  }

//   setDealersCSS() {
//     $("#side-nav-logo-button-wrapper,#mobile-menu-modal-header").css("background", " url('assets/img/dealers-bg.jpg')");
//     $(".site-logo").attr("src", "assets/img/ep-dealers-logo.png");
//     $(".csa-page-header-title").hide();
//     //$(".csa-page-header-title").show();
//     $("#main-top-nav-wrapper-clone").css("background", "#7395AE");
//     $("#main-side-nav,#mobile-menu-modal-body").css("background", "#5d5c61");
//     $(".main-top-navbar").css("border-top", "1px solid #fff");
//     $(".fa-ellipsis-v").css("color", "#396f96");
//     $("#main-side-nav .inner ul li.side-menu-li,#mobile-menu-links ul li.mobile-menu-li,.single-link-side-menu-li,.single-link-mobile-menu-li").css({ "border": "1px solid #1b6d85", "background-color": "#7395ae", "color": "#fff" });

  
//         $(".side-less-icons-wrapper .side-more-icons").css("color", "rgb(38, 154, 188)");

//         $(".side-less-icons-wrapper .side-more-icons").css("color", "#fff");

//     $(".side-more-icons-wrapper").hover(() =>{
//         $(".side-more-icons-wrapper .side-more-icons").css("color", "rgb(38, 154, 188)");
//     }, () =>{
//         $(".side-more-icons-wrapper .side-more-icons").css("color", "#fff");
//     });
//     $(".side-menu-button-wrapper i").hover(() => {
//         $(this).css({ "color": "rgb(38, 154, 188)", "border": "3px solid rgb(38, 154, 188)" });
//     }, () =>{
//         $(this).css({ "color": "#fff", "border": "3px solid #fff" });
//     });
//     $(".tooltip .tooltip-inner").css("border-left", "7px solid rgb(38, 154, 188)");
//     $(".group-switcher").css({ "border": "1px solid #2aabd1", "background-color": "#2286a3", "pointer-events": "auto" });
//     $(".side-menu-wrapper-inner").css("border-top", "1px solid #777");
//     //$(".main-csa-content-wrapper,.csa-page-header-title").show();
//     $(".main-dealers-content-wrapper,.dealer-page-header-title").fadeIn();
//     $(".btn-primary").css({ "border-color": "#2aabd1", "background-color": "#2286a3", "color": "#fff" });
//     $(".result-table tr td i").css({ "color": "#2aabd1" });
//     $("html,body").css({ "background-color": "#ddd" });
//     $(".main-top-navbar-left-content,.main-top-navbar-links,.group-switcher-inner").css({ "color": "#fff" });
//     $(".result-table tr th").css({ "background-color": "#7395ae" });

//     $('.sm-switcher .group-switcher-inner').css("margin-left", "-55px");
//     $('.md-switcher .group-switcher-inner').css("margin-left", "-60px");

// }

// setCSACSS() {
//   $("#side-nav-logo-button-wrapper,#mobile-menu-modal-header").css("background", " url('assets/img/csa-bg.jpg')");
//   $(".site-logo").attr("src", "assets/img/ep-csa-logo.png");
//   $("#main-top-nav-wrapper-clone").css("background", "#555");
//   $("#main-side-nav,#mobile-menu-modal-body").css("background", "#333");
//   $(".main-top-navbar").css("border-top", "1px solid rgb(186, 179, 171)");
//   $(".fa-ellipsis-v").css("color", "rgb(204, 204, 204)");
//   $("#main-side-nav .inner ul li.side-menu-li,#mobile-menu-links ul li.mobile-menu-li,.single-link-side-menu-li,.single-link-mobile-menu-li").css({ "border": "1px solid #333", "background-color": "#dbd5ce", "color": "#59534d" });

//   $(".side-less-icons-wrapper").hover(() => {
//       $(".side-less-icons-wrapper .side-more-icons").css("color", "#dbd5ce");
//   }, () =>{
//       $(".side-less-icons-wrapper .side-more-icons").css("color", "#fff");
//   });
//   $(".side-more-icons-wrapper").hover(() => {
//       $(".side-more-icons-wrapper .side-more-icons").css("color", "#dbd5ce");
//   }, () => {
//       $(".side-more-icons-wrapper .side-more-icons").css("color", "#fff");
//   });

//   $(".side-menu-button-wrapper i").hover(() => {
//       $(this).css({ "color": "#dbd5ce", "border": "3px solid #dbd5ce" });
//   }, () =>{
//       $(this).css({ "color": "#fff", "border": "3px solid #fff" });
//   });
//   $(".tooltip .tooltip-inner").css("border-left", "7px solid #dbd5ce");
//   $(".group-switcher").css({ "border": "1px solid #969089", "background-color": "#999","pointer-events": "auto" });
//   $(".side-menu-wrapper-inner").css("border-top", "1px solid #ccc");
//   $(".main-dealers-content-wrapper,.dealer-page-header-title").hide();
//   $(".main-csa-content-wrapper,.csa-page-header-title").fadeIn();
//   $(".btn-primary").css({ "border-color": "#969089", "background-color": "#999", "color": "#fff" });
//   $(".result-table tr td i").css({ "color": "#333", "border": "solid 1px #dbd5ce" });
//   $("html,body").css({ "background-color": "#ebe7e4" });
//   $(".main-top-navbar-left-content,.main-top-navbar-links").css({ "color": "#fff" });
//   $(".group-switcher-inner").css({ "color": "#fff" });
//   $(".result-table tr th").css({ "background-color": "#333" });
//   $('.sm-switcher .group-switcher-inner').css("margin-left", "5px");
//   $('.md-switcher .group-switcher-inner').css("margin-left", "0");

// }

loggedUserName=''; 
loggedUserFullName='';
dealerFleetList = [];
csaFleetList = [];
csaFleetTerminateList = [];
totalRecordsDealer;
totalDealerOrders: Array<number>;
totalDealerPagers;
totalRecordsCsa;
totalRecordsTerminate;
totalTerminatePagers;
totalCsaOrders: Array<number>;
totalTerminateReq: Array<number>;
totalCsaPagers;
selectedSortBy;
selectedSortOrder;
serialNumber;
modelNumber;
customerName;
installLocation;
dSelectAll = true;
dAction = true;
dSerial = true;
dModel = true;
dCustomerName = true;
dInstallLocation = true;
dDealerName = true;
dOwnedBy=true;
dCompBy =true;
ownedBy;
compBy;
cSelectAll = true;
cAction = true;
cSerial = true;
cModel = true;
cCustomerName = true;
cInstallLocation = true;
cDealerName = true;
tSelectAll = true;
tReqStatus = true;
tSerialNumber = true;
tServicedBy = true;
tContractNumber = true;
tContractStatus = true;
tTerminationDate = true;
tNotes = true;
dealerName="";
pageNum = 1;
recPerPage = 10;
tableLoader = true;
isAdmin: any; 
dealerNameSrch:String="";
comments:String="";
returnStatus:String="";
returnMsg:String="";
contractNumber:String="";
dealerCode:String="";
requestSent = false;
terminationFlag:String="";



clearButton() {
  this.dealerNameSrch = "";
  this.serialNumber = "";
  this.modelNumber = '';
  this.customerName = "";
  this.customerName = "";
  this.installLocation = "";

}

changeDSelectAll() {
  if(this.dSelectAll) {
    this.dDealerName = true;
   this.dAction = true;
   this.dSerial = true;
   this.dModel = true;
   this.dCustomerName = true; 
   this.dInstallLocation = true;
   this.dOwnedBy=true;
   this.dCompBy =true;
   
  }else {
    this.dDealerName = false;
    this.dAction = false;
    this.dSerial = false;
    this.dModel = false;
    this.dCustomerName = false; 
    this.dInstallLocation = false;
    this.dOwnedBy=false;
    this.dCompBy =false;
  } 
}

changeCSelectAll() {
  if(this.cSelectAll) {
    this.cDealerName = true;
   this.cAction = true;
   this.cSerial = true;
   this.cModel = true;
   this.cCustomerName = true; 
   this.cInstallLocation = true;
   
  }else {
    this.cDealerName = false;
    this.cAction = false;
    this.cSerial = false;
    this.cModel = false;
    this.cCustomerName = false; 
    this.cInstallLocation = false;
  } 
}

changeTSelectAll() {
  if(this.tSelectAll) {
    this.tReqStatus = true;
   this.tSerialNumber = true;
   this.tServicedBy = true;
   this.tContractNumber = true;
   this.tContractStatus = true; 
   this.tTerminationDate = true;
   this.tNotes = true;   
  }else {
    this.tReqStatus = false;
    this.tSerialNumber = false;
    this.tServicedBy = false;
    this.tContractNumber = false;
    this.tContractStatus = false; 
    this.tTerminationDate = false;
    this.tNotes = false;
  } 
}


getDealerSearchParam () {
  

return {
    "dealerName":this.dealerNameSrch,
    "serialNumber": this.serialNumber,
    "customerName": this.customerName,
    "installLocation": this.installLocation,
    "model": this.modelNumber,
    //"orderType": "DEALER",
    "pageNum": this.pageNum,
    "recPerPage":this.recPerPage,
    "sortBy": this.selectedSortBy,
    "sortOrder": this.selectedSortOrder
  }
  
}


getCsaSearchParam () {
  

  return {
      "dealerName":this.dealerNameSrch,
      "serialNumber": this.serialNumber,
      "customerName": this.customerName,
      "installLocation": this.installLocation,
      "model": this.modelNumber,
      "pageNum": this.pageNum,
      "recPerPage":this.recPerPage,
      "sortBy": this.selectedSortBy,
      "sortOrder": this.selectedSortOrder
    }
    
  }

  getCsaTerminateReqSearchParam () {
  

    return {
        "dealerName":this.dealerNameSrch,
        "serialNumber": this.serialNumber,
        "customerName": this.customerName,
        "installLocation": this.installLocation,
        "model": this.modelNumber,
        "pageNum": this.pageNum,
        "recPerPage":this.recPerPage,
        "sortBy": this.selectedSortBy,
        "sortOrder": this.selectedSortOrder
      }
      
    }




calTotalPages (pages){
  let totalPages = pages/10;
  let roundedTotalPages = Math.round(totalPages);

  
  if (totalPages > roundedTotalPages) {
    totalPages = roundedTotalPages + 1;
  }else {
    totalPages = roundedTotalPages;
  }

  console.log('totalPages',totalPages);
  return totalPages;
 }

searchDealerFleet(params) { 

  this.tableLoader = true;

  this.fleetService.getFleetDealerList(params).subscribe(data => {
  this.dealerFleetList = data.data;
  let totalDealerPages = data.recCount;
  this.dealerName = data.dealerName;
  this.totalRecordsDealer = totalDealerPages;
  totalDealerPages = this.calTotalPages(totalDealerPages);
  this.totalDealerPagers = totalDealerPages;

  //this.totalDealerOrders = new Array(data['recCount']);
  this.totalDealerOrders = new Array(totalDealerPages);
  console.log('dealerFleetList list',this.dealerFleetList);
  console.log('totalRecordsDealer',this.totalRecordsDealer);
  this.tableLoader = false;
  //console.log('totalRecordsDealer ',this.totalRecordsDealer );
  //console.log(JSON.stringify(this.csaOrderList));

});
}

searchCsaFleet(params) { 
  this.tableLoader = true;

  this.fleetService.getFleetCsaList(params).subscribe(data => {
  this.csaFleetList = data.data;
  let totalCsaPages = data.recCount;
  //this.dealerName = data.dealerName;
  this.totalRecordsCsa = totalCsaPages;
  totalCsaPages = this.calTotalPages(totalCsaPages);
  this.totalCsaPagers = totalCsaPages;

  //this.totalDealerOrders = new Array(data['recCount']);
  this.totalCsaOrders = new Array(totalCsaPages);
  console.log('csaFleetList list',this.csaFleetList);
  console.log('totalRecordsCsa',this.totalRecordsCsa);
  this.tableLoader = false;
  //console.log('totalRecordsDealer ',this.totalRecordsDealer );
  //console.log(JSON.stringify(this.csaOrderList));

});
}

searchCsaFleetTerminateReq(params) { 
  this.tableLoader = true;

  this.fleetService.getFleetTerminateReqList(params).subscribe(data => {
  this.csaFleetTerminateList = data.data;
  let totalCsaPages = data.recCount;
  this.totalRecordsTerminate = totalCsaPages;
  totalCsaPages = this.calTotalPages(totalCsaPages);
  this.totalTerminatePagers = totalCsaPages;

  this.totalTerminateReq = new Array(totalCsaPages);
  console.log('termination request list',this.csaFleetList);
  console.log('totalRecordsTerminate',this.totalRecordsTerminate);
  this.tableLoader = false;

});
}

setSortOrder(sortBy,sortOrder,type) {
  this.selectedSortBy = sortBy;
  this.selectedSortOrder = sortOrder;
  if(type=="dealer"){
  this.searchDealerList();    
  }if(type=="csaTerminate"){
    this.searchTerminateReqList();
  }else {
    this.searchCsaList();
  }

}


setPage (param,i, event: any) {
  event.preventDefault();
  this.pageNum= i;
  if(param=='dealer'){
   this.searchDealerList();
 }if(param=="csaTerminate"){
  this.searchTerminateReqList();
  }else{
  this.searchCsaList();
  }

}

setNextPage (param,event: any) {
 event.preventDefault();
 this.pageNum++;
 if(param=='dealer'){
   this.searchDealerList();
 }if(param=='csaTerminate'){
  this.searchTerminateReqList();
  }else{
 this.searchCsaList();
 }


}
setPrevPage (param,event: any) {
 event.preventDefault();
 if (this.pageNum > 1){
 this.pageNum--;
}
if(param=='dealer'){
 this.searchDealerList();
}if(param=='csaTerminate'){
  this.searchTerminateReqList();
  }else{
this.searchCsaList();
}


}

setFirstPage (param:string,event: any) {
 event.preventDefault();
 this.pageNum = 1;
 if(param=='dealer'){
   this.searchDealerList();
 }if(param=='csaTerminate'){
  this.searchTerminateReqList();
  }else{
 this.searchCsaList();
 }

}

setLastPage (param,i,event: any) {
 event.preventDefault();
 this.pageNum = i;
 if(param=='dealer'){
   this.searchDealerList();
 }if(param=='csaTerminate'){
  this.searchTerminateReqList();
  }else{
 this.searchCsaList();
 }


}

dowloadDealer() {
  let totalDealerFleetList = [];
  const dPageNumber = this.pageNum;
  this.recPerPage = 10000;
  this.pageNum = 1;
    this.fleetService.getFleetDealerList(this.getDealerSearchParam()).subscribe(data => {
    totalDealerFleetList = data.data;
    this.fleetService.jsonToExcel(totalDealerFleetList,'Dealer Fleet', true);
    this.recPerPage = 10;
    this.pageNum = dPageNumber;
  
  });

}


dowloadCsa() {
  let totalCsaFleetList = [];
  const cPageNumber = this.pageNum;
  this.recPerPage = 10000;
  this.pageNum = 1;
    this.fleetService.getFleetCsaList(this.getCsaSearchParam()).subscribe(data => {
    totalCsaFleetList = data.data;
    this.fleetService.jsonToExcel(totalCsaFleetList,'CSA Fleet', true)
    this.recPerPage = 10;
    this.pageNum = cPageNumber;
  
  });
 
}


searchDealerList() {
  this.searchDealerFleet(this.getDealerSearchParam());
  
 }

 searchCsaList(){
  this.searchCsaFleet(this.getCsaSearchParam());
  
 }

 searchTerminateReqList(){
  this.searchCsaFleetTerminateReq(this.getCsaTerminateReqSearchParam());
  
 }

 searchDealerFleetListBtn() {
  //this.setFilteredClosedOrders();
  this.pageNum = 1;
  this.searchDealerFleet(this.getDealerSearchParam());

 }

 searchCsaFleetListBtn(){
  //this.setFilteredClosedOrders();
  this.pageNum = 1;
  this.searchCsaFleet(this.getCsaSearchParam());

 }

 searchFleetTerminateListBtn(){
  this.pageNum = 1;
  this.searchCsaFleetTerminateReq(this.getCsaTerminateReqSearchParam())
 }

 pageLoad() {
  // alert("page Load");
    $("#page-loader-overlay")
      .delay(800)
      .fadeOut();
    $("#delay-loader")
      .delay(1000)
      .fadeOut();
    
    }


private subscription: Subscription;
myVar;


constructor(private route:ActivatedRoute,private loginAuthenticationService:LoginAuthenticationService, private fleetService: FleetService,private util : Util, private titleService: Title,private _setCsaService:SetScreenService,private routing:Router) {}

  ngOnInit() {


    this.subscription = this._setCsaService.changeVar.subscribe(message => {

      //if (message !== this.myVar) {
        //alert("fleet message"+message);
          this.myVar = Number(message);
          if(this.myVar == 0){
            this.setTab('csa');

            }else if(this.myVar == 1){
              this.setTab('dealer');

            }       
     // }
  });
  



    this.loggedUserName= this.loginAuthenticationService.getAuthenticatedUser();
    this.loggedUserFullName= this.loginAuthenticationService.getAuthenticatedUserFullName();

    let username = this.loggedUserName;
    this.titleService.setTitle("Fleet"); 
    this.isAdmin =	sessionStorage.getItem('Admin');
    //this._setCsaService.changeMyVar(0); 
    //this.setDealersCSS();
    //this.searchDealerList();
    this.pageLoad();


  }

  openTerminateRequestModal(csa){
    document.getElementById("cancelBtn").innerText  = "Cancel";
    this.successMsg = "";
    this.errorMsg = "";
    this.requestSent = false;
    this.comments = "";
    this.reqSerialNumber = csa.serialNumber;
    this.reqContractNumber = csa.contractNumber;
    this.reqDealerCode = csa.dealerCode;
    this.reqDealerName = csa.dealerName;
  }

  submitTerminateRequest(comments){
    let params={
        "serialNumber" : this.reqSerialNumber,
        "comments" : this.comments,
        "contractNumber" : this.reqContractNumber,
        "dealerCode" : this.reqDealerCode,
        "dealerName" : this.reqDealerName
    };

    this.fleetService.terminateRequest(params).subscribe(data => {
        this.returnStatus=data.returnStatus;
        this.returnMsg=data.returnMsg;
        if(this.returnStatus == "S"){
          document.getElementById("cancelBtn").innerText  = "Close";
          this.successMsg = "Request sent";
          this.requestSent = true;
          this.searchCsaFleet(this.getCsaSearchParam());
          
        }else{
          this.errorMsg = "Request could not be sent";
        }
        
    });      

   
    
  }

  ngOnDestroy() {
    if (this.subscription) {
    this.subscription.unsubscribe();
    }

  }

}
