import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { NewOrder, OrderLine, OrderHeader, Maintenance, PickupReturn } from 'src/app/models/NewOrder';
import { OrderCreateNewService } from 'src/app/service/order-mgmt-service/order-create-new.service';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';
import { Util } from 'src/app/app.util';
import { CurrencyPipe } from '@angular/common';
import { ActivatedRoute, Router, RouterEvent } from '@angular/router';

@Component({
  selector: 'app-order-create-new',
  templateUrl: './order-create-new.component.html',
  styleUrls: ['./order-create-new.component.css']
})
export class OrderCreateNewComponent implements OnInit {

  @ViewChild('createOrderForm') form;
  newOrder: NewOrder = new NewOrder();


  pageHeaderTitle="New Order Request";
  bRecoverOrder:boolean=false;


  ACTION_NEW:string="NEW";
  ACTIONE_COPY:string="COPIED";
  ACTION_RECOVER:string="RECOVERED";

  arrInstProduct: any[] = [];
  arrPickReturnProduct: any[] = [];
  arrCustomer: any[] = [];
  arrAttchList: any[] = [];

  arrTerm: any[] = [];

  //cust
  custName: string = '';
  custPageNum = 1;
  custRecPerPage = 10;
  custTotalRecords = 0;
  custLastPage = 0;
  arrCustPgLinks: Array<number>;

  //inst
  instItemCodeOrDesc: string = '';
  instItemType: string = 'ALL';
  instPageNum = 1;
  instRecPerPage = 10;
  instTotalRecords = 0;
  instLastPage = 0;
  arrInstPgLinks: Array<number>;




  pickReturnItemCodeOrDesc: string = '';
  pickReturnItemType: string = 'ALL';
  pickReturnPageNum = 1;
  pickReturnRecPerPage = 10;
  pickReturnTotalRecords = 0;
  pickReturnLastPage = 0;
  arrPickReturnPgLinks: Array<number>;
  pickReturnErrMsg: string = '';
  pickReturnErrMsgCls: string = '';

  bDeclineAllMaint: boolean = false;

  // file
  fileToUpload: File = null;
  fileMsg = '';
  commonErrMsg = '';
  warningFileMsg = '';
  desc: string = '';
  filename: File;

  disableUTPrices: boolean = false;
  msg: string = '';
  msgClass: any = [];

  bSubmitError: boolean = false;
  bOrderConfirmed: boolean = false;
  bSubmitInProgress: boolean = false;
  bSubmitWarning: boolean = false;
  bShowSubmitRedirectClose: boolean = false;

  bClrCancel: boolean = false;
  readOnlyFlag: boolean = false;
  loadFlag: boolean = true;
  
  instTemplateFile: File = null;
  instTemplateFileName: File;
  arrInstTemplateItemList: any[] = [];
  instUpldMsg: string = '';
  instUpldMsgCls: any = [];

  pickReturnTemplateFile: File = null;
  pickReturnTemplateFileName: File;
  arrPickReturnTemplateItemList: any[] = [];
  pickReturnUpldMsg: string = '';
  pickReturnUpldMsgCls: any = [];

  sub: any;
  requestId: any;
  copyFromRequestId: any;
  recoverRequestId: any;
  versionNumber:any;

  tabIntstId:number=1000;
  tabMaintId:number=5000;
  tabPickReturnId:number=10000;
  approvalId: any = '';
  userRoles = {};
  subod: any= '';
  bOrdContainsMachine:boolean=false;

  constructor(private router: Router, private activatedRoute: ActivatedRoute,private util: Util, private currencyPipe: CurrencyPipe, private newOrderServce: OrderCreateNewService, private loginAuthenticationService: LoginAuthenticationService, private titleService: Title) { }


  ngOnInit(): void {
    this.loginAuthenticationService.getUserRoles().subscribe(data => {
      this.userRoles = data;
       if (this.userRoles["epOMAccess"]){
         // has access
      }else{
        //this.router.navigate(['dashboard']);
      }  
    }); 


    this.titleService.setTitle("Order Request");
    this.subod = this.activatedRoute.params.subscribe(params => {
      this.approvalId = params['ad'];
      });

      this.activatedRoute.queryParams.subscribe(params => {
        this.copyFromRequestId = params['copyFromRequestId'];
        this.recoverRequestId = params['recoverRequestId'];
        console.log(" copyFromRequestId :"+this.copyFromRequestId);
        if(this.recoverRequestId){
          this.versionNumber = params['versionNumber'];
          if(this.versionNumber){
            this.versionNumber= parseInt(""+ this.versionNumber)+1;
          }else{
            this.versionNumber=2;
          }
          console.log(" recoverRequestId :"+this.recoverRequestId+", versionNumber : "+this.versionNumber);
        }
       
        
      });
      

    this.sub = this.activatedRoute.params.subscribe(params => {
         this.requestId = params['id'];
         
	       if(this.requestId){
	        this.readOnlyFlag = true;
	       	this.getRequestJson();
	       	 for (let i = 1; i <= 60; i++) {
			     this.arrTerm.push(i);
			   }
         }else if(this.copyFromRequestId){
           console.log("in Copy Order : copyFromRequestId = "+this.copyFromRequestId);
           this.copyOrder();
           for (let i = 1; i <= 60; i++) {
            this.arrTerm.push(i);
          }
         }else if(this.recoverRequestId){
          console.log("in Recover Order : recoverRequestId = "+this.recoverRequestId);
          this.recoverOrder();
          for (let i = 1; i <= 60; i++) {
           this.arrTerm.push(i);
         }
        }else{
	       this.getRequestId();
			   this.getDealerContact();
			   this.newOrder.orderHeader.orderDate = this.getDefaultDate();
			   for (let i = 1; i <= 60; i++) {
			     this.arrTerm.push(i);
			   }
	       }
       });
       
       this.setOnLoad();
  }

  getRequestJson(){
	  this.loadFlag = true;
    let reqParam = { "requestId": this.requestId,"approvalId":this.approvalId};
  	this.newOrderServce.getRequestJson(reqParam).subscribe(
  	data => {
  		if(data.reqJson){
	  		this.newOrder = JSON.parse(data.reqJson);
	  		this.loadFlag = true;
	  	    this.setLineTotalPrice();
	  		console.log("newOrder JSON: " + data.reqJson)
	  		
	  		if (this.newOrder.linesList.length > 0) {
		  		for (let i = 0; i < this.newOrder.linesList.length; i++) {
			      this.newOrder.linesList[i].unitPrice = this.formatCurrency(this.newOrder.linesList[i].unitPrice);
			      this.newOrder.linesList[i].totalPrice = this.formatCurrency(this.newOrder.linesList[i].totalPrice);
			      this.newOrder.linesList[i].findersFee = this.formatCurrency(this.newOrder.linesList[i].findersFee);
			      this.newOrder.linesList[i].installationFee = this.formatCurrency(this.newOrder.linesList[i].installationFee);
			    }
	  		}
	  		
	  		if (this.newOrder.maintList.length > 0) {
          this.bDeclineAllMaint=true; 
		  		for (let i = 0; i < this.newOrder.maintList.length; i++) {
			      this.newOrder.maintList[i].basePrice = this.formatCurrency(this.newOrder.maintList[i].basePrice);
			      this.newOrder.maintList[i].bwCpc = this.formatCurrencyCpc(this.newOrder.maintList[i].bwCpc);
            this.newOrder.maintList[i].clrCpc = this.formatCurrencyCpc(this.newOrder.maintList[i].clrCpc);
            let tmpMaint=this.newOrder.maintList[i];
            if(tmpMaint.declineMaintenance==null || tmpMaint.declineMaintenance == 'N'){
              this.newOrder.maintList[i].chk=false;
              this.bDeclineAllMaint=false;
            }else{ 
              this.newOrder.maintList[i].chk=true;
            }
			    }
	  		}
	  		
	  		if (this.newOrder.pickupReturnList.length > 0) {
		  		for (let i = 0; i < this.newOrder.pickupReturnList.length; i++) {
			      this.newOrder.pickupReturnList[i].removalComp = this.formatCurrency(this.newOrder.pickupReturnList[i].removalComp);
			    }
	  		}
  		}else{
  			this.loadFlag = false;
  		} 
  	});
  }

  

  copyOrder(){
	  let reqParam = { "requestId": this.copyFromRequestId,"approvalId":""};
  	this.newOrderServce.getCopyOrderRequestJson(reqParam).subscribe(
  	data => {
       if(data.error=="INVALID_REQ_ACCESS"){
          this.getRequestId();
          this.getDealerContact();
          this.newOrder.orderHeader.orderDate = this.getDefaultDate();
          for (let i = 1; i <= 60; i++) {
            this.arrTerm.push(i);
          }

          console.log("No Copy Request Access for Request Id :"+this.copyFromRequestId);
       }else{
          if(data.reqJson){
            this.newOrder = JSON.parse(data.reqJson);
            this.newOrder.copyFromRequestId=this.copyFromRequestId;
            this.loadFlag = true;
              this.setLineTotalPrice();
            console.log("Copy Order JSON: " + data.reqJson)
            this.newOrder.orderHeader.reqInstlDate=null;
            
            if (this.newOrder.linesList.length > 0) {
              for (let i = 0; i < this.newOrder.linesList.length; i++) {
                this.newOrder.linesList[i].unitPrice = this.formatCurrency(this.newOrder.linesList[i].unitPrice);
                this.newOrder.linesList[i].totalPrice = this.formatCurrency(this.newOrder.linesList[i].totalPrice);
                this.newOrder.linesList[i].findersFee = this.formatCurrency(this.newOrder.linesList[i].findersFee);
                this.newOrder.linesList[i].installationFee = this.formatCurrency(this.newOrder.linesList[i].installationFee);
                
                let tmpMaint = this.newOrder.maintList.find(maint => maint.serviceModel === this.newOrder.linesList[i].serviceModel);

                if (tmpMaint) {
                  // maint exist in cart
                  this.newOrder.linesList[i].machineFlag = "Y";
                  }

              }
            }
            
            if (this.newOrder.maintList.length > 0) {
              this.bDeclineAllMaint=true;
              for (let i = 0; i < this.newOrder.maintList.length; i++) {

                this.newOrder.maintList[i].basePrice = this.formatCurrency(this.newOrder.maintList[i].basePrice);
                this.newOrder.maintList[i].bwCpc = this.formatCurrencyCpc(this.newOrder.maintList[i].bwCpc);
                this.newOrder.maintList[i].clrCpc = this.formatCurrencyCpc(this.newOrder.maintList[i].clrCpc);
                let tmpMaint=this.newOrder.maintList[i];
                if(tmpMaint.declineMaintenance==null || tmpMaint.declineMaintenance == 'N'){
                  this.newOrder.maintList[i].chk=false;
                  this.bDeclineAllMaint=false;
                }else{ 
                  this.newOrder.maintList[i].chk=true;
                }

              }
            }
            
            if (this.newOrder.pickupReturnList.length > 0) {
              for (let i = 0; i < this.newOrder.pickupReturnList.length; i++) {
                this.newOrder.pickupReturnList[i].removalComp = this.formatCurrency(this.newOrder.pickupReturnList[i].removalComp);
  
              }
            }
            
            console.log("Getting new Requset Id for copy Order")
            this.getRequestId();
            this.newOrder.orderHeader.orderDate = this.getDefaultDate();
            
          }else{
            this.loadFlag = false;
          } 
    }
  	});
  }

  recoverOrder(){
	  let reqParam = { "requestId": this.recoverRequestId,"approvalId":""};
  	this.newOrderServce.getCopyOrderRequestJson(reqParam).subscribe(
  	data => {
       if(data.error=="INVALID_REQ_ACCESS"){
          this.getRequestId();
          this.getDealerContact();
          
          for (let i = 1; i <= 60; i++) {
            this.arrTerm.push(i);
          }

          console.log("No Recover Order Access for Request Id :"+this.recoverRequestId);
       }else{
          if(data.reqJson){
            this.bRecoverOrder=true;
            this.pageHeaderTitle="Resubmit Existing Request: ";
            this.newOrder = JSON.parse(data.reqJson);
            this.newOrder.recoverRequestId=this.recoverRequestId;
            this.newOrder.requestId=this.recoverRequestId;
            this.newOrder.action=this.ACTION_RECOVER;
            this.newOrder.versionNumber=this.versionNumber;
            this.loadFlag = true;
            this.setLineTotalPrice();
            console.log("Recover Order JSON: " + data.reqJson)
            
            
            if (this.newOrder.linesList.length > 0) {
              for (let i = 0; i < this.newOrder.linesList.length; i++) {
                this.newOrder.linesList[i].unitPrice = this.formatCurrency(this.newOrder.linesList[i].unitPrice);
                this.newOrder.linesList[i].totalPrice = this.formatCurrency(this.newOrder.linesList[i].totalPrice);
                this.newOrder.linesList[i].findersFee = this.formatCurrency(this.newOrder.linesList[i].findersFee);
                this.newOrder.linesList[i].installationFee = this.formatCurrency(this.newOrder.linesList[i].installationFee);
                
                let tmpMaint = this.newOrder.maintList.find(maint => maint.serviceModel === this.newOrder.linesList[i].serviceModel);

                if (tmpMaint) {
                  // maint exist in cart
                  this.newOrder.linesList[i].machineFlag = "Y";
                  }

              }
            }
            
            if (this.newOrder.maintList.length > 0) {
              this.bDeclineAllMaint=true;
              for (let i = 0; i < this.newOrder.maintList.length; i++) {

                this.newOrder.maintList[i].basePrice = this.formatCurrency(this.newOrder.maintList[i].basePrice);
                this.newOrder.maintList[i].bwCpc = this.formatCurrencyCpc(this.newOrder.maintList[i].bwCpc);
                this.newOrder.maintList[i].clrCpc = this.formatCurrencyCpc(this.newOrder.maintList[i].clrCpc);
                let tmpMaint=this.newOrder.maintList[i];
                if(tmpMaint.declineMaintenance==null || tmpMaint.declineMaintenance == 'N'){
                  this.newOrder.maintList[i].chk=false;
                  this.bDeclineAllMaint=false;
                }else{ 
                  this.newOrder.maintList[i].chk=true;
                }
              }
            }
            
            if (this.newOrder.pickupReturnList.length > 0) {
              for (let i = 0; i < this.newOrder.pickupReturnList.length; i++) {
                this.newOrder.pickupReturnList[i].removalComp = this.formatCurrency(this.newOrder.pickupReturnList[i].removalComp);
  
              }
            }
             
          }else{
            this.loadFlag = false;
          } 
    }
  	});
  }


  getRequestId() {

    this.newOrderServce.getRequestId().subscribe(data => this.newOrder.requestId = data.requestId);
    console.log("newOrder.requestId : " + this.newOrder.requestId);

  }

  getDealerContact() {

    this.newOrderServce.getDealerContact().subscribe(
      data => {
        console.log("Dealer Contact : " + data.dlrCtName);
        this.newOrder.orderHeader.primaryDealerContactName = data.dlrCtName;
        this.newOrder.orderHeader.primaryDealerContactEmail = data.dlrCtEmail;
        this.newOrder.orderHeader.primaryDealerContactPhone = data.dlrCtPhone;
        this.newOrder.orderHeader.dealerPsn = data.dealerPsn; 
        this.newOrder.orderHeader.dealerAddress = data.dlrAddr;
        this.newOrder.orderHeader.dealerCity = data.dlrCity;
        this.newOrder.orderHeader.dealerState = data.dlrState;
        this.newOrder.orderHeader.dealerZip = data.dlrZip;
        this.newOrder.orderHeader.dealerMeterContactName = data.mtrCtName;
        this.newOrder.orderHeader.dealerMeterContactEmail = data.mtrCtEmail;
        this.newOrder.orderHeader.dealerMeterContactPhone = data.mtrCtPhone;

      });
    console.log("newOrder.requestId : " + this.newOrder.requestId)

  }


  getCustomerList(pageNum: any, event: any) {
    event.preventDefault();
    this.custPageNum = pageNum;
    let custParam = { "custName": this.custName, "custPageNum": this.custPageNum, "custRecPerPage": this.custRecPerPage };
    this.newOrderServce.getCustomerList(custParam).subscribe(data => {
      this.arrCustomer = data.data;
      this.custTotalRecords = data.recCount;
      this.custLastPage = (data.recCount % 10 == 0) ? (data.recCount / 10) : (parseInt(("" + data.recCount / 10)) + 1);
      this.arrCustPgLinks = new Array(this.custLastPage);

    });

  }

  setCustomer(customer) {
    this.custName = '';
    this.newOrder.orderHeader.customerName = customer.shipToCustomer;
    this.newOrder.orderHeader.address = customer.shipToAddress;
    this.newOrder.orderHeader.city = customer.shipToCity;
    this.newOrder.orderHeader.state = customer.shipToState;
    this.newOrder.orderHeader.zip = customer.shipToPostal;
    this.newOrder.orderHeader.primaryCustContactName = customer.primaryContactName;
    this.newOrder.orderHeader.primaryCustContactEmail = customer.primaryContactEmail;
    this.newOrder.orderHeader.primaryCustContactPhone = customer.primaryContactPhone;
    this.newOrder.orderHeader.shipToCustomerNumber = customer.shipToCustomerNumber;

  }

  setUnitTotalPrice() {
    let utPrice = "";

    if (this.newOrder.orderHeader.inventorySourceId == 2) {
      this.disableUTPrices = false;
      utPrice = "";
    } else {
      this.disableUTPrices = true;
      utPrice = "0";
    }

    for (let i = 0; i < this.newOrder.linesList.length; i++) {
      this.newOrder.linesList[i].unitPrice = utPrice;
      this.newOrder.linesList[i].totalPrice = utPrice;
      this.newOrder.linesList[i].findersFee="";
      this.newOrder.linesList[i].installationFee="";
    }
    this.newOrder.orderHeader.totalAmount = null;
  }


  // installation / products


  setLineTotalPrice() {
    let orderTotal = 0.0;


    for (let i = 0; i < this.newOrder.linesList.length; i++) {
      let ln = this.newOrder.linesList[i];
      if (ln.totalPrice) {
        orderTotal += this.convertStringCurrencyToNum(ln.totalPrice + "");
      }

      if (ln.findersFee) {
        let lnTotFindersFee=ln.quantity *  this.convertStringCurrencyToNum(ln.findersFee + "");
        orderTotal +=lnTotFindersFee; 
      }

      if (ln.installationFee) { 
        let lnTotInstallationFee=ln.quantity * this.convertStringCurrencyToNum(ln.installationFee + "");
        orderTotal += lnTotInstallationFee;
      }
    }


    for (let m = 0; m < this.newOrder.pickupReturnList.length; m++) {
      let pr = this.newOrder.pickupReturnList[m];
       if(pr.removalComp){
        orderTotal += this.convertStringCurrencyToNum(pr.removalComp + "");
        //console.log("added  Removal Comp :"+pr.removalComp  + " to total  "+this.newOrder.orderHeader.totalAmount);
       }
    }
     

    this.newOrder.orderHeader.totalAmount = this.formatCurrency(orderTotal);
  }

  getInstProductList(pageNum: any, event: any) {
    event.preventDefault();
    this.instPageNum = pageNum;
    let gplParam = { "itemCodeOrDesc": this.instItemCodeOrDesc, "pageNum": this.instPageNum, "recPerPage": this.instRecPerPage, "itemType": this.instItemType };
    this.newOrderServce.getProductsList(gplParam).subscribe(data => {
      this.arrInstProduct = data.data;
      this.instTotalRecords = data.recCount;
      this.instLastPage = (data.recCount % 10 == 0) ? (data.recCount / 10) : (parseInt(("" + data.recCount / 10)) + 1);
      this.arrInstPgLinks = new Array(this.instLastPage);
    });

  }



  addInstProduct(product) {
    this.instItemCodeOrDesc = '';
    let orderLine = new OrderLine();
    orderLine.itemCode = product.itemCode;
    orderLine.itemDescription = product.itemDescription;
    orderLine.itemId = product.inventoryItemId;
    orderLine.serviceModel = product.serviceModel;
    orderLine.quantity = 1;
    orderLine.unitPrice = 0;
    orderLine.totalPrice = 0;



    let merchandiseType = product.merchandiseType;
    if (product.machineFlag == 'Y') {
       orderLine.machineFlag = 'Y';
      let maintenance = new Maintenance();
      maintenance.serviceModel = product.serviceModel;
      maintenance.clrFlag = product.clrFlag;
      maintenance.quantity = 1;


      if (this.newOrder.maintList.length > 0) {
        let tmpMaint = this.newOrder.maintList.find(maint => maint.serviceModel === maintenance.serviceModel);

        if (tmpMaint) {
          // maint exist in cart
          let tmpMaintIdx = this.newOrder.maintList.indexOf(tmpMaint);
          let tmpMaintenance = this.newOrder.maintList[tmpMaintIdx];
          tmpMaintenance.quantity += 1;
          this.newOrder.maintList[tmpMaintIdx] = tmpMaintenance;
          console.log("tmpMaintenance svc mdl and idx " + tmpMaintenance.serviceModel + " : " + tmpMaintIdx);
        } else {
          // new maint
          this.newOrder.maintList.push(maintenance);
        }
      } else {
        this.newOrder.maintList.push(maintenance);
      }


    }

    if (this.newOrder.linesList.length > 0) {
      let tmpLine = this.newOrder.linesList.find(line => line.itemCode === orderLine.itemCode);

      if (tmpLine) {
        // line exist in cart
        let tmpIdx = this.newOrder.linesList.indexOf(tmpLine);
        let tmpOrderLine = this.newOrder.linesList[tmpIdx];
        tmpOrderLine.quantity = parseInt(tmpOrderLine.quantity + '') + 1;
        this.newOrder.linesList[tmpIdx] = tmpOrderLine;
        console.log("tmpLine ic and idx " + tmpLine.itemCode + " : " + tmpIdx);
        this.updateLineTotalPrice(tmpLine);

      } else {
        // new line
        this.newOrder.linesList.push(orderLine);
      }

    } else {
      // first line
      this.newOrder.linesList.push(orderLine);
    }
  }

  deleteInstProduct(idx) {
    if(this.readOnlyFlag){
	  return false;
	}
	
    if (this.newOrder.linesList.length > 0) {
      let tmpOrdLine = this.newOrder.linesList[idx];
      this.newOrder.linesList.splice(idx, 1);

      if (this.newOrder.maintList.length > 0) {
        let tmpMaint = this.newOrder.maintList.find(maint => maint.serviceModel === tmpOrdLine.serviceModel);
        if (tmpMaint) {
          let tmpIdx = this.newOrder.maintList.indexOf(tmpMaint);
          this.newOrder.maintList.splice(tmpIdx, 1);
        }
      }
    }

    this.setLineTotalPrice(); 
  }

  deleteSelectedInstProducts() {
    let tmpArr: any[] = [];
    for (let idx in this.newOrder.linesList) {
      let ordLine = this.newOrder.linesList[idx];
      if (!ordLine.chk) {
        tmpArr.push(ordLine);
      } else {
        if (this.newOrder.maintList.length > 0) {
          let tmpMaint = this.newOrder.maintList.find(maint => maint.serviceModel === ordLine.serviceModel);
          if (tmpMaint) {
            let tmpIdx = this.newOrder.maintList.indexOf(tmpMaint);
            this.newOrder.maintList.splice(tmpIdx, 1);
          }
        }
      }
    }
    this.newOrder.linesList = tmpArr;
    this.setLineTotalPrice(); 
  }
  selectAllInstProducts(chkSelAllPrds) {

    for (let idx in this.newOrder.linesList) {
      let ordLine = this.newOrder.linesList[idx];
      ordLine.chk = chkSelAllPrds;
      this.newOrder.linesList[idx] = ordLine;
    }

  }


  uploadInstTemplate() {
    this.instUpldMsg = '';
    this.instUpldMsgCls = [];
    this.fileMsg = "";
    this.commonErrMsg = "";
    this.warningFileMsg = "";
    this.arrInstTemplateItemList = [];
    if (this.instTemplateFile != null) {

      this.newOrderServce.uploadTemplate(this.instTemplateFile, this.newOrder.requestId, 'Install').subscribe(data => {

        if (data.errFlag == "S") {
          console.log(data);
          if (data.errorCnt > 0) {
            this.arrInstTemplateItemList = data.data;
            this.instUpldMsg = 'Please correct Invalid Items and upload';
            this.instUpldMsgCls = ['error'];
          } else {
            this.instUpldMsg = 'All Items have been added to Installation';
            this.instUpldMsgCls = ['success'];
            for (let j = 0; j < data.data.length; j++) {
              let product = data.data[j];
              let orderLine = new OrderLine();
              orderLine.itemCode = product.itemCode;
              orderLine.itemDescription = product.itemDescription;
              orderLine.itemId = product.inventoryItemId;
              orderLine.serviceModel = product.serviceModel;
              orderLine.quantity = product.quantity;
              orderLine.unitPrice = 0;
              orderLine.totalPrice = 0;
              orderLine.merchandiseType = product.merchandiseType;
              orderLine.clrFlag = product.clrFlag;
              orderLine.machineFlag=product.machineFlag;
              let merchandiseType = product.merchandiseType;
              /*if (merchandiseType == "10") {
                orderLine.machineFlag = 'Y';
              }*/
              this.addInstProduct(orderLine);
            }
          }

          this.instTemplateFile = null;
          this.instTemplateFileName = null;
        }
      },
        (error) => {
          this.commonErrMsg = "";
          console.log("Error: " + error);
          this.commonErrMsg = error;
        });;
    }
    else {
      this.warningFileMsg = "Please Upload the file and click Submit";
    }
  }

  instTemplateFileChange(files: FileList) {
    this.instTemplateFile = files.item(0);
  }

  clearUpload() {
    this.instTemplateFile = null;
    this.instTemplateFileName = null;
    this.arrInstTemplateItemList = [];
    this.instUpldMsg = '';
    this.instUpldMsgCls = [];;

    this.pickReturnTemplateFile = null;
    this.pickReturnTemplateFileName = null;
    this.arrPickReturnTemplateItemList = [];
    this.pickReturnUpldMsg = '';
    this.pickReturnUpldMsgCls = [];
  }

  setDeclineMaintenance(idx) {

    let tmpMaint = this.newOrder.maintList[idx];
    tmpMaint.declineMaintenance = 'N';
    if (tmpMaint.chk){
      tmpMaint.declineMaintenance = 'Y';
    }else{
      this.bDeclineAllMaint=false;
    }
    this.newOrder.maintList[idx] = tmpMaint;
    console.log(this.newOrder.maintList);


  }



  uploadPickReturnTemplate() {
    this.pickReturnUpldMsg = '';
    this.pickReturnUpldMsgCls = [];
    this.fileMsg = "";
    this.commonErrMsg = "";
    this.warningFileMsg = "";
    this.arrPickReturnTemplateItemList = [];
    if (this.pickReturnTemplateFile != null) {

      this.newOrderServce.uploadTemplate(this.pickReturnTemplateFile, this.newOrder.requestId, 'RETURNS').subscribe(data => {
        let bDupclicateSerial: boolean = false;
        if (data.errFlag == "S") {
          console.log(data);
          if (data.errorCnt > 0) {
            this.arrPickReturnTemplateItemList = data.data;
            this.pickReturnUpldMsg = 'Please correct following Errors.';
            this.pickReturnUpldMsgCls = ['error'];
          } 
          
         

            let tmpData = [];
         
            for (let k = 0; k < data.data.length; k++) {
              let product = data.data[k];
              for (let m = 0; m < this.newOrder.pickupReturnList.length; m++) {
                let pr = this.newOrder.pickupReturnList[m];
                if (pr.serialNumber && product.serialNumber) {
                  if (pr.serialNumber.toUpperCase() == product.serialNumber.toUpperCase()) {
                    bDupclicateSerial = true;
                    this.pickReturnUpldMsg = 'Please correct following Errors';
                    this.pickReturnUpldMsgCls = ['error'];
                    let sLn:string="";
                    if(product.error.length>0)
                    sLn="\n";
                    product.error += sLn+"Duplicate Serial Number";
                  }
                }
              }
              tmpData.push(product);
            }
            if (bDupclicateSerial) {
              this.arrPickReturnTemplateItemList = tmpData;
            }
         

          if (!bDupclicateSerial && data.errorCnt<1) {
            this.pickReturnUpldMsg = 'All Items have been added to Pickup/Returns';
            this.pickReturnUpldMsgCls = ['success'];
            for (let j = 0; j < data.data.length; j++) {
              let product = data.data[j];
              let pickReturn = new PickupReturn();
              pickReturn.itemCode = product.itemCode;
              pickReturn.itemDescription = product.itemDescription;
              pickReturn.quantity = 1;
              pickReturn.serviceModel = product.serviceModel;
              pickReturn.itemId = product.inventoryItemId;
              pickReturn.serialNumber = product.serialNumber;
              pickReturn.merchandiseType=product.merchandiseType;
              pickReturn.serializedItem=product.serializedItem;
              pickReturn.machineFlag=product.machineFlag;
              if(product.serializedItem&&product.serializedItem!='Y'){
                pickReturn.serialNumber='';
              }
              this.addPickReturnProduct(pickReturn);
            }

          }

          this.pickReturnTemplateFile = null;
          this.pickReturnTemplateFileName = null;
        }
      },
        (error) => {
          this.commonErrMsg = "";
          console.log("Error: " + error);
          this.commonErrMsg = error;
        });
    }
    else {
      this.warningFileMsg = "Please Upload the file and click Submit";
    }
  }

  pickReturnTemplateFileChange(files: FileList) {
    this.pickReturnTemplateFile = files.item(0);
  }



  getPickReturnProductList(pageNum: any, event: any) {
    event.preventDefault();
    this.pickReturnPageNum = pageNum;
    let gplParam = { "itemCodeOrDesc": this.pickReturnItemCodeOrDesc, "pageNum": this.pickReturnPageNum, "recPerPage": this.pickReturnRecPerPage, "itemType": this.pickReturnItemType };
    this.newOrderServce.getProductsList(gplParam).subscribe(data => {
      this.arrPickReturnProduct = data.data;
      this.pickReturnTotalRecords = data.recCount;
      this.pickReturnLastPage = (data.recCount % 10 == 0) ? (data.recCount / 10) : (parseInt(("" + data.recCount / 10)) + 1);
      this.arrPickReturnPgLinks = new Array(this.pickReturnLastPage);

    });

  }


  updateMaintQty(ordLine: OrderLine) {
    if (this.newOrder.maintList.length > 0) {
      let tmpMaint = this.newOrder.maintList.find(maint => maint.serviceModel === ordLine.serviceModel);
      if (tmpMaint) {
        let tmpIdx = this.newOrder.maintList.indexOf(tmpMaint);
        let maint = this.newOrder.maintList[tmpIdx];
        maint.quantity = ordLine.quantity;
        this.newOrder.maintList[tmpIdx] = maint;
      }
    }
    this.updateLineTotalPrice(ordLine);
  }


  declineAllMaint(eve: any) {

    this.bDeclineAllMaint = !this.bDeclineAllMaint;

    for (let idx = 0; idx < this.newOrder.maintList.length; idx++) {
      let tmpMaint = this.newOrder.maintList[idx];
      tmpMaint.declineMaintenance = 'N';
      if (this.bDeclineAllMaint){
        tmpMaint.declineMaintenance = 'Y';
        tmpMaint.chk=true;
      }else{
        tmpMaint.chk=false;
      }
      this.newOrder.maintList[idx] = tmpMaint;


    }
    console.log(this.newOrder.maintList);
  }


  selectAllPickReturnProducts(chkSelAllPickReturnPrds) {

    for (let idx in this.newOrder.pickupReturnList) {
      let pickReturn = this.newOrder.pickupReturnList[idx];
      pickReturn.chk = chkSelAllPickReturnPrds;
      this.newOrder.pickupReturnList[idx] = pickReturn;
    }

  }


  updateLineTotalPrice(ordLine: OrderLine) {
    console.log("updateLineTotalPrice");
    let tmpOrdLine = this.newOrder.linesList.find(orLine => orLine.itemCode === ordLine.itemCode);
    if (tmpOrdLine) {
      let tmpIdx = this.newOrder.linesList.indexOf(tmpOrdLine);
      let line = this.newOrder.linesList[tmpIdx];
      if (line.quantity && line.unitPrice) {
        console.log(line.quantity + " : " + line.unitPrice);
        line.totalPrice = this.formatCurrency(line.quantity * this.convertStringCurrencyToNum(line.unitPrice));
        this.newOrder.linesList[tmpIdx] = line;
      }

    }
    this.setLineTotalPrice();
  }

  addPickReturnProduct(product) {
    this.pickReturnErrMsg = '';
    this.pickReturnErrMsgCls = '';

    this.pickReturnItemCodeOrDesc = '';
    let pickReturn = new PickupReturn();
    pickReturn.itemCode = product.itemCode;
    pickReturn.itemDescription = product.itemDescription;
    pickReturn.quantity = 1;
    pickReturn.serviceModel = product.serviceModel;
    pickReturn.itemId = product.inventoryItemId;
    pickReturn.serializedItem=product.serializedItem;
    pickReturn.machineFlag=product.machineFlag;
    if (product.serialNumber) {
      pickReturn.serialNumber = product.serialNumber;
    }
    let merchandiseType = product.merchandiseType;
    /*if (merchandiseType == "10") {
      pickReturn.machineFlag = 'Y';
    }*/
    pickReturn.returnAddress=this.newOrder.orderHeader.address;
    pickReturn.returnCity=this.newOrder.orderHeader.city;
    pickReturn.returnState=this.newOrder.orderHeader.state;
    pickReturn.returnZipcode=this.newOrder.orderHeader.zip;

    this.newOrder.pickupReturnList.push(pickReturn);
  }

  deletePickReturnProduct(idx) {
    if(this.readOnlyFlag){
	  return false;
	}
	
    this.pickReturnErrMsg = '';
    this.pickReturnErrMsgCls = '';
    if (this.newOrder.pickupReturnList.length > 0) {
      this.newOrder.pickupReturnList.splice(idx, 1);
    }
  }


  deleteSelectedPickReturnProducts() {
    this.pickReturnErrMsg = '';
    this.pickReturnErrMsgCls = '';

    let tmpPrArr: any[] = [];
    for (let idx in this.newOrder.pickupReturnList) {
      let pr = this.newOrder.pickupReturnList[idx];
      if (!pr.chk) {
        tmpPrArr.push(pr);
      }
    }
    this.newOrder.pickupReturnList = tmpPrArr;
  }


  checkDuplicateSerial(pickReturn, event: any) {
    this.pickReturnErrMsg = '';
    this.pickReturnErrMsgCls = '';
    let srlCnt = 0;
    for (let idx in this.newOrder.pickupReturnList) {
      let pr = this.newOrder.pickupReturnList[idx];
      if(pr.serialNumber && pickReturn.serialNumber ) {
        if(pr.serialNumber && pr.serialNumber.length>0 &&  pickReturn.serialNumber &&  pickReturn.serialNumber.length>0){
          if (pr.serialNumber.toLocaleUpperCase() == pickReturn.serialNumber.toUpperCase()) {
            srlCnt++;
          }
       } 
      }
    }
    if (srlCnt > 1) {
      this.pickReturnErrMsg = "Serial Number already exist. Please enter new  Serial.";
      this.pickReturnErrMsgCls = "error";
      event.target.value = '';
    }
  }

  //end   installation / products  

  setInvSource() {

    if (this.newOrder.orderHeader.inventorySourceId == 1) {
      this.newOrder.orderHeader.inventorySource = "Dealers Own Inventory";
    } else if (this.newOrder.orderHeader.inventorySourceId == 2) {
      this.newOrder.orderHeader.inventorySource = "CSA Inventory";
    }else if (this.newOrder.orderHeader.inventorySourceId ==3) {
      this.newOrder.orderHeader.inventorySource = "CUSA Ship In";
    }
    
  }



  formatCurrency(sNum: any): string {
    let fmtNum: string = '';
    fmtNum = sNum + "";
    try {
      fmtNum = fmtNum.replace(/\$/g, "");
      fmtNum = fmtNum.replace(/\,/g, "");

      if (fmtNum) {

        let formattedVal = this.currencyPipe.transform(fmtNum, 'USD', 'symbol', '1.2-2');
        fmtNum = formattedVal;

      }
    } catch (error) {
      fmtNum = sNum;
    }

    return fmtNum;
  }
  
  formatCurrencyCpc(sNum: any): string {
    let fmtNum: string = '';
    fmtNum = sNum + "";
    try {
      fmtNum = fmtNum.replace(/\$/g, "");
      fmtNum = fmtNum.replace(/\,/g, "");

      if (fmtNum) {

        let formattedVal = this.currencyPipe.transform(fmtNum, 'USD', 'symbol', '1.2-5');
        fmtNum = formattedVal;

      }
    } catch (error) {
      fmtNum = sNum;
    }

    return fmtNum;
  }

  convertStringCurrencyToNum(sNum: string): number {
    let fmtNum: number = 0.0;

    try {
      sNum = sNum.replace(/\$/g, "");
      sNum = sNum.replace(/\,/g, "");
      sNum = sNum.trim();
      if (sNum) {
        fmtNum = Number(sNum);

      }
    } catch (error) {
      fmtNum = 0.0;
    }

    return fmtNum;
  }



  // File Upload

  uploadFile() {

    this.fileMsg = "";
    this.commonErrMsg = "";
    this.warningFileMsg = "";
    if (this.fileToUpload != null) {
        let fileSize:number=this.fileToUpload.size/1024/1024;
        if(fileSize>4){
          this.warningFileMsg= "Max allowed file size is 4 MB";
        }else{
        this.newOrderServce.uploadFile(this.fileToUpload, this.desc, this.newOrder.requestId).subscribe(data => {

          if (data.data == "S") {
            this.fileMsg = "Successfully Uploaded";
            this.getReqAttchList();
            this.clearFileDtls('U');
            this.fileToUpload = null;
          }
        },
          (error) => {
            this.commonErrMsg = "";
            console.log("Error: " + error);
            this.commonErrMsg = error;
          });
        } 
    }else {
      this.warningFileMsg = "Please Upload the file and click Submit";
    }
  }

  clearFileDtls(s: string) {
    this.desc = '';
    this.filename = null;

    if (s == "I") {
      this.warningFileMsg = '';
      this.fileMsg = '';
    }
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  getReqAttchList() {
    let gralParam = { "requestId": this.newOrder.requestId + "" };


    this.newOrderServce.getReqAttchList(gralParam).subscribe(data => {
      this.arrAttchList = data.data;

    });

  }

  deleteAttachment(params) {
    this.commonErrMsg = "";
    this.warningFileMsg = "";
    this.fileMsg = "";
    let daParam = { "requestId": this.newOrder.requestId + "", "attachmentId": params.attachmentId + "" };

    this.newOrderServce.deleteAttachment(daParam).subscribe(data => {

      let errFlag = data.errFlag;
      let errMsg = data.errMsg;
      if (errFlag == "S") {
        this.getReqAttchList();
        this.fileMsg = "Deleted Successfully";
      } else {
        console.log("ERROR:" + errMsg);
      }
    });

  }




  downloadAttachment(params, event) {
    event.preventDefault();
    this.commonErrMsg = "";
    var pgUrl = window.location.host;
	var encodedFileName= encodeURIComponent(params.fileName);
    var link = this.util.getBaseUrl() + 'neworder/da?requestId=' + params.requestId + '&orderNumber=' + params.orderNumber + '&attachmentId=' + params.attachmentId + '&fileIdentifierId=' + params.fileIdentifierId + '&dataType=' + params.dataType + '&fileName=' + encodedFileName + '&schema=' + params.schema;
    window.open(link, "_blank");
  }


  getDefaultDate() {

    let d1 = new Date();
    let sd = (d1.getUTCMonth() + 1) + "/" + (d1.getUTCDate()) + "/" + d1.getFullYear();
    return sd;
  }

  clearOrCancelOrder(ccFlag: string) {
    // ccFlag cancel: cl , clear :Clr

    let tmpReqId = this.newOrder.requestId;
    let daParam = { "requestId": tmpReqId, "attachmentId": "" };
    this.deleteAttachment(daParam);
    this.newOrder.orderHeader = new OrderHeader();
    this.newOrder.linesList = [];
    this.newOrder.maintList = [];
    this.newOrder.pickupReturnList = [];

    this.newOrder = new NewOrder();
    if (ccFlag == 'clr') {
      this.newOrder.requestId = tmpReqId;
      this.getDealerContact();
    }

    this.bClrCancel = true;

  }

  validateEmail(sEmail: string): boolean {
    let bValidEmail: boolean = false;
    if (sEmail && sEmail.trim().length > 0) {
      sEmail = sEmail.trim();
      let pVldtr = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      bValidEmail = pVldtr.test(sEmail);
    }
    return bValidEmail;
  }

  validatePhone(sPhoneNum: string): boolean {
    let bValidPhoneNum: boolean = false;
    let Validformats = 'Valid Formats:18005551234\r\n' +
      '1 800 555 1234\r\n       ' +
      '+1 800 555-1234\r\n      ' +
      '+86 800 555 1234\r\n     ' +
      '1-800-555-1234\r\n       ' +
      '1 (800) 555-1234\r\n     ' +
      '(800)555-1234\r\n        ' +
      '(800) 555-1234\r\n       ' +
      '(800)5551234\r\n         ' +
      '800-555-1234\r\n         ' +
      '800.555.1234\r\n         ' +
      '800 555 1234x5678\r\n    ' +
      '8005551234 x5678\r\n     ' +
      '1    800    555-1234\r\n ' +
      '1---800---555-1234\r\n   ';


    if (sPhoneNum && sPhoneNum.trim().length > 0) {

      //let pVldtr = new RegExp(/^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i);
      let pVldtr = new RegExp(/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/);

      bValidPhoneNum = pVldtr.test(sPhoneNum);
    }

    return bValidPhoneNum;
  }

  setSortOrders() {

    for (let i = 0; i < this.newOrder.linesList.length; i++) {
      let ordLine = this.newOrder.linesList[i];
      ordLine.sortOrder = i;
      this.newOrder.linesList[i] = ordLine;
      for (let m = 0; m < this.newOrder.maintList.length; m++) {
        let maintLine = this.newOrder.maintList[m];
        if (maintLine.serviceModel == ordLine.serviceModel) {
          maintLine.configSortOrder = ordLine.sortOrder;
          this.newOrder.maintList[m] = maintLine;
        }
      }
    }

  }

  downloadItemTemplate(tabName: string) {
    event.preventDefault();

    var link = this.util.getBaseUrl() + "neworder/dit?tabName=" + tabName;
    window.open(link, "_blank");
  }

  setOnLoad() {
    if(!this.readOnlyFlag){
    setTimeout(function () {

      $('.form-section-header .plus-mius').click(function () {
        if ($(this).parent().next().height() <= 85) {
          $(this).parent().next().css("height", "auto");
        } else {
          $(this).parent().next().css("height", "85px");
        }
      });

      $( "#dealerPO" ).focus();


    }, 2000);

  }
  }

  formatBeforeSubmit() {
    let orderHeader = this.newOrder.orderHeader;
    orderHeader.totalAmount = this.convertStringCurrencyToNum(orderHeader.totalAmount+"");
    this.newOrder.orderHeader = orderHeader;

    for (let i = 0; i < this.newOrder.linesList.length; i++) {
      let ln = this.newOrder.linesList[i];
      if (ln.unitPrice) {
        ln.unitPrice = this.convertStringCurrencyToNum(ln.unitPrice + "");
      }
      if (ln.totalPrice) {
        ln.totalPrice = this.convertStringCurrencyToNum(ln.totalPrice + "");
      }
      if (ln.findersFee) {
        ln.findersFee = this.convertStringCurrencyToNum(ln.findersFee + "");
      }
      if (ln.installationFee) {
        ln.installationFee = this.convertStringCurrencyToNum(ln.installationFee + "");
      }

      this.newOrder.linesList[i] = ln;
    }
    for (let j = 0; j < this.newOrder.maintList.length; j++) {
      let maint = this.newOrder.maintList[j];
      if (maint.basePrice) {
        maint.basePrice = this.convertStringCurrencyToNum(maint.basePrice + "");
      }
      if (maint.bwAllowance) {
        maint.bwAllowance = this.convertStringCurrencyToNum(maint.bwAllowance + "");
      }
      if (maint.bwCpc) {
        maint.bwCpc =  this.convertStringCurrencyToNum(maint.bwCpc + "");
        try {
          maint.bwCpc= ( maint.bwCpc).toFixed(5);
        } catch (error) {
        }
      
      }
      if (maint.clrAllowance) {
        maint.clrAllowance = this.convertStringCurrencyToNum(maint.clrAllowance + "");
      }
      if (maint.clrCpc) {
        maint.clrCpc = this.convertStringCurrencyToNum(maint.clrCpc + "");
       
        try {
           maint.clrCpc=( maint.clrCpc).toFixed(5);
        } catch (error) {
        }
      }
      this.newOrder.maintList[j] = maint;
    }

    for (let k = 0; k < this.newOrder.pickupReturnList.length; k++) {
      let pickReturn = this.newOrder.pickupReturnList[k];
      if (pickReturn.removalComp) {
        pickReturn.removalComp = this.convertStringCurrencyToNum(pickReturn.removalComp + "");
      }

      this.newOrder.pickupReturnList[k] = pickReturn;
    }
    console.log("formatBeforeSubmit  complete");
  }

  submitOrder(newOrderForm: FormGroup, sFlag: string) {
    this.setSortOrders();
    this.msg = "";
    let bInvalid = false;
    this.bOrdContainsMachine=false;
    
    this.bSubmitError = false;
    this.setInvSource();
    let orderData = JSON.stringify(this.newOrder);

    let errorMsg = '';
  
    if (!newOrderForm.valid) {
      if( !(this.newOrder.orderHeader.dealerPO && this.newOrder.orderHeader.dealerPO.trim().length>0)){
        errorMsg += "Please enter Dealer PO\n";
      }
      if( !(this.newOrder.orderHeader.inventorySourceId && this.newOrder.orderHeader.inventorySourceId>0)){
        errorMsg += "Please enter Inventory Source\n";
      }
      if( !(this.newOrder.orderHeader.reqInstlDate)){
        errorMsg += "Please enter Requested Installation Date\n";
      }
     
      if( !(this.newOrder.orderHeader.customerName && this.newOrder.orderHeader.customerName.trim().length>0)){
        errorMsg += "Please enter Requested Customer Name\n";
      }
      if( !(this.newOrder.orderHeader.address && this.newOrder.orderHeader.address.trim().length>0)){
        errorMsg += "Please enter Requested Address\n";
      }
      if( !(this.newOrder.orderHeader.city && this.newOrder.orderHeader.city.trim().length>0)){
        errorMsg += "Please enter Requested City\n";
      }
      if( !(this.newOrder.orderHeader.state && this.newOrder.orderHeader.state.trim().length>0)){
        errorMsg += "Please enter Requested State\n";
      }
      if( !(this.newOrder.orderHeader.zip && this.newOrder.orderHeader.zip.trim().length>0)){
        errorMsg += "Please enter Requested Zip\n";
      }
      if( !(this.newOrder.orderHeader.primaryCustContactName && this.newOrder.orderHeader.primaryCustContactName.trim().length>0)){
        errorMsg += "Please enter Requested Primary Contact Name\n ";
       
      }
      if( !(this.newOrder.orderHeader.primaryCustContactEmail && this.newOrder.orderHeader.primaryCustContactEmail.trim().length>0)){
        errorMsg += "Please enter Requested Primary Contact Email\n ";
      }
      if( !(this.newOrder.orderHeader.primaryCustContactPhone && this.newOrder.orderHeader.primaryCustContactPhone.trim().length>0)){
        errorMsg += "Please enter Requested Primary Contact Phone\n ";
      }
      if( !(this.newOrder.orderHeader.primaryDealerContactEmail && this.newOrder.orderHeader.primaryDealerContactEmail.trim().length>0)){
        errorMsg += "Please enter Requested Dealer Contact Email\n ";
      }
      console.log(" Invalid form");
     // errorMsg = "Please enter Required Fields";
     if (errorMsg) {
      errorMsg = "\n\Order Information :\n---------------\n " + errorMsg;
     } 
      bInvalid = true;
    }
    if (this.newOrder.orderHeader.primaryCustContactEmail && !this.validateEmail(this.newOrder.orderHeader.primaryCustContactEmail)) {
      errorMsg += " \nPlease enter valid Primary Customer Contact Email";
      bInvalid = true;
    }
    if (this.newOrder.orderHeader.primaryCustContactPhone && !this.validatePhone(this.newOrder.orderHeader.primaryCustContactPhone)) {
      errorMsg += " \nPlease enter valid Primary Customer Contact Phone Number";
      bInvalid = true;
    }

    if (this.newOrder.orderHeader.secondaryCustContactEmail && !this.validateEmail(this.newOrder.orderHeader.secondaryCustContactEmail)) {
      errorMsg += " \nPlease enter valid Secondary Customer Contact Email";
      bInvalid = true;
    }
    if (this.newOrder.orderHeader.secondaryCustContactPhone && !this.validatePhone(this.newOrder.orderHeader.secondaryCustContactPhone)) {
      errorMsg += " \nPlease enter valid Secondary Customer Contact Phone Number";
      bInvalid = true;
    }

    if (this.newOrder.orderHeader.primaryDealerContactEmail && !this.validateEmail(this.newOrder.orderHeader.primaryDealerContactEmail)) {
      errorMsg += " \nPlease enter valid Primary Dealer Contact Email";
      bInvalid = true;
    }
    if (this.newOrder.orderHeader.primaryDealerContactPhone && !this.validatePhone(this.newOrder.orderHeader.primaryDealerContactPhone)) {
      errorMsg += " \nPlease enter valid Primary Dealer Contact Phone Number";
      bInvalid = true;
    }

    if (!this.newOrder.orderHeader.dealerMeterContactEmail ||
       (this.newOrder.orderHeader.dealerMeterContactEmail && !this.validateEmail(this.newOrder.orderHeader.dealerMeterContactEmail))) {
      errorMsg += " \nPlease enter valid  Dealer Meter Contact Email";
      bInvalid = true;
    }

    

    if (this.newOrder.linesList.length < 1) {
      console.log(" No Products Added.");
       // Installation optional for RMA Only Orders
      if(this.newOrder.pickupReturnList.length>0){
        this.bSubmitWarning=true;
      }else{
        errorMsg += "\n\nInstallation :\n--------------- \n" + " Please add atleast 1 product.\n";
         bInvalid = true;  
      }
         
      
    }

    let instTabErr: string = ""
    let bInstallError: boolean = false;
    for (let i = 0; i < this.newOrder.linesList.length; i++) {
      let orderLine = this.newOrder.linesList[i];
      if (!orderLine.quantity || (orderLine.quantity && orderLine.quantity < 1)) {
        instTabErr += " \nPlease enter valid quantity for itemCode :" + orderLine.itemCode;
        bInvalid = true;
        bInstallError = true;
      }


      if (orderLine.machineFlag == "Y") {
          this.bOrdContainsMachine=true;

        if(((!orderLine.unitPrice || (orderLine.unitPrice && orderLine.unitPrice < 1))
        &&(!orderLine.findersFee || (orderLine.findersFee && orderLine.findersFee < 1))
        &&(!orderLine.installationFee || (orderLine.installationFee && orderLine.installationFee < 1)))){
  
          instTabErr += " \n Please enter an amount for compensation types  for Item Code : " + orderLine.itemCode;
          bInvalid = true;
          bInstallError = true;
  
        }else{

          if (!orderLine.findersFee || (orderLine.findersFee && !(this.convertStringCurrencyToNum(""+ orderLine.findersFee) >= 0))) {
            instTabErr += " \nPlease enter valid Finder Fee for Item Code :" + orderLine.itemCode;
            bInvalid = true;
            bInstallError = true;
          }
          if (!orderLine.installationFee || (orderLine.installationFee && !(this.convertStringCurrencyToNum(""+ orderLine.installationFee) >= 0))) {
            instTabErr += " \nPlease enter valid Installation Fee  for Item Code :" + orderLine.itemCode;
            bInvalid = true;
            bInstallError = true;
          }

       }
      }
     
      
    }

    if (bInstallError) {
      instTabErr = "\n\nInstallation :\n--------------- " + instTabErr;
      errorMsg += instTabErr;
    }

    let maintTabErr: string = ""
    let bMaintError: boolean = false;

    for (let j = 0; j < this.newOrder.maintList.length; j++) {

      let maint = this.newOrder.maintList[j];
      if (!this.bDeclineAllMaint) {
        if (!maint.chk) {
          if (!maint.term || (maint.term && maint.term < 1)) {
            maintTabErr += " \nPlease select valid term for Service Model :" + maint.serviceModel;
            bInvalid = true;
            bMaintError = true;
          }
          if (maint.basePrice==undefined) {
            maintTabErr += " \nPlease Enter valid   Base Price for Service Model :" + maint.serviceModel;
            bInvalid = true;
            bMaintError = true;
          }
          if (maint.bwAllowance==undefined) {
            maintTabErr += " \nPlease Enter valid BW Allowance for Service Model  :" + maint.serviceModel;
            bInvalid = true;
            bMaintError = true;
          }
          if (!maint.bwCpc) {
            maintTabErr += " \nPlease Enter valid   BW CPC for Service Model :" + maint.serviceModel;
            bInvalid = true;
            bMaintError = true;
          }
          if (maint.clrFlag == "Y") {
            if (maint.clrAllowance==undefined) {
              maintTabErr += " \nPlease Enter valid   CLR Allowance for Service Model :" + maint.serviceModel;
              bInvalid = true;
              bMaintError = true;
            }
            if (!maint.clrCpc) {
              maintTabErr += " \nPlease Enter valid   CLR CPC for Service Model :" + maint.serviceModel;
              bInvalid = true;
              bMaintError = true;
            }
          }

          if (!maint.baseBillingCycle) {
            maintTabErr += " \nPlease Select valid  Base Billing Cycle for Service Model :" + maint.serviceModel;
            bInvalid = true;
            bMaintError = true;
          }
          if (!maint.usageBillingCycle) {
            maintTabErr += " \nPlease Select valid  Usage Billing Cycle for Service Model :" + maint.serviceModel;
            bInvalid = true;
            bMaintError = true;
          }

        }
      }
    }

    if (bMaintError) {
      maintTabErr = "\n\nMaintenance :\n--------------- " + maintTabErr;
      errorMsg += maintTabErr;
    }



    let pickReturnTabErr: string = ""
    let bPickReturnTabErr: boolean = false;

    for (let k = 0; k < this.newOrder.pickupReturnList.length; k++) {
      let pickReturn = this.newOrder.pickupReturnList[k];
      if (!pickReturn.quantity || (pickReturn.quantity && pickReturn.quantity < 1)) {
        pickReturnTabErr += " \nPlease select valid term for Item Code :" + pickReturn.itemCode;
        bInvalid = true;
        bPickReturnTabErr = true;
      }
      if (!pickReturn.returnAddress) {
        pickReturnTabErr += " \nPlease Enter valid Address  for Item Code :" + pickReturn.itemCode;
        bInvalid = true;
        bPickReturnTabErr = true;
      }
      if (!pickReturn.returnCity) {
        pickReturnTabErr += " \nPlease Enter valid City  for Item Code :" + pickReturn.itemCode;
        bInvalid = true;
        bPickReturnTabErr = true;
      }
      if (!pickReturn.returnState) {
        pickReturnTabErr += " \nPlease select valid State  for Item Code :" + pickReturn.itemCode;
        bInvalid = true;
        bPickReturnTabErr = true;
      }
      if (!pickReturn.returnZipcode) {
        pickReturnTabErr += " \nPlease select valid Zip Code  for Item Code :" + pickReturn.itemCode;
        bInvalid = true;
        bPickReturnTabErr = true;
      }
      if (!pickReturn.hardDriveRemoval) {
        pickReturnTabErr += " \nPlease select valid Hard Drive Removal  for Item Code :" + pickReturn.itemCode;
        bInvalid = true;
        bPickReturnTabErr = true;
      }
      if (!pickReturn.disposalOrPickup) {
        pickReturnTabErr += " \nPlease select valid Disposal/Pickup and Hold for Item Code :" + pickReturn.itemCode;
        bInvalid = true;
        bPickReturnTabErr = true;
      }
      if((pickReturn.removalComp==undefined) ||
       (pickReturn.removalComp!=undefined && (pickReturn.removalComp.length<1)) ||
       !(this.convertStringCurrencyToNum(""+ pickReturn.removalComp) >= 0)) { 
        pickReturnTabErr += " \nPlease enter valid Removal Comp for Item Code :" + pickReturn.itemCode;
        bInvalid = true;
        bPickReturnTabErr = true;
      }
    }

    if (bPickReturnTabErr) {
      pickReturnTabErr = "\n\nPickup/Returns :\n--------------- " + pickReturnTabErr;
      errorMsg += pickReturnTabErr;
    }



    if (bInvalid) {
      this.msg = errorMsg;
      this.bSubmitError = true;
      this.msgClass = ['error', 'white-space-pre-line'];
      return;
    }
    this.formatBeforeSubmit();
    if (sFlag == 'C') {
      this.bSubmitInProgress = true;
      this.msg = "Submitting order request.Please wait.";
      this.msgClass = ['success'];
      this.newOrderServce.submitOrderRequest(orderData)
        .subscribe(data => {
          if (data.errFlag == 'S') {
            this.msg = "Order Request Submitted. Request Number :" + data.errMsg;
            this.msgClass = ['success'];
            this.bOrderConfirmed = true;
            this.bShowSubmitRedirectClose = true;
          } else {
            this.msg = data.errMsg;
            this.msgClass = ['error'];
            this.bSubmitInProgress = false;
          }
          console.log("Create Order Response : errFlag " + data.errFlag);
        });

    }
  }

}
