import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Util } from 'src/app/app.util';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};
const httpFileoptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'multipart/form-data; charset=UTF-8')
};

const httpJsonOptions = {
  headers: new HttpHeaders({

  }).set('Content-Type', 'application/json')
};

@Injectable({
  providedIn: 'root'
})
export class OrderCreateNewService {

  defaultUrl: string;

  constructor(private http: HttpClient, private util: Util) {
    this.defaultUrl = util.getBaseUrl();

  }

  getCustomerList(custParam): Observable<any> {
    let FN = "[getCustomerList]";
    let urlCl = this.defaultUrl + "neworder/gcl";
    let params = new URLSearchParams();
    for (let key in custParam) {
      params.set(key, custParam[key])
    }
    console.log(FN + "params : " + params.toString());

    return this.http
      .post(urlCl, params.toString(), httpOptions)
      .pipe(catchError(this.handleError(FN)));
  }

  getProductsList(gplParam): Observable<any> {
    let FN = "[getProductsList]";
    let urlGpl = this.defaultUrl + "neworder/gpl";
    let params = new URLSearchParams();
    for (let key in gplParam) {
      params.set(key, gplParam[key])
    }
    console.log(FN + "params : " + params.toString());
  
    return this.http
      .post(urlGpl, params.toString(), httpOptions)
      .pipe(catchError(this.handleError(FN)));

  }  

  getDealerContact(): Observable<any> {
    let FN = "[getDealerContact]";
    let urlGdc = this.defaultUrl + "neworder/gdc";
    let params = new URLSearchParams();
    
    return this.http
      .post(urlGdc, params.toString(), httpOptions)
      .pipe(catchError(this.handleError(FN)));

  }  

  // File Upload 
  uploadFile(fileToUpload: File, desc: string, requestId: string): Observable<any> {
    let upldUrl = this.defaultUrl + "neworder/unra?requestId=" + requestId + "&desc=" + desc;
    let FN = "uploadFile"
    const formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    return this.http
      .post(upldUrl, formData)
      .pipe(catchError(this.handleError(FN)))
  }  

  getReqAttchList(gralParam): Observable<any> {
    let FN = "[getReqAttchList]";
    let urlGral = this.defaultUrl + "neworder/gral";
    let params = new URLSearchParams();
    for (let key in gralParam) {
      params.set(key, gralParam[key])
    }
    console.log(FN + "params : " + params.toString());

    return this.http
      .post(urlGral, params.toString(), httpOptions)
      .pipe(catchError(this.handleError(FN)));

  }

  deleteAttachment(daParam): Observable<any> {
    let FN = "[deleteAttachment]";
    let urlDA = this.defaultUrl + "neworder/delAttch";
    let params = new URLSearchParams();
    for (let key in daParam) {
      params.set(key, daParam[key])
    }
    console.log(FN + "params : " + params.toString());

    return this.http
      .post(urlDA, params.toString(), httpOptions)
      .pipe(catchError(this.handleError(FN)));

  }


  uploadTemplate  (fileToUpload: File, requestId: string,tabName:string): Observable<any> {
    let upldUrl = this.defaultUrl + "neworder/uipt?requestId=" + requestId+"&tabName="+tabName ;
    let FN = "uploadTemplate"
    const formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    return this.http
      .post(upldUrl, formData)
      .pipe(catchError(this.handleError(FN)))
  }  
 
 
  submitOrderRequest(orderData): Observable<any> {

    let FN = "[submitOrderRequest]";
    let urlSor = this.defaultUrl + "neworder/sor"; //  ordersearch/gdol neworder/sor

    console.log('newOrder: ', orderData);
    return this.http
      .post(urlSor, orderData, httpJsonOptions)
      .pipe(catchError(this.handleError(FN)));

  }

  getRequestId(): Observable<any> {
    let FN = "[getRequestId]";
    let urlGpl = this.defaultUrl + "neworder/gri";


    return this.http
      .get(urlGpl, httpOptions)
      .pipe(catchError(this.handleError(FN)));
  }


  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {

      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
  
   getRequestJson(reqParam): Observable<any> {
    let FN = "[getRequestJson]";
    let urlGrj = this.defaultUrl + "neworder/grj";
    let params = new URLSearchParams();
    
    for (let key in reqParam) {
      params.set(key, reqParam[key])
    }
    console.log(FN + "params : " + params.toString());

    return this.http
      .post(urlGrj, params.toString(), httpOptions)
      .pipe(catchError(this.handleError(FN)));
  }

  getCopyOrderRequestJson(reqParam): Observable<any> {
    let FN = "[getCopyOrderRequestJson]";
    let urlGrj = this.defaultUrl + "neworder/gcorj";
    let params = new URLSearchParams();
    
    for (let key in reqParam) {
      params.set(key, reqParam[key])
    }
    console.log(FN + "params : " + params.toString());

    return this.http
      .post(urlGrj, params.toString(), httpOptions)
      .pipe(catchError(this.handleError(FN)));
  }
}
