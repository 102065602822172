import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class SetScreenService {
  myVariable:any =0;
 
  private messageSource = new BehaviorSubject<any>("");
  changeVar = this.messageSource.asObservable();
    
 
  setCSA = 1;


  constructor() {
      
  this.changeMyVar("1");
  
  }

  changeMyVar (message: any) {
    this.messageSource.next(message);
  }
    
    
    
    setDealersCSS() {       
        //$(".csa-page-header-title").hide();
        $("#side-nav-logo-button-wrapper,#mobile-menu-modal-header").css("background", " url('assets/img/dealers-bg.jpg')");
        $(".site-logo").attr("src", "assets/img/ep-white-bg-logo.png");
        $("#main-top-nav-wrapper-clone").css("background", "#7395AE");
        $("#main-side-nav,#mobile-menu-modal-body").css("background", "#5d5c61");
        $(".main-top-navbar").css("border-top", "1px solid #fff");
        $(".fa-ellipsis-v").css("color", "#396f96");
        $("#main-side-nav .inner ul li.side-menu-li,#mobile-menu-links ul li.mobile-menu-li,.single-link-side-menu-li,.single-link-mobile-menu-li").css({ "border": "1px solid #1b6d85", "background-color": "#7395ae", "color": "#fff" });

        $(".side-less-icons-wrapper").hover(function () {
            $(".side-less-icons-wrapper .side-more-icons").css("color", "rgb(38, 154, 188)");
        }, function () {
            $(".side-less-icons-wrapper .side-more-icons").css("color", "#fff");
        });
        $(".side-more-icons-wrapper").hover(function () {
            $(".side-more-icons-wrapper .side-more-icons").css("color", "rgb(38, 154, 188)");
        }, function () {
            $(".side-more-icons-wrapper .side-more-icons").css("color", "#fff");
        });
        $(".side-menu-button-wrapper i").hover(function () {
            $(this).css({ "color": "rgb(38, 154, 188)", "border": "3px solid rgb(38, 154, 188)" });
        }, function () {
            $(this).css({ "color": "#fff", "border": "3px solid #fff" });
        });
        $(".tooltip .tooltip-inner").css("border-left", "7px solid rgb(38, 154, 188)");
        $(".group-switcher").css({ "border": "1px solid #2aabd1", "background-color": "#2286a3","pointer-events": "auto" });
        $(".side-menu-wrapper-inner").css("border-top", "1px solid #777");
        $(".main-csa-content-wrapper,.csa-page-header-title").show();
        $(".main-approvalcsa-content-wrapper,.csa-page-header-title").hide();
        $(".main-dealers-content-wrapper,.dealer-page-header-title").fadeIn();
        $(".btn-primary").css({ "border-color": "#2aabd1", "background-color": "#2286a3", "color": "#fff" });
        $(".result-table tr td i").css({ "color": "#2aabd1", "border": "solid 1px #2aabd1" });
        $("html,body").css({ "background-color": "#ddd" });
        $(".main-top-navbar-left-content,.main-top-navbar-links,.group-switcher-inner").css({ "color": "#fff" });
        $(".result-table tr th").css({ "background-color": "#7395ae" });

        $('.sm-switcher .group-switcher-inner').css("margin-left", "-55px");
        $('.md-switcher .group-switcher-inner').css("margin-left", "-60px");

        this.setCSA = 1;

//        setTimeout(function(){ 
//            //alert("Hello"); 
//        //sessionStorage.setItem("setScreen", "1");
//            $(".csa-page-header-title").hide();
//        }, 100);

    }
     setCSACSS() {       
        $("#side-nav-logo-button-wrapper,#mobile-menu-modal-header").css("background", " url('assets/img/csa-bg.jpg')");
        $(".site-logo").attr("src", "assets/img/ep_logo.png");
        $("#main-top-nav-wrapper-clone").css("background", "#555");
        $("#main-side-nav,#mobile-menu-modal-body").css("background", "#333");
        $(".main-top-navbar").css("border-top", "1px solid rgb(186, 179, 171)");
        $(".fa-ellipsis-v").css("color", "rgb(204, 204, 204)");
        $("#main-side-nav .inner ul li.side-menu-li,#mobile-menu-links ul li.mobile-menu-li,.single-link-side-menu-li,.single-link-mobile-menu-li").css({ "border": "1px solid #333", "background-color": "#dbd5ce", "color": "#59534d" });

        $(".side-less-icons-wrapper").hover(function () {
            $(".side-less-icons-wrapper .side-more-icons").css("color", "#dbd5ce");
        }, function () {
            $(".side-less-icons-wrapper .side-more-icons").css("color", "#fff");
        });
        $(".side-more-icons-wrapper").hover(function () {
            $(".side-more-icons-wrapper .side-more-icons").css("color", "#dbd5ce");
        }, function () {
            $(".side-more-icons-wrapper .side-more-icons").css("color", "#fff");
        });

        $(".side-menu-button-wrapper i").hover(function () {
            $(this).css({ "color": "#dbd5ce", "border": "3px solid #dbd5ce" });
        }, function () {
            $(this).css({ "color": "#fff", "border": "3px solid #fff" });
        });
        $(".tooltip .tooltip-inner").css("border-left", "7px solid #dbd5ce");
        $(".group-switcher").css({ "border": "1px solid #969089", "background-color": "#999","pointer-events": "auto"  });
        $(".side-menu-wrapper-inner").css("border-top", "1px solid #ccc");
        $(".main-dealers-content-wrapper,.dealer-page-header-title").hide();
        $(".main-approvalcsa-content-wrapper,.csa-page-header-title").fadeIn();
        $(".main-csa-content-wrapper,.csa-page-header-title").fadeIn();
        $(".btn-primary").css({ "border-color": "#969089", "background-color": "#999", "color": "#fff" });
        $(".result-table tr td i").css({ "color": "#333", "border": "solid 1px #dbd5ce" });
        $("html,body").css({ "background-color": "#ebe7e4" });
        $(".main-top-navbar-left-content,.main-top-navbar-links").css({ "color": "#fff" });
        $(".group-switcher-inner").css({ "color": "#fff" });
        $(".result-table tr th").css({ "background-color": "#333" });
        $('.sm-switcher .group-switcher-inner').css("margin-left", "5px");
        $('.md-switcher .group-switcher-inner').css("margin-left", "0");

        this.setCSA = 0;
        //sessionStorage.setItem("setScreen", "0");
    }
  


}
