import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { FleetService } from '../../service/fleet-service/fleet.service';
import { LoginAuthenticationService } from 'src/app/service/common-service/login-authentication.service';
import { SetScreenService } from 'src/app/service/common-service/set-screen.service';
import { AcknowledgementService } from '../../service/fleet-service/acknowledgement.service';
import { Util } from 'src/app/app.util';
import { ServiceRequestService } from '../../service/service-request-service/service-request.service';

declare var $: any;

@Component({
  selector: 'app-fleet-details',
  templateUrl: './fleet-details.component.html',
  styleUrls: ['./fleet-details.component.css']
})
export class FleetDetailsComponent implements OnInit, AfterViewInit {
  suppliesInc: boolean;

  constructor(private activatedRoute:ActivatedRoute,private loginAuthenticationService:LoginAuthenticationService, private fleetService: FleetService,private util : Util, private titleService: Title, private setScreenService:SetScreenService, private acknowledgementService:AcknowledgementService, private serviceRequestService: ServiceRequestService ) {}

  serialNumber: any;
  model = "";
  customerName = "";
  installAddress = "";
  srlNo: any;
  tableLoader= true;
  acknowledgementList = [];
  acknowledgementInfo = [];
  dPriceMeterInfo:any = [];
  selectedSortBy = "";
  selectedSortOrder = "";
  eSortBy = "";
  eSortOrder = "";
  isAdmin : any;



  setSortOrder(sortBy,sortOrder) {
  
    this.selectedSortBy = sortBy;
    this.selectedSortOrder = sortOrder;
    this.setAckList();    
  
  
  }

  setEqiSortOrder(sortBy,sortOrder) {
  
    this.eSortBy = sortBy;
    this.eSortOrder = sortOrder;
    this.getEqipInfo();    
  
  
  }

  setAcknowledge(ack) {

    this.acknowledgementInfo = ack; 
    //console.log('acknowledgementInfo..',this.acknowledgementInfo)
  
  
  }

  setTab(event:any){
    console.log(event);
    //this.pageNum = 1;
    if(event == 'notifications'){
     this.setAckList();
     

     //this.setPgLinks(10000,1,10,10);
    }else if (event == 'addInfo') {
      this.getAdditionaInfo('dealer');

     //this.searchCsaOrder(this.getCsaSearchParam());
     //this.setCSACSS();

    }
   else if (event == 'priceMeter') {
    this.getPriceMeterDtls('dealer');

  }
  }

  getDealerSearchParam () {
  
    return {
        "serialNumber": this.serialNumber,
      }
      
    }
    

  getPriceMeterDtls (type){
  if(type=="dealer"){
    this.tableLoader = true;

    this.fleetService.getFleetDealerList(this.getDealerSearchParam()).subscribe(data => {
     this.dPriceMeterInfo = data.data;
     this.tableLoader = false;
     console.log('dealerPriceMeterInfo',this.dPriceMeterInfo);
     this.suppliesInc=this.dPriceMeterInfo[0].suppliesInc == "Y"? true : false;
     console.log(' this.dPriceMeterInfo[0].serialNumber', this.dPriceMeterInfo[0].serialNumber);
    
     //console.log('dPriceMeterInfo',this.dPriceMeterInfo);


   
   });
 }
  }
   //additionalInfo:AdditionalInfo[]=[];
   dAdditionalInfo =[];
   equipmentList = [];
   cAdditionalInfo =[];
   confirmMessage;
  

   
 
   getAdditionaInfo (type){
 

     if(type=="dealer"){
     this.tableLoader = true;

     this.fleetService.getAdditionalInfo(this.serialNumber).subscribe(data => {
      this.dAdditionalInfo = data.data;
      this.tableLoader = false;
      console.log('dealerAdditionalInfo',this.dAdditionalInfo);


    
    });
  }

  if(type=="csa"){
    this.tableLoader = true;

    this.fleetService.getAdditionalInfo(this.serialNumber).subscribe(data => {
     this.cAdditionalInfo = data.data;
     this.tableLoader = false;
     console.log('cAdditionalInfo',this.cAdditionalInfo);

     $(".group-switcher").css({
      "border-color": "#999",
      "background-color": "#999 !important",
      "pointer-events": "none"
  });

  $("#main-top-nav-switcher").css({ 
    "border-color": "#999",
    "background-color": "#999",

});
$("#main-menu-switcher").css({ 
    "border-color": "#999",
    "background-color": "#999",

});


   
   });
 }

    this.getEqipInfo();
 
   }


   getEqipInfo() {

    let eparams = { 
      "serialNumber" : this.serialNumber,
      "pageNum": "1",
      "recPerPage":"100000",
      "sortBy": this.eSortBy,
      "sortOrder": this.eSortOrder

    }

    this.serviceRequestService.getServiceEquipmentDealerList(eparams).subscribe(data => {
      this.equipmentList = data.data;
      console.log('this.equipmentList',this.equipmentList);
    
    });


   }


  
 
  getAckParam () {
  

    return {
        
      "serialNumber":this.serialNumber,
      //"serialNumber":"QLA06506",
      "pageNum": "1",
      "recPerPage":"21",
      "sortBy": this.selectedSortBy,
      "sortOrder": this.selectedSortOrder 
        //"pageNum": this.pageNum,
        //"recPerPage":this.recPerPage,
       // "sortBy": this.selectedSortBy,
        //"sortOrder": this.selectedSortOrder
      }
      
    }
    
    setAckList() {
      this.getAcknowledgementList(this.getAckParam());
      
     }
  
  
  
  getAcknowledgementList(params) { 

  
  
    this.tableLoader = true;
    this.acknowledgementService.getAcknowledgementList(params).subscribe(data => {
    this.acknowledgementList = data.data;
    //this.additionalInfo = this.acknowledgementList[0];
    this.tableLoader = false;
  
    console.log('acknowledgementList ',this.acknowledgementList);

    setTimeout(function(){ 
     
      $(".group-switcher").css({
        "border-color": "#999",
        "background-color": "#999 !important",
        "pointer-events": "none"
    });

    $("#main-top-nav-switcher").css({ 
      "border-color": "#999",
      "background-color": "#999",

  });
  $("#main-menu-switcher").css({ 
      "border-color": "#999",
      "background-color": "#999",

  });
  
  
  }, 200);
   
  
  });
  }

  pageLoad() {
    // alert("page Load");
      $("#page-loader-overlay")
        .delay(800)
        .fadeOut();
      $("#delay-loader")
        .delay(1000)
        .fadeOut();
      
      }
  
      

  acknowledgeConfirm(ackc) {
    console.log("ackc",ackc);
    let ackList = [];
    ackList.push(ackc);

    this.acknowledgementService.confirmAcknowledgement(ackList).subscribe(data => {
      this.confirmMessage = data.result;
      console.log('this.confirmMessage',this.confirmMessage);
      this.setAckList();
    
    });


  }


  getSearchParam () {
  

    return {
        
        "serialNumber": this.serialNumber,
        "customerName": "",
        "installLocation": "",
        "model": "",
        //"orderType": "DEALER",
        "pageNum": "",
        "recPerPage":"",
        "sortBy": "",
        "sortOrder": ""
      }
      
    }

  searchDealerFleet() { 

  
    this.fleetService.getFleetDealerList(this.getSearchParam()).subscribe(data => {
     
    let fleetDetails = data.data;
    //this.serialNumber = fleetDetails.serialNumber;
    this.model = fleetDetails[0].model;
    this.customerName = fleetDetails[0].customerName;
    this.installAddress = fleetDetails[0].installLocation;

    console.log('fleet details',fleetDetails);


  
  });
  }

  searchCsaFleet() { 

  
    this.fleetService.getFleetCsaList(this.getSearchParam()).subscribe(data => {
     
    let fleetDetails = data.data;
    //this.serialNumber = fleetDetails.serialNumber;
    this.model = fleetDetails[0].model;
    this.customerName = fleetDetails[0].customerName;
    this.installAddress = fleetDetails[0].installLocation;

    console.log('fleet details',fleetDetails);


  
  });
  }

 
  pagetype;


  ngOnInit() {


    this.srlNo = this.activatedRoute
    .params
    .subscribe(params => {
      this.serialNumber = params['sn'];
      this.pagetype = params['type'];
    });



    this.titleService.setTitle("Service Details");
    this.pageLoad();
    this.isAdmin =	sessionStorage.getItem('Admin');
    //this.myVar = this.myVariable;



  //   this.setScreenService.changeVar.subscribe(message => {
  //     if (message !== this.myVar) {
  //         this.myVar = message;
  //         if(this.myVar == '1' && this.titleService.getTitle() == 'Service Details'){
  //         //this.doSomething1(message);
  //         //this.setAckList(); 
  //         this.getAdditionaInfo('csa');
  //             }
  //       if(this.myVar == '0' && this.titleService.getTitle() == 'Service Details'){
  //        //this.doSomething1(message);
  //        this.setAckList();
  //        //alert('0');
  //             }
  //     }
  // });

  if(this.pagetype == 'dealer'){
    this.setAckList();
    this.searchDealerFleet();
  }else {
    this.getAdditionaInfo('csa');
    this.searchCsaFleet();

  }


  }

  
  ngAfterViewInit(){
    if(this.pagetype == 'dealer'){
      this.setScreenService.changeMyVar('1');
    }else {
      this.setScreenService.changeMyVar('0');
  
    }
      
  }

}
