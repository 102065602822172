import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { LoginAuthenticationService } from '../../service/common-service/login-authentication.service';
import { DashboardService } from 'src/app/service/dashboard-service/dashboard.service';
import { SetScreenService } from 'src/app/service/common-service/set-screen.service';

declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  userRoles = {};
  approvalList:any=[];
  srList:any=[];
  openOrderList:any=[];
  compList:any=[];
  approvalDealerName:string='';
  openOrderDealerName:string='';
  orderInstalledBy:string='ALL';
  openOrderInstalledBy:string="ALL";
  currentMonth: any;
  currentYear: any;
  
  objOpenOrdCls={"SDI":"dashboard-scheduled","U":"dashboard-unscheduled","SDP":"dashboard-pastdue"}

  constructor(private route: ActivatedRoute, private dashboardService:DashboardService, private loginAuthenticationService: LoginAuthenticationService, private titleService: Title,private setScreenService:SetScreenService) { }


  getLoginUserRoles() {

    this.loginAuthenticationService.getUserRoles().subscribe(data => {
      this.userRoles = data;
      console.log('user Roles : ', this.userRoles);

    });




  }



  pageLoad() {
    $("#page-loader-overlay")
      .delay(800)
      .fadeOut();
    $("#delay-loader")
      .delay(1000)
      .fadeOut();

  }




  ngOnInit() {
    this.getLoginUserRoles();
    const dateObj = new Date()
    this.currentMonth = dateObj.toLocaleString("default", { month: "long" });
    this.currentYear = dateObj.getFullYear();
    this.pageLoad();
    this.titleService.setTitle("Dashboard");
    this.getApprovalList() ;
    this.getSRList();
    this.getOpenOrderList();
    this.getCompList();
    this.setScreenService.changeMyVar(1);
  }


  getApprovalList() {
    let params =
    { "pageNum": 1,
      "recPerPage": 100,
      "approverType":this.orderInstalledBy,
      "sortBy": "APPROVAL_ID",
      "sortOrder": "ASC"
    };

    this.dashboardService.getApprovalList(params).subscribe(data=>{
        this.approvalList=data.data;
        if(this.approvalList&&this.approvalList.length>0){
            this.approvalDealerName=(this.approvalList[0]).userName;
        }
        //console.log(this.approvalList)
    });
    
  }


  getSRList() {
    let params =
    { "pageNum": 1,
      "recPerPage": 100,
      "sortBy": "",
      "sortOrder": "ASC"
    };

    this.dashboardService.getSRList(params).subscribe(data=>{
        this.srList=data.data;
      //  console.log(this.srList)
    });
    
  }


  getOpenOrderList() {
    let params =
    {  
      "installedBy":this.openOrderInstalledBy
    };

    this.dashboardService.getOpenOrderList(params).subscribe(data=>{
        this.openOrderList=data.data;
        if(this.openOrderList&&this.openOrderList.length>0){
          this.openOrderDealerName=(this.openOrderList[0]).dealerName;
        }
    });
    
  }

  getCompList() {
    let params ={};
    this.dashboardService.getCompList(params).subscribe(data=>{
        this.compList=data.data;
    });
    
  }


}
